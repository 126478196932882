// Used in theme blocks
.theme-card-list {
    @include bare-list;
    @include row-bottom;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $gutter;
    @include bp-min($medium-large) {
        grid-template-columns: repeat(4, 1fr);
    }
    @include bp-min($large) {
        grid-gap: $gutter-xl / 2;
    }
}
