@use "sass:map";
/* stylelint-disable selector-max-universal, declaration-no-important */

/*
 _________   ________   ________   _______-+-
/\* ______\ /\* ____ \ /\* ____ \ /\* ____ \
\ \ \_____/ \ \ \__/\ \\ \ \__/\ \\ \ \__/\ \
 \ \ \   ____\ \ \_\_\ \\ \ \_\_\ \\ \ \_\_\ \
  \ \ \ /\__ \\ \  __  _\\ \  __  _\\ \  __  _\
   \ \ \_/_/\ \\ \ \/\ \/_\ \ \/\ \/_\ \ \/\ \/_
    \ \________\\ \_\ \___\\ \_\ \___\\ \_\ \___\
     \/________/ \/_/\/___/ \/_/\/___/ \/_/\/___/.nl -->

      Author: Grrr.nl
*/

/* ==========================================================================
   CONTENTS
   ==========================================================================

 * Imports.............Import external stylesheets
 * Primary styles......Non module styles  (e.g. basic typography)
 * Specific styles.....Common styles used througout the site
 * Layout..............General layout stuff
 * Modules.............Modules based on SMACSS
 * Print styles........Styles for printing
 */

/* Import Normalize.css from npm so it’s easy to update */
@import "vendor/normalize.scss";
@import "utility/functions.scss";
@import "utility/mixins.scss";
@import "utility/variables.scss";
@import "utility/font-imports.scss";
@import "utility/font-mixins.scss";
@import "utility/animations.scss";

/* ==========================================================================
    PRIMER
    ========================================================================== */
html {
    box-sizing: border-box;
    scroll-behavior: smooth;
}
*,
*::before,
*::after {
    box-sizing: inherit;
}
html,
body {
    position: relative;
}
body {
    width: 100%;
    margin: 0;
    background-color: $bg-color;
}
body.has-overlay {
    overflow: hidden;
    z-index: 0;
}
main {
    @include clearfix;
    position: relative;
    @include bp-min($large) {
        min-height: calc(
            100vh - 291px - #{site-header-height-large-screens}
        ); // large footerheight - menu height
    }
}
.toc-wrapper {
    position: relative;
}

/**
 * Links
 */
a {
    color: inherit;
}
a:hover {
    vertical-align: baseline;
    -webkit-tap-highlight-color: rgb(
        0 0 0 / 0
    ); /* Remove the blue tap-highlight on mobile */
}

/*
 * Forms
 */
button,
input,
select,
textarea {
    color: $black;
    font-size: map.get($font-sizes-medium, body);
    font-family: $body-font;
    font-weight: normal;
}
mark {
    display: inline-block;
    background: rgba($tertiary-color, 0.3);
    padding: 0 3px;
    color: $text-color;
    border-radius: 3px;
}

/*
 * Media
 */
img,
object,
iframe,
video {
    max-width: 100%;
    width: auto;
    height: auto;
}

/**
 * Tables
 */
table {
    margin: 0 0 $typography-spacer;
    padding: 0;
    border-collapse: collapse;
    @include bp-min($medium) {
        margin-bottom: $typography-spacer * 2;
    }
}
tr:nth-child(even) {
    background-color: $grey-lighter;
}
th,
td {
    padding: 0.5em 1.5em;
    text-align: left;
    font-size: map.get($font-sizes-small, body);
}
th {
    line-height: 1.15;
    font-weight: $custom-font-bold;
}
td {
    font-size: map.get($font-sizes-small, body);
}
/* ==========================================================================
    LAYOUT
    ========================================================================== */

figure {
    @include clearfix;
    margin: 0 auto 1em;
}
figure img {
    display: block;
}
figcaption {
    padding: 0.5em;
    font-size: 0.75em;
    text-align: center;
    line-height: 1.6;
}
hr {
    height: 1px;
    background: $grey;
    border: 0;
}

/**
 * Non semantic layout helpers
 */
.text-color {
    color: $text-color;
}
.text-center {
    text-align: center;
}
.align-center {
    display: inline-block;
    margin-left: 50%;
    transform: translateX(-50%);
}

/* ==========================================================================
   LAYOUT & MODULES
========================================================================== */

@import "layout/colors.scss";
@import "layout/content.scss";
@import "layout/row.scss";
@import "layout/typography.scss";

@import "modules/_auto-suggest-list.scss";
@import "modules/_back-to-top.scss";
@import "modules/_breadcrumbs.scss";
@import "modules/_button-popup.scss";
@import "modules/_button.scss";
@import "modules/_card.scss";
@import "modules/_cards.scss";
@import "modules/_category-filter.scss";
@import "modules/_circle.scss";
@import "modules/_cloud.scss";
@import "modules/_code.scss";
@import "modules/_collapsible-section.scss";
@import "modules/_container-of-video-builder-on-products-page.scss";
@import "modules/_content-illustration.scss";
@import "modules/_content-section.scss";
@import "modules/_error.scss";
@import "modules/_extra-information.scss";
@import "modules/_featured-categories.scss";
@import "modules/_feedback-tool.scss";
@import "modules/_flash-message.scss";
@import "modules/_flipper.scss";
@import "modules/_focus-ring.scss";
@import "modules/_form.scss";
@import "modules/_hallmark-contentions.scss";
@import "modules/_highlighted-pages.scss";
@import "modules/_icon.scss";
@import "modules/_illustration.scss";
@import "modules/_image-text.scss";
@import "modules/_index-card.scss";
@import "modules/_index-group.scss";
@import "modules/_index.scss";
@import "modules/_ingredients-list.scss";
@import "modules/_ingredients.scss";
@import "modules/_intro-blocks.scss";
@import "modules/_login.scss";
@import "modules/_menu.scss";
@import "modules/_nav-primary.scss";
@import "modules/_news-list.scss";
@import "modules/_news-page.scss";
@import "modules/_note.scss";
@import "modules/_notice-icon.scss";
@import "modules/_page-block.scss";
@import "modules/_page-header.scss";
@import "modules/_page-preview.scss";
@import "modules/_pagination.scss";
@import "modules/_partner-list.scss";
@import "modules/_partner-logos.scss";
@import "modules/_personalisation-tool.scss";
@import "modules/_product-appearances.scss";
@import "modules/_products.scss";
@import "modules/_quality-mark-item.scss";
@import "modules/_quality-marks.scss";
@import "modules/_quiz.scss";
@import "modules/_read-indicator.scss";
@import "modules/_recently-visited.scss";
@import "modules/_related-entities.scss";
@import "modules/_related-products.scss";
@import "modules/_relevant-filter.scss";
@import "modules/_search-button.scss";
@import "modules/_search-dropdown.scss";
@import "modules/_search-form.scss";
@import "modules/_search.scss";
@import "modules/_section-title.scss";
@import "modules/_site-footer.scss";
@import "modules/_site-header.scss";
@import "modules/_site-logo.scss";
@import "modules/_skiplink.scss";
@import "modules/_slider.scss";
@import "modules/_social-list.scss";
@import "modules/_social-share-links.scss";
@import "modules/_strength-indicators.scss";
@import "modules/_styleguide.scss";
@import "modules/_substance-filters.scss";
@import "modules/_theme-allergic-extended.scss";
@import "modules/_theme-allergic.scss";
@import "modules/_theme-baby-extended.scss";
@import "modules/_theme-baby.scss";
@import "modules/_theme-blocks.scss";
@import "modules/_theme-card-container.scss";
@import "modules/_theme-card.scss";
@import "modules/_theme-cards.scss";
@import "modules/_theme-frequent-users-extended.scss";
@import "modules/_theme-frequent-users.scss";
@import "modules/_theme-gardening-extended.scss";
@import "modules/_theme-gardening.scss";
@import "modules/_theme-header.scss";
@import "modules/_theme-house-jobs-extended.scss";
@import "modules/_theme-house-jobs.scss";
@import "modules/_theme-kids-extended.scss";
@import "modules/_theme-kids.scss";
@import "modules/_theme-pregnant-extended.scss";
@import "modules/_theme-pregnant.scss";
@import "modules/_toc-menu.scss";
@import "modules/_tooltip.scss";
@import "modules/_video-embed.scss";
@import "modules/_video-overlay.scss";
@import "modules/_video-with-thumbnail.scss";
@import "modules/_visited-pages.scss";
@import "modules/_word-definition.scss";
@import "modules/_word-list.scss";
@import "modules/explanation.scss";

/* ==========================================================================
   HELPERS
   ========================================================================== */

@include bp-max($small) {
    .hide-on-palm {
        display: none !important;
    }
}

@include bp-min($small) {
    .hide-on-lap {
        display: none !important;
    }
}

.show-only-on-palm {
    display: none;
}
@include bp-max($small) {
    .show-only-on-palm {
        display: block !important;
    }
}

.is-offscreen {
    position: absolute !important;
    left: -999em !important;
}
.is-invisible {
    visibility: hidden !important;
}

/* Hide form field that should be left empty */
form .hp {
    display: none;
}

// screenreader text
.sr-only {
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
}

/* ==========================================================================
    HOVER - disable on scroll (see function disableHoverStylesOnScroll() in main.js)
    ========================================================================== */
.disable-hover {
    pointer-events: none;
}

/* ==========================================================================
    October CMS Inline Images
    ========================================================================== */
.fr-fic,
.fr-fir,
.fr-fil {
    display: block;
    width: 100%;
    line-height: 0;
}
.fr-fir,
.fr-fil {
    max-width: 85px;
    @include bp-min($medium) {
        max-width: 100px;
    }
}

// Froala Figure Center
.fr-fic:not(.fr-fil, .fr-fir) {
    margin: 4em auto !important;
}

// Froala Figure Right
.fr-fir {
    float: right;
    margin: 1em 0 1em 1.5em !important;
}

// Froala Figure Left
.fr-fil {
    float: left;
    margin: 1em 1.5em 1em 0 !important;
}
