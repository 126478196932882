.image-text {
    position: relative;
    background-color: $grey-lighter;
}
.image-text__inner {
    @include content(large);
    @include row(large);
    @include clearfix;
    @include bp-min($medium-large) {
        display: flex;
        align-items: center;
    }
}
.image-text__image,
.image-text__content {
    position: relative;
    width: 100%;
    @include bp-min($medium-large) {
        float: left;
        width: 50%;
    }
}
.image-text__image {
    display: none;
    @include bp-min($medium-large) {
        display: block;
        width: 40%;
        height: 365px;
    }
}
.image-text__content {
    @include bp-min($medium-large) {
        width: 60%;
        padding: 0 $gutter-lg;
    }
}
.image-text__content > .button {
    margin: $single-line 0 0;
}
.image-text__title {
    @include block-title;
}
.image-text__image > svg {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 365px;
}

/**
 * Has video
 */
.image-text--video.has-active-video .image-text__inner {
    display: none;
}
.image-text--video.has-active-video::after {
    content: none;
}
