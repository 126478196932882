@use "sass:map";
/*
 * Base
 */
html {
    font-size: 62.5%;
    line-height: $default-line-height;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    @include regular-font-size;
    @include body-font;
    color: $text-color;
    font-weight: normal;
}

/**
 * Headings
 */
@mixin h1 {
    margin: 0 0 $half-line;
    font-family: $heading-font;
    font-size: map.get($font-sizes-small, h1);
    font-weight: normal;
    line-height: 1.25;
    @include bp-min($medium) {
        font-size: map.get($font-sizes-medium, h1);
        line-height: 1;
    }
    @include bp-min($large) {
        font-size: map.get($font-sizes-large, h1);
    }
}

h1 {
    @include h1;
}

@mixin h2 {
    margin: 0 0 20px;
    font-family: $heading-font;
    font-size: map.get($font-sizes-small, h2);
    font-weight: normal;
    line-height: 1;
    @include bp-min($medium) {
        font-size: map.get($font-sizes-medium, h2);
    }
    @include bp-min($large) {
        font-size: map.get($font-sizes-large, h2);
    }
}

h2 {
    @include h2;
}

@mixin h3 {
    margin: 0 0 6px;
    font-family: $heading-font;
    font-weight: normal;
    font-size: map.get($font-sizes-small, h3);
    line-height: 1.27;
    @include bp-min($medium) {
        font-size: map.get($font-sizes-medium, h3);
    }
    @include bp-min($large) {
        font-size: map.get($font-sizes-large, h3);
    }
}

h3 {
    @include h3;
}

@mixin h4 {
    margin: 0 0 $half-line;
    font-family: $heading-font;
    font-size: map.get($font-sizes-small, h4);
    font-weight: normal;
    line-height: $heading-line-height;
    @include bp-min($medium) {
        font-size: map.get($font-sizes-medium, h4);
    }
    @include bp-min($large) {
        font-size: map.get($font-sizes-large, h4);
    }
}

h4 {
    @include h4;
}

@mixin h5 {
    margin: 0 0 $half-line;
    font-family: $heading-font;
    font-size: map.get($font-sizes-small, h5);
    font-weight: normal;
    line-height: $heading-line-height;
    @include bp-min($medium) {
        font-size: map.get($font-sizes-medium, h5);
    }
    @include bp-min($large) {
        font-size: map.get($font-sizes-large, h5);
    }
}

h5 {
    @include h5;
}

@mixin h6 {
    margin: 0 0 $half-line;
    font-family: $custom-font-regular;
    font-size: map.get($font-sizes-small, h6);
    font-weight: normal;
    line-height: $heading-line-height;
    text-decoration: underline;
    @include bp-min($medium) {
        font-size: map.get($font-sizes-medium, h6);
    }
    @include bp-min($large) {
        font-size: map.get($font-sizes-large, h6);
    }
}

h6 {
    @include h6;
}

/**
 * Paragrahps
 */
p {
    margin: 0;
    margin-bottom: $typography-spacer;
    @include bp-min($medium) {
        margin-bottom: $typography-spacer * 2;
    }
}
p + p {
    @include bp-min($medium) {
        margin-top: -$typography-spacer;
    }
}

/*
 * Lists
 */
ul,
ol {
    margin: 0 0 0 1.15em;
    padding: 0;
}

// Adjust margin if lists are adjacent
ol,
ul,
dl {
    margin: 0 0 ($typography-spacer * 2) 1.15em;
    + ol,
    + ul,
    + dl {
        margin-top: -$typography-spacer;
    }
}

// Remove margin bottom if a list is nested within a list
ul ul,
ul ol,
ol ol,
ol ul {
    margin-bottom: 0;
}

li li {
    margin: 0;
}

/**
 * Typography combinations
 */
p + ol,
p + ul,
p + dl {
    margin-top: -$typography-spacer;
}
// Cater for paragraphs that are within list items because of Word Import generated content
li p + ol,
li p + ul,
li p + dl {
    @include bp-min($medium) {
        margin-top: -($typography-spacer * 2);
    }
}

dt {
    font-weight: normal;
    font-family: $custom-font-bold;
}
dd {
    margin: 0;
}

li a,
p a {
    text-decoration: underline;
    text-underline-position: under;
    &:hover {
        text-decoration: none;
    }
}

/**
 * Importance styling
 */
strong {
    font-weight: bold;
}
em {
    font-weight: normal;
    font-style: italic;
}

/**
 * Blockquote
 */
blockquote {
    margin: 0 0 1em;
    padding: 0;
    font-family: $custom-font-bold;
}
blockquote footer {
    display: inline-block;
    font-family: $custom-font-regular;
}
blockquote footer::before {
    content: "— ";
}
