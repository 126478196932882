/**
 * White border radii of content sections
 * ...
 */
.circle {
    position: relative;
}
.circle .content-section__inner {
    position: relative;
    z-index: 999;
}
