// Logos in site footer
.partner-list {
    @include bare-list;
    float: left;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.partner-list li {
    width: 50%;
    float: left;
    margin: 0;
}
.partner-list__logo {
    display: inline-block;
    opacity: 0.8;
    transition: opacity 0.2s;
}
.partner-list__logo:hover {
    opacity: 1;
}
.partner-list__logo > svg {
    width: 100%;
    max-width: 175px;
    max-height: 75px;
    fill: $white;
}
