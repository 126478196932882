/**
 * Main menu
 */
.menu {
    @include bare-list;
}
.menu__item {
    z-index: 333;
    position: relative;
    display: inline-block;
    vertical-align: top;
    height: inherit;
    margin: 0;
    padding: 0;
    .hide-mobile {
        display: none;
        @include bp-min(840) {
            display: inline-block;
        }
    }
    .show-mobile {
        display: inline-block;
        @include bp-min(840) {
            display: none;
        }
    }
}
.menu__item--footer-only {
    .menu--primary & {
        display: none;
    }
}
.menu__item a {
    display: block;
    height: 100%;
    background: none;
    color: $text-color;
    text-decoration: none;
    font-family: $custom-font-bold;
    transition: color 0.15s;
}
.menu__item a:hover {
    color: $text-color;
}

/**
 * Primary menu (in header)
 */
.menu--primary {
    display: flex;
    justify-content: space-around;
    vertical-align: top;
    width: 100%;
    height: $site-header-bar-height-small-screens;
    padding: 0 $menu-item-padding-extra-small;
    background: $white;
    white-space: nowrap;
    text-align: center;
    margin-top: $site-header-bar-height-small-screens;
    box-shadow: 0 4px 10px -3px rgba($black, 0.4);
    @include bp-min($medium) {
        box-shadow: none;
        margin-top: 0;
        padding: 0 $menu-item-padding-small;
    }
    @include bp-min($medium) {
        height: $site-header-bar-height-medium-screens;
    }
    @include bp-min($nav-switch) {
        height: inherit;
        box-shadow: none;
    }
    .styleguide & {
        position: relative;
    }
}
.menu--primary .menu__item {
    padding: 0 $menu-item-padding-extra-small;
    font-size: 15px;
    @include bp-min($medium) {
        padding: 0 $menu-item-padding-small;
    }
    @include bp-min(1060) {
        font-size: 16px;
    }
}
.menu--primary .menu__item > a:hover > span {
    border-bottom: 2px solid $tertiary-color-dark;
}
.menu--primary .menu__item > a > span {
    padding: 3px 0;
}
.menu--primary .menu__item--active a > span {
    border-bottom: 2px solid $tertiary-color-dark;
}
.menu--primary li:first-child {
    position: absolute;
    top: 0;
    right: $gutter-xs;
    @include bp-min($medium) {
        right: 0;
    }
    @include bp-min($large) {
        position: relative;
    }
}
.menu--primary .menu__item a {
    line-height: $site-header-bar-height-small-screens;
    @include bp-min($medium) {
        line-height: $site-header-bar-height-medium-screens;
    }
    @include bp-min($large) {
        line-height: $site-header-height-large-screens;
    }
}

/*
 * Secondary menu (in footer)
 */
.menu--secondary {
    display: inline-block;
}
.menu--secondary .menu__item {
    float: left;
    width: 100%;
    padding: 0;
    font-size: 17px;
    text-align: left;
}
.menu--secondary .menu__item a {
    font-family: $custom-font-regular;
    font-size: 17px;
    line-height: 2;
}
.menu--secondary .menu__item a:hover {
    color: $tertiary-color;
}
.menu--secondary .menu__item a:hover::after {
    transform: translateX(0.1em);
}
.menu--secondary .menu__item--active a {
    color: $tertiary-color;
}
.menu--secondary .menu__item a::after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 12px;
    margin: 0 0 -2px 5px;
    background: url("../../images/chevron--small-white.svg") no-repeat 50% 50% /
        100% auto;
    transition: transform 0.15s;
}
