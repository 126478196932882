.explanation {
    @include row-bottom(small);
    @include bp-min($medium) {
        @include content(medium);
    }
}
.explanation__inner {
    @include card-hover-effect;
    @include box-shadow;
    display: inline-block;
    width: 100%;
    margin-top: $gutter-sm;
    padding: $gutter-sm;
    background: $white;
    @include bp-min($medium) {
        margin: 0;
        padding: $gutter;
        padding-right: $gutter-lg;
    }
    @include bp-min($medium-large) {
        padding: $gutter;
        padding-right: $gutter-xl;
    }
}

.explanation__link {
    @include expand-clickable-area;
}

.explanation__link::after {
    @include z-index(search-result-click-area);
}

.explanation__inner > :last-child {
    margin: 0;
}
.explanation__excerpt {
    margin-bottom: $single-line;
}
.explanation mark {
    background-color: inherit;
}
