// stylelint-disable scss/at-rule-no-unknown

:root {
    --block-padding: 15px;

    @include bp-min($small) {
        --block-padding: 15px;
    }
    @include bp-min($medium) {
        --block-padding: 30px;
    }
}

@mixin content($size: small) {
    margin-right: auto;
    margin-left: auto;
    padding-left: $gutter-sm;
    padding-right: $gutter-sm;
    @include bp-min($small) {
        padding-left: $gutter-sm;
        padding-right: $gutter-sm;
        @if $size == medium {
            max-width: $site-width-medium + 80;
        } @else if $size == large {
            max-width: $site-width-large + 80;
        } @else if $size == small {
            max-width: $site-width-small + 80;
        } @else if $size == none {
            max-width: 100%;
        } @else {
            max-width: $size + 80px;
        }
    }
    @include bp-min($medium) {
        padding-left: $gutter;
        padding-right: $gutter;
    }
}
.content {
    @include content;
}

$content-sizes: ("small", "medium", "large");

@each $size in $content-sizes {
    .content--#{$size} {
        @include content($size);
    }
}
