.site-footer {
    @include clearfix;
    position: relative;
    width: 100%;
    background: $primary-color;
    color: $white;
}
.site-footer__inner {
    display: flex;
    flex-wrap: wrap;
}

// typography
.site-footer h2 {
    position: relative;
    margin-top: 0;
    margin-bottom: $single-line;
    padding-bottom: 8px;
    font-size: 21px;
}
.site-footer h2::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background: rgba($white, 0.24);
    @include bp-min($large) {
        width: 240px;
    }
}
.site-footer a {
    color: $white;
    font-size: 15px;
}

// Site logo
.site-footer__logo {
    display: inline-block;
    width: 80px;
    margin-bottom: $gutter;
    @include bp-min($large) {
        width: 100px;
    }
}
.site-footer__logo svg {
    width: 100%;
    height: auto;
}
.site-footer__page-links {
    @include bare-list;
}
.site-footer__page-links > li {
    margin-bottom: $gutter;
    @include bp-min($medium-large) {
        margin-bottom: $gutter-xs / 2;
    }
}
.site-footer__page-link::after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 12px;
    margin: 0 0 -2px 5px;
    background: url("../../images/chevron--small-blue.svg") no-repeat 50% 50% /
        100% auto;
    transition: transform 0.2s;
}
.site-footer__page-link:hover,
.site-footer__page-link:focus {
    color: $text-color;
    text-decoration: underline;
}
.site-footer__page-link:hover::after,
.site-footer__page-link:focus::after {
    transform: translate(0.05em, 0);
}

// Parts
.site-footer__about {
    color: $text-color;
    @include bp-min($medium-large) {
        text-align: right;
    }
}
.site-footer__about a {
    color: inherit;
    text-decoration: none;
}
.site-footer__about a:hover,
.site-footer__about a:focus {
    text-decoration: underline;
    text-underline-position: under;
}
.site-footer__partners,
.site-footer__menu,
.site-footer__about {
    @include row(small);
    width: 100%;
    padding-left: $gutter-sm;
    padding-right: $gutter-sm;
    @include bp-min($medium-large) {
        width: 33.333%;
        float: left;
    }
    @include bp-min($large) {
        width: 25%;
        padding: $gutter-lg;
    }
}
.site-footer__partners {
    @include bp-min($large) {
        width: 50%;
    }
}
.site-footer__copyright,
.site-footer__colophon {
    margin: $gutter-xs 0 0;
    font-size: 13px;
    line-height: 1.2;
    @include bp-min($medium-large) {
        margin: $gutter-sm 0 0;
    }
}
.site-footer__colophon {
    @include bp-min($medium-large) {
        float: right;
        margin-bottom: $gutter-lg;
    }
    @include bp-min($large) {
        margin-bottom: $gutter;
    }
}
.site-footer__colophon-label {
    display: block;
}
.site-footer__colophon-logo {
    display: inline-block;
    fill: currentcolor;
    margin: 2px 0 0;
    border-bottom: 1px solid transparent;
}
.site-footer__colophon-logo:hover,
.site-footer__colophon-logo:focus {
    border-color: $text-color;
}
.site-footer__colophon-logo > svg {
    width: 34px;
    height: 11px;
    fill: $text-color;
}
