.search-dropdown {
    position: relative;
    width: 100%;
    padding-top: 12px;
    transition:
        all 0.2s,
        max-height 0.3s,
        opacity 0.3s;
    @include bp-min($medium-large) {
        max-width: 500px;
    }
}
.search-dropdown.is-expanded {
    z-index: 999;
    opacity: 1;
    max-height: 9999px;
    overflow: visible;
    transition:
        all 0.2s,
        max-height 0.3s,
        opacity 0.3s;
}
