@use "sass:color";
// stylelint-disable no-unknown-animations

.theme-header {
    @include site-header-padding;
    position: relative;
    width: 100%;
    text-align: center;
}
.styleguide .theme-header {
    height: 600px;
}

/**
 * Theme colors
 */
.theme-header::before {
    content: "";
    opacity: 1;
    background-color: color.adjust($grey-lighter, $lightness: -7%);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200vh;
    transition: opacity 0.2s;
}
.styleguide .theme-header::before {
    position: absolute;
}
.has-overlay .theme-header::before {
    @include bp-min($medium) {
        opacity: 0;
    }
}
.no-smil .theme-header,
.theme-header.is-transitioned {
    @include bp-min($medium) {
        &::before {
            opacity: 1;
        }
    }
}
.theme-header--zwanger::before,
.theme-header--klussen::before,
.theme-header--veelgebruikers::before,
.theme-header--zwanger + article,
.theme-header--klussen + article,
.theme-header--veelgebruikers + article {
    background-color: $blue-medium;
}
.theme-header--baby::before,
.theme-header--baby + article {
    background-color: $blue-muted-light;
}
.theme-header--tuinieren::before,
.theme-header--kinderen::before,
.theme-header--tuinieren + article,
.theme-header--kinderen + article {
    background-color: $yellow;
}
.theme-header--allergieen::before,
.theme-header--allergieen + article {
    background-color: $salmon;
}
.has-overlay .theme-header + article {
    transform: translateY(4em);
    animation: pop-in 0.3s forwards;
}
/**
 * On removal via personalisation tool
 */
.theme-header.is-removed {
    animation: fade-out 0.15s forwards;
    .theme-kids-extended__blob,
    .theme-baby-extended__blob,
    .theme-allergic-extended__blob,
    .theme-pregnant-extended__blob {
        animation: pop-out 0.15s forwards;
    }
    .theme-header__content {
        animation: pop-out 0.15s forwards;
    }
    + article {
        animation: pop-out 0.15s forwards;
    }
}

/**
 * Regular settings
 */
.theme-header__inner {
    @include content(large);
    @include row(large);
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.has-overlay .theme-header__inner {
    @include row-top(none);
    @include row-bottom(medium);
    @include bp-min($medium-large) {
        padding-top: $gutter;
    }
}
.theme-header__image {
    position: relative;
    display: flex;
    justify-content: center;
    width: 53%;
    height: 0;
    padding-bottom: 35%;
    max-height: 55vh;
    margin: 0 auto $gutter-sm;
    text-align: center;
    animation: pop-in 0.3s forwards;
    @include bp-min($medium-large) {
        margin-bottom: $gutter;
    }
}
// If we can't handle svg path transitions:
.no-smil .theme-header__image {
    animation: fade-in 0.4s;
}
.theme-header__image > svg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    @include bp-min($medium) {
        height: auto;
    }
}
.theme-header__content {
    margin-top: $gutter-xs;
    animation: pop-in 0.5s;
}
.theme-header__intro {
    margin: 0 auto;
    line-height: 1.5;
    @include bp-min($medium) {
        width: 90%;
        font-size: 24px;
    }
}
.theme-header__intro :last-child {
    margin: 0;
}
