// stylelint-disable declaration-no-important

/*
 * Only applies when non-pointer or non-touch focus is received, and falls
 * back to default browser focus styling when not supported.
 *
 * A `focus-visible` polyfill is used for unsupported platforms, although
 * it applies the classes to supported platforms as well, leaving the platform
 * styles for `no-js` scenarios.
 */
:focus:not(:focus-visible) {
    outline: none;
}
:focus:not(:-moz-focusring) {
    outline: none;
}
.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}
:focus-visible {
    outline: $focus-ring;
}

.focus-ring-light:focus-visible,
.theme--primary :focus-visible {
    outline: $focus-ring-light;
}

:-moz-focusring {
    outline: $focus-ring;
}

.focus-ring-light:-moz-focusring,
.theme--primary :-moz-focusring {
    outline: $focus-ring-light;
}

button:-moz-focusring {
    outline: $focus-ring !important;
}

.theme--primary button.focus-ring-light:-moz-focusring,
.theme--primary button:-moz-focusring {
    outline: $focus-ring-light !important;
}

.js-focus-visible .focus-visible {
    outline: $focus-ring;
}
.js-focus-visible .focus-ring-light.focus-visible,
.js-focus-visible .theme--primary .focus-visible {
    outline: $focus-ring-light;
}
