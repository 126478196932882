$video-player-controls-bar-height: 44px;

.video-with-thumbnail {
    z-index: 0;
    position: relative;
}

/**
* Thumbnail
*/
.video-with-thumbnail__thumbnail {
    z-index: 900;
    opacity: 1;
    transition: opacity 0.3s;
    position: absolute;
    width: 100%;
    height: 100%;
}
.video-with-thumbnail.has-active-video .video-with-thumbnail__thumbnail {
    opacity: 0;
    pointer-events: none;
}
.video-with-thumbnail__thumbnail-image {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/**
 * Play button
 */
.video-with-thumbnail__play-button-container {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.video-with-thumbnail__play-button {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: $blue-dark;
    color: $white;
    border-radius: 50%;
    box-shadow: none;
    position: unset;
    transition: background 0.15s ease-out, color 0.15s ease-out;

    @include bp-min($medium) {
        width: 96px;
        height: 96px;
    }
}
.video-with-thumbnail__play-button:hover {
    background-color: $white;
    color: $blue-dark;
}
.video-with-thumbnail__play-button::after {
    content: "";
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: none;
}
.video-with-thumbnail__play-button::before {
    display: none;
}
.video-with-thumbnail__play-button svg {
    width: 22px;
    height: 22px;
    transform: translateX(2px);

    @include bp-min($medium) {
        width: 38px;
        height: 38px;
        transform: translateX(4px);
    }
}

/**
 * Video
 */
.video-with-thumbnail__video {
    display: block;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.video-with-thumbnail.has-active-video .video-with-thumbnail__video {
    display: block;
}
