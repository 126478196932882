/**
 * Kids illustration
 */
.theme-kids {
    @include theme-illustration;
}
/**
 * Hover - focus effects
 */
.theme-card {
    @include hocus {
        @include bp-min($medium-large) {
            .theme-kids__blob--back {
                transform: scale(1.1) rotate(-2deg);
            }
            .theme-kids__blob--front {
                transform: scale(1.25, 1.3) rotate(2deg);
            }
        }
        .theme-kids__father-bust {
            transform: rotate(-3deg) translateY(2%);
        }
        .theme-kids__kids-head {
            transform: rotate(-10deg) translateY(3%);
        }
    }
}

/**
 * Parts
 */
.theme-kids__blob--front,
.theme-kids__blob--back {
    transform-origin: center;
    transition: transform 0.3s;
}
.theme-kids__blob--back {
    transform: scale(0.55);
}
.theme-kids__kids-head,
.theme-kids__father-bust {
    transform-origin: bottom left;
    transition: transform 0.3s;
}
