// stylelint-disable no-unknown-animations

.cloud {
    @include bare-list;
    position: relative;
    width: 500px;
    height: 500px;
}
.cloud__item {
    position: absolute;
    will-change: transform;
    $elements: 10;
    @for $i from 0 to $elements {
        &:nth-child(#{$i}) {
            animation-delay: $i * -2.15s;
        }
    }
    animation: float 4s alternate infinite ease-in-out;
    animation-fill-mode: forwards;
}
.cloud__item span {
    @include hide-text;
    display: block;
    will-change: transform;
}
/**
 * Cloud item shapes
 */
.cloud__item--circle span {
    background: $tertiary-color;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    box-shadow: 2px 2px 6px $text-color;
}
.cloud__item--rect span {
    background: $text-color;
    width: 38px;
    height: 38px;
    box-shadow: 2px 2px 6px $text-color;
}
.cloud__item--triangle span {
    background: url("../../images/shape--triangle.svg") no-repeat 50% 50% / 100%
        auto;
    width: 70px;
    height: 70px;
    filter: drop-shadow(1px 2px 3px $text-color);
}

// Generic cloud svg settings
.cloud__item span > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 50%;
    max-height: 50%;
    fill: $white;
}

// Exceptions
.cloud__item--ingredients span > svg {
    fill: $text-color;
}
.cloud__item--triangle span > svg {
    top: 60%;
}
.cloud__item--water span > svg {
    max-width: 100%;
    max-height: 100%;
}
.page-header .cloud,
.image-text .cloud {
    position: absolute;
    width: 100%;
    height: 100%;
}

// circle
.cloud__item:nth-child(1) {
    top: 16%;
    left: 14%;
}
.cloud__item:nth-child(1) span {
    transform: rotate(-15deg);
}

// circle
.cloud__item:nth-child(2) {
    top: 0%;
    left: 25.5%;
}
.cloud__item:nth-child(2) span {
    transform: scale(0.7);
}

// rect
.cloud__item:nth-child(3) {
    top: 16%;
    left: 36%;
}
.cloud__item:nth-child(3) span {
    transform: rotate(21deg);
}

// triangle
.cloud__item:nth-child(4) {
    top: 5%;
    left: 51%;
}
.cloud__item:nth-child(4) span {
    transform: rotate(-35deg);
}

// circle
.cloud__item:nth-child(5) {
    top: 5%;
    right: 11%;
}
.cloud__item:nth-child(5) span {
    transform: scale(0.7) rotate(20deg);
}

// rect
.cloud__item:nth-child(6) {
    top: 24%;
    right: 19.5%;
}
.cloud__item:nth-child(6) span {
    transform: rotate(-35deg) scale(0.8);
}

// circle
.cloud__item:nth-child(7) {
    top: 33%;
    left: 48.5%;
}
.cloud__item:nth-child(7) span {
    transform: scale(1.1) rotate(3deg);
}

// triangle
.cloud__item:nth-child(8) {
    top: 29%;
    left: 30%;
}
.cloud__item:nth-child(8) span {
    transform: rotate(-35deg) scale(0.55);
}

// circle
.cloud__item:nth-child(9) {
    top: 48%;
    right: 23%;
}
.cloud__item:nth-child(9) span {
    transform: rotate(-35deg) scale(0.58);
}

// rect
.cloud__item:nth-child(10) {
    top: 48%;
    left: 30.5%;
}
.cloud__item:nth-child(10) span {
    transform: rotate(-36deg) scale(0.9);
}
