/**
 * Corporate partner logos
 */
.partner-logos {
    @include bare-list;
    margin: $gutter 0 $gutter-sm;
}
.partner-logos:last-child {
    margin-bottom: 0;
}
.partner-logos__logo {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
}
.partner-logos__logo > svg {
    display: block;
    fill: $text-color;
}
.partner-logos__logo--rivm > svg {
    width: 150px;
    height: 65px;
    @include bp-min($small) {
        width: 210px;
        height: 91px;
    }
}
.partner-logo__logo--veiligheid-nl {
    @include bp-max($small) {
        margin-top: 5px;
    }
}
.partner-logos__logo--veiligheid-nl > svg {
    width: 140px;
    height: 30px;
    @include bp-min($small) {
        width: 160px;
        height: 38px;
    }
}
