.extra-information {
    position: relative;
    margin: 0 0 $typography-spacer;
    padding: 0 $gutter-sm;
    @include bp-min($medium) {
        margin-top: -$typography-spacer;
        margin-bottom: $typography-spacer * 2;
    }
}
// Faux border
.extra-information::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-52%);
    width: 5px;
    height: calc(100% - 1ex);
    background: $orange;
}
.extra-information__inner {
    padding-left: 5px;
}
.extra-information__title {
    margin: 0;
    // font-size: 0.95em;
    // line-height: 1.3;
}
.extra-information__list {
    @include bare-list;
}
.extra-information__item {
    display: inline-block;
    line-height: 1.3;
}
.extra-information__item[aria-hidden="true"] {
    display: none;
}
.extra-information__item:not(:first-of-type) {
    text-transform: lowercase;
}
