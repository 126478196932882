/**
 * Allergic illustration
 */
.theme-allergic {
    @include theme-illustration;
}

/**
 * Hover - focus effects
 */
.theme-card {
    @include hocus {
        @include bp-min($medium-large) {
            .theme-allergic__blob--front {
                transform: scale(1.25) rotate(2deg);
            }
            .theme-allergic__blob--back {
                transform: scale(1.1) rotate(-2deg);
            }
        }
        .theme-allergic__head {
            transform: rotate(-10deg) scale(1, 0.98) translateY(3%);
        }
        .theme-allergic__left-arm {
            transform: rotate(-8deg);
        }
        .theme-allergic__right-arm {
            transform: rotate(-12deg);
        }
        .theme-allergic__legs {
            transform: rotate(0.5deg);
        }
        .theme-allergic__spots {
            transform: scale(1.15);
        }
    }
}

/**
 * Parts
 */
.theme-allergic__blob--front,
.theme-allergic__blob--back {
    transform-origin: center;
    transition: transform 0.3s;
}
.theme-allergic__blob--back {
    transform: scale(0.5);
    transform-origin: center;
    transition: transform 0.3s;
}
.theme-allergic__head {
    transform-origin: center;
    transition: transform 0.2s;
}
.theme-allergic__left-arm {
    transform-origin: bottom right;
    transition: transform 0.2s;
}
.theme-allergic__right-arm {
    transform-origin: top right;
    transition: transform 0.2s;
}
.theme-allergic__legs {
    transform-origin: bottom right;
    transition: transform 0.2s;
}
.theme-allergic__spots {
    transform-origin: center;
    transition: transform 0.2s;
}
