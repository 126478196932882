@use "sass:map";

button,
.button {
    @include bare-button;
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0.75em 2.5em 0.75em 1em;
    background-color: $grey;
    color: $text-color;
    font-family: $custom-font-regular;
    font-size: map.get($font-sizes-small, body);
    line-height: 1.15;
    text-align: left;
    box-shadow: inset 0 0 0 1px currentcolor;
    cursor: pointer;
    transition: background-color 0.2s;
    @include bp-min($medium) {
        font-size: map.get($font-sizes-medium, body);
    }
}
button:hover,
.button:hover {
    text-decoration: none;
}
button::after,
.button::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 2.5em;
    height: 100%;
    background: url("../../images/chevron--pink.svg") no-repeat 50% 50% / 0.5em
        auto;
}
.button__inner {
    position: relative;
    top: 50%;
    display: inline-block;
    transform: translateY(-50%);
}

/**
 * Primary button
 */
.button--primary {
    background-color: $primary-color;
    color: $white;
    box-shadow: inset 0 0 0 1px $primary-color;
}
.button--primary:hover {
    background-color: $white;
    color: $text-color;
    border-color: currentcolor;
}

/**
 * Secondary button
 */
.button--secondary {
    background-color: $white;
    color: $text-color;
    box-shadow: inset 0 0 0 1px $grey-medium;
}
.button--secondary:hover {
    background-color: $primary-color;
    color: $white;
    box-shadow: inset 0 0 0 1px $primary-color;
}

/**
 * Tertiary button
 */
.button--tertiary {
    background-color: $grey;
    color: $primary-color;
    box-shadow: none;
}
.button--tertiary:hover {
    background-color: $primary-color;
    color: $white;
    box-shadow: inset 0 0 0 1px $primary-color;
}

/**
 * Quaternary button
 */
.button--quaternary {
    position: relative;
    width: 100%;
    min-width: auto;
    padding: 0.75em 1em 0.75em 2.25em;
    background-color: $white;
    color: $primary-color;
    @include bp-min($medium) {
        width: auto;
    }
}
.button--quaternary:hover {
    background-color: $primary-color;
    color: $white;
    box-shadow: none;
}
.button--quaternary > svg {
    position: absolute;
    left: 12px;
    top: 14px;
    // Don't want to hard code 20px, using calc to keep size relative to body font-size.
    width: calc(20em / 18);
    height: calc(20em / 18);
}
.button--quaternary:hover > svg {
    fill: $white;
}
.button--quaternary::after {
    content: none;
}
.no-js .button--copy-to-clipboard {
    display: none;
}

/**
 * Arrow other way
 */
.button--back {
    padding-left: 2.5em;
    padding-right: 1em;
    text-align: right;
}
.button--back::after {
    left: 0;
    right: auto;
    transform: rotate(180deg);
}

/**
 * Arrow down
 */
.button--down {
    padding-right: 3em;
}
.button--down::after {
    transform: rotate(90deg);
    right: 0.25em;
}

/**
 * Ghost button
 */
.button--ghost {
    background-color: transparent;
    color: $text-color;
    box-shadow: inset 0 0 0 1px $text-color;
}
.button--ghost:hover {
    background-color: $primary-color;
    color: $white;
}

/**
 * Ghost button - light variant
 */
.button--ghost-light {
    background-color: transparent;
    color: $white;
    box-shadow: inset 0 0 0 1px $white;
}
.button--ghost-light::after {
    background-image: url("../../images/chevron--white.svg");
}
.button--ghost-light:hover {
    background-color: $white;
    color: $text-color;
}
.button--ghost-light:hover::after {
    background-image: url("../../images/chevron--pink.svg");
}

/**
 * Button that doesn't look like a button
 */
.button--naked {
    min-width: 0;
    padding-left: 0;
    padding-right: 0;
    background: none;
    color: $text-color;
    box-shadow: none;
}
.button--naked:hover {
    text-decoration: underline;
}
.button--naked::after {
    content: none;
}

/*
 * Play button
 */
.button.button--play {
    padding-right: 55px;
    background: $white url("../../images/icon-play--blue.svg") no-repeat 0 0 /
        25px auto;
    background-position: right 15px bottom 50%;
    color: $text-color;
    box-shadow: inset 0 0 0 1px $text-color;
}
.button.button--play:hover {
    background-image: url("../../images/icon-play--white.svg");
    background-color: $primary-color;
    color: $white;
}
.button--play::after {
    content: none;
}

/**
 * Link button
 */
.button--link {
    min-width: auto;
    display: inline-block;
    margin: $gutter-sm 0 0;
    padding: 0 1em 0 0;
    background: none;
    color: $text-color;
    box-shadow: none;
    font-size: inherit;
}
.button--link:hover {
    text-decoration: underline;
}
.button--link::after {
    width: 0.8em;
    // background-position: 100% 65%;
    // background-size: 0.3em auto;
    background: url("../../images/chevron--small-blue.svg") no-repeat 50% 50% /
        0.8em auto;
}
.button--link:hover::after {
    transition: transform 0.2s;
    transform: translateX(0.15em);
}

/**
 * Close button
 */
.button--close {
    width: 45px;
    height: 45px;
    min-width: 0;
    background: transparent url("../../images/close--blue-dark.svg") no-repeat
        54% 50% / 30% auto;
    margin: 0;
    padding: 0;
    border-radius: 0 0 0 2rem;
    box-shadow: none;
}
.button--close:hover {
    background-color: $grey-lighter;
}
.button--close::after {
    content: none;
}
.button--close span {
    @include hide-text;
}
.button.is-hidden {
    display: none;
}
