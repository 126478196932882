@use "sass:color";

.skiplink {
    z-index: 80;
    position: absolute;
    left: -1000em;
    display: block;
    margin: $gutter-sm $gutter;
    padding: 0.58em 1em;
    text-decoration: none;
    background: $blue-dark;
    color: $white;
    border-radius: 0;
}
.skiplink:hover {
    background: color.adjust($blue-dark, $lightness: 10%);
}
.skiplink:focus,
.skiplink:active {
    z-index: 999;
    left: 0;
}
.styleguide .skiplink {
    position: relative;
    left: 0;
    display: inline-block;
}
.skiplink--center:focus,
.skiplink--center:active {
    left: 50%;
    transform: translateX(-50%);
}
.skiplink--right:focus,
.skiplink--right:active {
    right: 0;
    left: auto;
}
