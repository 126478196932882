.search {
    position: relative;
    z-index: 666;
    background: $secondary-color;
}
.search__inner {
    @include content(small);
    @include row(medium);
}
.search__title {
    @include block-title;
    margin-bottom: $single-margin;
}

/**
 * Search dropdown in search section
 */
.search__search-dropdown {
    box-shadow: 0 0 40px rgba($black, 0.3);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 500px;
}
.search__search-dropdown article:first-of-type::before {
    content: "";
    display: block;
    width: 26px;
    height: 26px;
    background: $white;
    margin: 10px 0 -20px 50%;
    transform: translateX(-50%) rotate(135deg);
}
