/**
 * Video embed
 * Scale video's while keeping aspect ratio
 * - Aspect ratio: 16:9 (padding-top: 56.25%)
 * - Padding bottom is used for Youtube playbar
 */
.video-embed {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    display: block;
    margin-bottom: 1em;
    @include bp-min($small) {
        margin-bottom: 1.5em;
    }
}

.video-embed iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
