.back-to-top {
    opacity: 1;
    display: block;
    width: 60px;
    height: 60px;
    background: $white url("../../images/chevron--up-blue.svg") no-repeat 50%
        50% / 30px auto;
    border-radius: 50%;
    box-shadow:
        0 2px 8px 0 rgba($black, 0.25),
        0 2px 10px 0 rgba($black, 0.25);
    transform: translate3d(0, 0, 0);
    transition:
        background 0.3s,
        opacity 0.3s,
        transform 0.25s;
}
.back-to-top:hover,
.back-to-top:focus {
    background-color: $primary-color;
    background-image: url("../../images/chevron--up-white.svg");
}

.main__back-to-top {
    z-index: 999;
    position: fixed;
    right: 6px;
    bottom: $gutter-xs;
    @include bp-min($medium) {
        bottom: $gutter;
        right: $gutter;
    }
    @include bp-min($large) {
        right: calc(50% - (#{$site-max-width} / 2) - 30px);
    }
    &.is-hidden {
        opacity: 0;
        transform: translate3d(0, 80px, 0);
    }
}
