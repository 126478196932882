$content-icon-size-small: 2.4rem;
$content-icon-size-large: 2.8rem;
$content-icon-small-margin: px-to-em(14);
$content-icon-large-margin: px-to-em(12);

.content-section {
    @include clearfix;
    @include row(medium);
    position: relative;
    width: 100%;
    z-index: 0;
    background-color: $white;
    @include bp-min($large) {
        border-radius: 0 250px / 200px;
    }
    @include bp-min($extra-large) {
        border-radius: 0 350px / 290px;
    }
}
.content-section--no-radius-bottom {
    border-bottom-left-radius: 0;
}
.content-section--transparent {
    background-color: transparent;
}

// container
.content-section__inner {
    position: relative;
    z-index: 999;
    max-width: $site-max-width;
    margin: 0 auto;
    @include bp-min($medium-large) {
        padding-left: ($toc-menu-width / 2);
        padding-right: ($toc-menu-width / 2);
        .toc-wrapper &,
        .toc-wrapper ~ .content-section & {
            padding-left: $toc-menu-width;
            padding-right: 0;
        }
    }
}
.content-section__part {
    @include clearfix;
    scroll-margin-top: 6em;
    position: relative;
    padding: 0 $gutter-sm;
    @include bp-min($small) {
        padding: 0 $gutter;
    }
    @include bp-min($medium) {
        padding: 0 $gutter-lg;
    }
    @include bp-min($large) {
        padding: 0 $gutter-xl;
    }
}

.content-section.theme--secondary li a:not([class*="button"]),
.content-section.theme--secondary p a:not([class*="button"]) {
    color: $text-color;
}

/**
 * Headers with icons
 */
.content-section__header-container {
    margin-bottom: 20px;
    .toc-wrapper & {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}
.content-section__header-icon {
    width: $content-icon-size-small;
    height: $content-icon-size-small;
    flex: 0 1 $content-icon-size-small;
    margin: 0 $content-icon-small-margin 0 px-to-em(2);
    @include bp-min($toc-menu-switch) {
        width: $content-icon-size-large;
        height: $content-icon-size-large;
        flex-basis: $content-icon-size-large;
        margin: 0 $content-icon-large-margin 0 px-to-em(2);
    }
}
.content-section__header-icon > svg {
    width: 100%;
    height: 100%;
    fill: $text-color;
}
.content-section__header {
    .toc-wrapper & {
        flex: 1;
    }
}
.content-section__header-container .content-section__header {
    margin-bottom: 0;
}
h3.content-section__header {
    margin-bottom: 20px;
}

/*
 * Because Word Content puts paragraphs in list items we gonna remove margin
 * from the last paragraph
 */
.content-section li > p:last-child {
    margin-bottom: 0;
}
