/**
 * Forms
 */
fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}
legend {
    max-width: 35em;
    margin: 0 0 0.5em;
    padding: 0;
    font-family: $custom-font-bold;
}
label {
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
    font-family: $custom-font-bold;
}
[type="text"],
[type="password"],
[type="email"],
[type="file"],
[type="search"] {
    @include placeholder {
        opacity: 1;
        color: $text-color;
    }
    display: inline-block;
    width: 100%;
    margin: 0 0 20px;
    padding: 5px 10px;
    line-height: 30px;
    background-color: $white;
    border: 1px solid $text-color;
}
[type="file"] {
    padding: 0;
    border: 0;
}

[type="radio"] + label {
    font-family: $body-font;
    line-height: 1.1;
}
.form__text {
    display: block;
    clear: both;
    font-size: 0.75em;
}

// Custom radio inputs
input[type="radio"] {
    appearance: none;
    position: relative;
    width: 18px;
    height: 18px;
    background: $white;
    border: 1px solid $primary-color;
    border-radius: 50%;
}
input[type="radio"]::before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    background: $primary-color;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.2s;
}
input[type="radio"]:checked::before {
    transform: scale(1);
}
