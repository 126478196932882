/**
 * Baby illustration
 */
.theme-baby {
    @include theme-illustration;
}

/**
 * Hover - focus effects
 */
.theme-card {
    @include hocus {
        @include bp-min($medium) {
            .theme-baby__blob--front {
                transform: scale(1.1, 1.8);
            }
            .theme-baby__blob--back {
                transform: scale(1.1) rotate(-2deg);
            }
        }
        .theme-baby__father-head {
            transform: rotate(5deg) translateY(4%);
        }
        .theme-baby__father-arm {
            transform: rotate(-5deg) translateY(-3%);
        }
        .theme-baby__baby-right-arm {
            transform: rotate(-5deg) translateY(-3%);
        }
        .theme-baby__baby-left-arm {
            transform: rotate(-5deg) translateY(-3%);
        }
    }
}

/**
 * Parts
 */
.theme-baby__blob--front {
    transform-origin: 50% 80%;
    transform: scale(1.1, 1.5);
    transition: transform 0.2s;
}
.theme-baby__blob--back {
    transform: scale(0.5);
    transform-origin: center;
    transition: transform 0.2s;
}
.theme-baby__father-head {
    transform-origin: bottom right;
    transition: transform 0.2s;
}
.theme-baby__father-arm {
    transform-origin: top right;
    transition: transform 0.2s;
}
.theme-baby__baby-left-arm {
    transform-origin: bottom left;
    transition: transform 0.2s;
}
.theme-baby__baby-right-arm {
    transform-origin: bottom right;
    transition: transform 0.2s;
}
