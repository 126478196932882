// stylelint-disable no-unknown-animations

.theme-frequent-users-extended {
    @include theme-illustration;
}
.theme-frequent-users-extended__blob {
    animation: float 14s alternate infinite ease-in-out;
}

/**
 * Animations: one time
 */
@keyframes bucket-slide-in {
    0% {
        opacity: 0;
        transform: translateX(-50%) rotate(-40deg);
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateX(0) rotate(0);
    }
}

@keyframes cactus-slide-in {
    0% {
        opacity: 0;
        transform: rotate(25deg);
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: rotate(0deg);
    }
}

@keyframes ladder-slide-in {
    0% {
        opacity: 0;
        transform: translateX(-10%);
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

/**
 * Animations: constantly running
 */
@keyframes growing-paint {
    0%,
    100% {
        transform: scaleY(1);
    }
    50% {
        transform: scaleY(2);
    }
}

@keyframes moving-arm-left {
    0%,
    100% {
        transform: translateY(0) translateX(0) scaleX(1) rotate(0);
    }
    50% {
        transform: translateY(10%) translateX(10%) scaleX(0.9) rotate(-18deg);
    }
}

@keyframes moving-arm-left-under {
    0%,
    100% {
        transform: translateY(0) scaleX(1) rotate(0);
    }
    50% {
        transform: translateY(-15%) scaleX(0.8) rotate(-13deg);
    }
}

@keyframes moving-arm-right {
    0%,
    100% {
        transform: translateY(0) translateX(0) rotate(0);
    }
    50% {
        transform: translateY(50%) translateX(0%) rotate(-3deg);
    }
}

@keyframes moving-fingers {
    0%,
    100% {
        transform: translateY(0) translateX(0%);
    }
    50% {
        transform: translateY(150%) translateX(-65%);
    }
}

@keyframes moving-roller {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(15%) rotate(1deg);
    }
}

/**
 * Elements
 */
.theme-frequent-users-extended__paint-streak {
    animation: growing-paint 1.85s infinite;
}
.theme-frequent-users-extended__paint-roller {
    transform-origin: top center;
    animation: moving-roller 1.85s infinite;
}
.theme-frequent-users-extended__arm-left {
    transform-origin: bottom right;
    animation: moving-arm-left 1.85s infinite;
}
.theme-frequent-users-extended__arm-left-under {
    transform-origin: bottom right;
    animation: moving-arm-left-under 1.85s infinite;
}
.theme-frequent-users-extended__arm-right {
    transform-origin: bottom left;
    animation: moving-arm-right 1.85s infinite;
}
.theme-frequent-userss-extended__fingers {
    transform-origin: center right;
    animation: moving-fingers 1.85s infinite;
}
.theme-frequent-users-extended__ladder {
    animation: ladder-slide-in 0.7s forwards;
}
.theme-frequent-users-extended__cactus {
    transform-origin: bottom right;
    animation: cactus-slide-in 0.7s forwards;
}
.theme-frequent-users-extended__paint-bucket-right,
.theme-frequent-users-extended__paint-bucket-left {
    transform-origin: bottom left;
    animation: bucket-slide-in 0.7s forwards;
}
