@use "sass:map";

@mixin body-font {
    font-family: $body-font;
}

@mixin heading-font {
    font-family: $heading-font;
    font-weight: normal;
}

@mixin ui-font {
    font-family: $body-font;
    font-weight: bold;
}

@mixin regular-font-size {
    font-size: map.get($font-sizes-small, body);
    @include bp-min($small) {
        font-size: map.get($font-sizes-medium, body);
    }
    @include bp-min($large) {
        font-size: map.get($font-sizes-large, body);
    }
}

@mixin small-font-size {
    font-size: map.get($font-sizes-tiny, body);
    @include bp-min($small) {
        font-size: map.get($font-sizes-tiny, body);
    }
    @include bp-min($large) {
        font-size: map.get($font-sizes-tiny, body);
    }
}
