.strength-indicators {
    display: flex;
    margin: 0;
    font-size: 1.3em;
    color: $grey-medium;
    @include bp-min($medium) {
        font-size: 2.2em;
    }
}
.strength-indicator {
    align-self: center;
    padding: 0 0.15em;
}
.strength-indicator--active {
    color: $text-color;
}
