/**
 * Site header
 */
.site-header {
    @include z-index(site-header);
    @include clearfix;
    position: relative;
    width: 100%;
    @include bp-min($medium) {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(0) translateZ(0);
    }
}
.site-header--is-initialized {
    @include bp-min($large) {
        position: fixed;
        transition: transform 0.4s;
    }
}
// When site header is sticky, add a background color
.site-header--is-initialized::before {
    @include bp-min($large) {
        content: "";
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $white;
        transition: none;
    }
}
.site-header--is-unpinned,
.site-header--is-not-at-top,
.has-overlay .site-header {
    @include bp-min($large) {
        transform: translateX(-50%) translateY(-120%) translateZ(0);
    }
}
.site-header--is-pinned {
    @include bp-min($large) {
        transform: translateX(-50%) translateY(0) translateZ(0);
    }
}
.site-header--is-not-at-top::before {
    @include bp-min($large) {
        @include box-shadow;
        opacity: 1;
        transition: opacity 0.4s;
    }
}
.site-header__inner {
    @include clearfix;
    height: inherit;
    position: relative;
    margin: 0 auto;
    @include bp-min($medium) {
        padding-left: calc(#{$site-logo-width-medium} + #{$gutter});
        padding-right: $gutter;
    }
    @include bp-min($medium-large) {
        padding-left: calc(#{$site-logo-width-large} + #{$gutter});
    }
    @include bp-min($nav-switch) {
        max-width: $site-width-large + 80;
    }
}

.site-header__container {
    position: relative;
    height: $site-header-height-small-screens;
    @include bp-min($medium) {
        display: flex;
        align-items: flex-end;
        height: 140px;
        padding-right: $menu-item-padding-small;
    }
    @include bp-min($large) {
        height: $site-header-height-large-screens;
    }
}
.site-header__container::before {
    @include bp-min($medium) {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: $site-header-bar-height-small-screens;
        background: $white;
    }
    @include bp-min($medium) {
        height: $site-header-bar-height-medium-screens;
    }
    @include bp-min($large) {
        height: $site-header-height-large-screens;
    }
}
.site-header--is-at-top .site-header__container::before {
    @include bp-min($medium) {
        @include box-shadow;
    }
}
.site-header__logo {
    @include z-index(site-header);
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: $site-logo-width-small;
    height: $site-logo-height-small;
    padding: 7px;
    text-align: center;
    transform: translateZ(0);
    transition:
        opacity 0.2s,
        transform 0.2s;
    @include bp-min($medium) {
        top: $gutter-xs;
        left: 25px;
        width: $site-logo-width-medium;
        height: $site-logo-height-medium;
    }
    @include bp-min($medium-large) {
        top: 8px;
        width: $site-logo-width-large;
        height: $site-logo-height-large;
        border: 0;
    }
    @include bp-min($nav-switch) {
        padding: 0;
        left: $gutter;
    }
}
.site-header__logo:hover {
    opacity: 0.8;
}
.site-header__logo::after {
    @include bp-min($medium-large) {
        opacity: 0;
        content: "";
        position: absolute;
        top: 50%;
        right: -$gutter;
        width: 2px;
        height: 70%;
        background: $grey-light;
        transform: translate(0, -50%);
    }
}
.site-header--is-not-at-top .site-header__logo {
    @include bp-min($medium-large) {
        position: absolute;
        top: 0;
        height: $site-logo-width-medium;
        transform: scale(0.6);
    }
}
.site-header--is-not-at-top .site-header__logo::after {
    @include bp-min($medium-large) {
        opacity: 1;
        transition: 0.2s 0.2s;
    }
}
.site-header__logo > svg {
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    @include bp-min($nav-switch) {
        padding: 0 $gutter-sm 0 0;
    }
}

.site-header__nav {
    display: flex;
    height: $site-header-bar-height-small-screens;
    flex: 1;
    @include bp-min($medium) {
        height: $site-header-bar-height-medium-screens;
    }
    @include bp-min($large) {
        position: relative;
        height: $site-header-height-large-screens;
    }
}
.site-header--is-not-at-top .site-header__nav::before {
    @include bp-min($medium-large) {
        opacity: 0;
        transition: none;
    }
}

/*
 * Search dropdown in header
 */
.site-header__search-dropdown {
    @include z-index(search-dropdown);
    opacity: 0;
    overflow: hidden;
    width: 100%;
    max-height: 0;
    position: absolute;
    top: $search-button-small;
    left: auto;
    right: 0;
    @include bp-min($nav-switch) {
        right: $gutter;
    }
    @include bp-min($medium-large) {
        top: 63px;
    }
}
