// stylelint-disable no-unknown-animations

.theme-pregnant-extended {
    @include theme-illustration;
}
.theme-pregnant-extended__blob {
    animation: float 14s alternate infinite ease-in-out;
}

/**
 * Animations: one time
 */
@keyframes crib-in {
    0% {
        opacity: 0;
        transform: rotate(-60deg);
    }
    39% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    70% {
        transform: rotate(5deg);
    }
    100% {
        opacity: 1;
        transform: rotate(0);
    }
}
@keyframes bear-in {
    0% {
        opacity: 0;
        transform: translate(-100%, 200%) rotate(-100deg);
    }
    39% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    70% {
        transform: translate(10%, -10%) rotate(0deg);
    }
    100% {
        opacity: 1;
        transform: translate(0%, 0%);
    }
}

@keyframes blocks-in {
    0% {
        opacity: 0;
        transform: translate(50%, 200%);
    }
    39% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}
@keyframes father-slide-in {
    0% {
        transform: translateX(-14%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes mother-slide-in {
    0% {
        transform: translateX(14%);
    }
    100% {
        transform: translateX(0);
    }
}

/**
 * Animations: constantly running
 */
@keyframes spin-cloud-offset {
    0% {
        transform: translateX(-12%) rotateY(0);
    }
    100% {
        transform: translateX(-12%) rotateY(-360deg);
    }
}
@keyframes spin-cloud {
    0% {
        transform: rotateY(0);
    }
    100% {
        transform: rotateY(-360deg);
    }
}
@keyframes spin-star {
    0% {
        transform: rotateY(0);
    }
    100% {
        transform: rotateY(-360deg);
    }
}
@keyframes spin-star-squeeze {
    0% {
        transform: scaleX(2) rotateY(0);
    }
    50% {
        transform: scaleX(1) rotateY(180deg);
    }
    100% {
        transform: scaleX(2) rotateY(0);
    }
}
@keyframes move-arm {
    0% {
        transform: rotate(0) translate(0, 0);
    }
    50% {
        transform: rotate(-10deg) translate(2%, -3.5%);
    }
    100% {
        transform: rotate(0) translate(0, 0);
    }
}
@keyframes mobile-suspension {
    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(2deg);
    }
    100% {
        transform: rotate(0);
    }
}

/**
 * Constantly moving elements
 */
.theme-pregnant-extended__cloud {
    transform-origin: center;
    animation: spin-cloud 10s infinite;
}
.theme-pregnant-extended__cloud--offset {
    transform: translateX(-12%);
    animation-name: spin-cloud-offset;
    animation-direction: reverse;
}
.theme-pregnant-extended__mobile-arm-left {
    transform-origin: center;
    animation: mobile-suspension 4s infinite;
    animation-direction: reverse;
}
.theme-pregnant-extended__mobile-arm-right {
    transform-origin: center;
    animation: mobile-suspension 4s infinite;
}
.theme-pregnant-extended__mother-arm {
    transform-origin: bottom right;
    animation: move-arm 4s infinite;
}
.theme-pregnant-extended__star {
    transform-origin: center;
}
.theme-pregnant-extended__star--squeeze {
    animation: spin-star-squeeze 5s infinite;
    animation-direction: reverse;
}

/**
 * One time
 */
.theme-pregnant-extended__bear {
    opacity: 0;
    transform-origin: bottom left;
    animation: bear-in 0.65s 0.1s forwards;
}
.theme-pregnant-extended__blocks {
    opacity: 0;
    transform-origin: bottom right;
    animation: blocks-in 0.7s 0.1s forwards;
}
.theme-pregnant-extended__crib {
    opacity: 0;
    transform-origin: bottom right;
    animation: crib-in 0.7s forwards;
}
.theme-pregnant-extended__father,
.theme-pregnant-extended__father-arm-right {
    animation: father-slide-in 0.7s forwards;
}
.theme-pregnant-extended__mother {
    animation: mother-slide-in 0.7s forwards;
}
