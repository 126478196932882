/*
 *  Pure css tooltips. Take their (string) content from data-tooltip attributes.
 *
 *  @todo - tabbable version
*/
[data-tooltip] {
    position: relative;
    cursor: pointer;
}
[data-tooltip]::before,
[data-tooltip]::after {
    content: "";
    position: absolute;
    top: 100%;
    z-index: 666;
    background: $primary-color;
    color: $white;
    font-size: 14px;
    cursor: pointer;
    pointer-events: none;
}
[data-tooltip]::before {
    // speech arrow
    content: "";
    left: 50%;
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 1rem;
    transform: translate3d(-50%, 100%, 0) rotate(45deg);
    opacity: 0;
    transition:
        transform 0.15s cubic-bezier(0.6, 0, 0.4, 1),
        opacity 0.15s cubic-bezier(0.6, 0, 0.4, 1);
}
[data-tooltip]::after {
    // contents
    content: attr(data-tooltip);
    left: 50%;
    margin-top: 1.75rem;
    padding: 8px $gutter-xs;
    border-radius: 2rem 0;
    transform: scale3d(0, 0, 0) translate3d(-50%, 0, 0);
    transform-origin: 0 0;
    transition: transform 0.25s cubic-bezier(0.6, 0, 0.4, 1);
    max-width: 10em;
    text-align: center;

    @include bp-min($medium) {
        width: auto;
        max-width: none;
        white-space: nowrap;
        padding: $gutter-xs $gutter-sm;
    }
}
[data-tooltip]:hover {
    &::before {
        opacity: 1;
        transform: translate3d(-50%, 0, 0) rotate(45deg);
        transition:
            transform 0.25s cubic-bezier(0.6, 0, 0.4, 1),
            opacity 0.3s cubic-bezier(0.6, 0, 0.4, 1);
    }
    &::after {
        transform: scale3d(1, 1, 1) translate3d(-50%, 0, 0);
    }
}
