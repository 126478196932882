// stylelint-disable no-unknown-animations
// stylelint-disable declaration-no-important

/*
 * Ingredients list, show hide panels
 */
.ingredients-list {
    position: relative;
}
.ingredients-list.is-hidden {
    display: none;
}
.ingredients-list__container + .button {
    margin: $single-line 0 0;
}

/*
 * Table header
 */
.ingredients-list__header-container {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
}

.ingredients-list__table-header {
    position: relative;
    width: 40%;
    float: right;
    transition: opacity 0.15s, transform 0.15s;
    padding: 7px 0;
    @include bp-min($medium) {
        width: 35%;
        padding: $quarter-line 0;
    }
}
.ingredients-list__table-header .ingredients-list__properties {
    display: flex;
    width: 100%;
}
.ingredients-list__table-header .ingredients-list__properties li {
    height: 100%;
    font-family: $custom-font-bold;
    font-size: 0.65em;
    text-transform: uppercase;
    @include bp-max($small) {
        padding: 0;
    }
    @include bp-min($medium) {
        font-size: 0.75em;
    }
}

/*
 * Articles
 */
.ingredients-list__article {
    @include clearfix;
    width: 100%;
    margin-bottom: $half-line;
    animation: fade-in 0.25s;
}

// Toggle 'sticky headers'
.ingredients-list__article.is-read
    + .ingredients-list__article
    .ingredients-list__table-header {
    transform: translateZ(0);
    opacity: 1;
}
.ingredients-list__section {
    position: relative;
    width: 100%;
    z-index: 0;
    clear: both;
    margin-bottom: 5px;
}
// Expand clickable area
.ingredients-list__button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.ingredients-list__section-title.section-supertitle {
    display: inline-block;
    flex: 1;
    width: 60%;
    margin: 0;
    padding: $half-line 0;
    @include bp-min($medium) {
        width: 65%;
    }
}

/*
 * Article headers
 */
.ingredients-list__header {
    position: relative;
    width: 100%;
    z-index: 333;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: $white;
    font-family: $custom-font-regular;
    box-shadow: 0 0 20px 0 rgba($text-color, 0.2);
}

.js .ingredients-list__header {
    cursor: pointer;
}

.js .ingredients-list__header:hover::after {
    opacity: 1;
    transition: opacity 0.15s;
}

// Arrow up down toggle
.js .ingredients-list__header::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -10px;
    display: block;
    width: 20px;
    height: 20px;
    margin: -10px 0 0 22px;
    background: $primary-color url("../../images/chevron--small-white-down.svg")
        no-repeat 50% 54%;
    background-size: 40% auto;
    color: $white;
    border: 1px solid;
    border-radius: 50%;
    transform: translate3d(0, 0, 0) rotate(0.75turn);
    transition: transform 0.2s;
    @include bp-min($medium) {
        left: 0;
        margin-top: -15px;
        width: 30px;
        height: 30px;
        border-radius: 100%;
    }
}
.js .is-expanded .ingredients-list__header::before {
    transform: translate3d(0, 0, 0) rotate(1turn);
}

// performant background transition
.ingredients-list__header::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $grey-lighter;
    opacity: 0;
}

.ingredients-list__button {
    @include expand-clickable-area;
    @include bare-button;
    position: static;
    display: block;
    width: 100%;
    padding: 0;
    color: $text-color;
    text-decoration: none;
    &::after {
        content: "" !important; // Override bare button style
    }
}
.no-js .ingredients-list__button {
    cursor: default;
}
.js .ingredients-list__button:hover {
    text-decoration: underline;
}

.ingredients-list__title {
    display: inline-block;
    width: 60%;
    margin: 0;
    padding: 1em 1.2em;
    padding-left: 40px;
    font-size: 14px;
    color: $text-color;
    font-family: $custom-font-regular;
    @include bp-min($medium) {
        width: 65%;
        padding-right: $gutter;
        padding-left: 65px;
        font-size: 19px;
    }
}
.no-js .ingredients-list__title {
    padding-left: 15px;
    @include bp-min($medium) {
        padding-left: 30px;
    }
}

// Make featured sections more compact,
// Differs from default because __title has a 'subtitle'
.ingredients-list--featured .ingredients-list__title {
    @include bp-min($medium) {
        line-height: 1em;
        padding-top: 0.7em;
        padding-bottom: 0.7em;
    }
}

.ingredients-list__title span {
    font-size: 14px;
}

// last items
.ingredients-list__section:last-of-type,
.ingredients-list__article:last-of-type,
.ingredients-list__article:last-of-type .ingredients-list__section:last-child {
    margin-bottom: $single-line;
}

/*
 * Properties
 */
.ingredients-list__properties {
    @include bare-list;
    position: relative;
    display: flex;
    align-items: stretch;
    width: 40%;
    height: auto;
    font-family: $custom-font-regular;
    font-size: 12px;
    color: $text-color;
    pointer-events: none;
    @include bp-min($medium) {
        width: 35%;
        font-size: 15px;
    }
}
.ingredients-list__properties li {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: auto;
    margin: 0;
    padding: 0 10px;
    text-align: center;
}
.ingredients-list__properties figure {
    margin: 0;
}
.ingredients-list__frequency {
    background: $grey-lighter;
}

// Body text
.ingredients-list__inner {
    // collapse
    overflow: hidden;
    display: block;
    background-color: $white;
    color: $text-color;
    box-shadow: 0 0 20px 0 rgba($text-color, 0.2),
        inset 0 10px 30px -15px rgba($grey-dark, 0.6);
    transform: translateZ(0) scaleY(0);
    will-change: transform;
    transform-origin: center top;
    transition: transform 1s linear;
}
.ingredients-list__inner-wrapper {
    will-change: opacity, font-size;
    opacity: 0;
    padding: 0;
    font-size: 0;
    transition: opacity 0.15s 0.08s;
}
.is-expanded .ingredients-list__inner,
.no-js .ingredients-list__inner {
    // expand
    transform: translateZ(0) scaleY(1);
    transition: transform 0.12s linear;
    .ingredients-list__inner-wrapper {
        padding: $gutter-sm;
        opacity: 1;
        font-size: inherit;
        transition: opacity 0.4s;
        @include bp-min($small) {
            padding: $gutter;
        }
    }
}
.js .ingredients-list__link {
    font-size: 0;
    margin-top: 0;
}
.is-expanded .ingredients-list__link,
.no-js .ingredients-list__link {
    margin-top: $single-line;
    font-size: 1em;
}
.is-expanded .ingredients-list__inner-wrapper {
    opacity: 1;
}
.ingredients-list__inner-content :last-of-type {
    margin: 0;
}

/**
 * Notice in article
 */
.ingredients-list__notice {
    opacity: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    max-height: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 0.8em;
    border-bottom: 1px solid $grey;
    transition: max-height 0.2s;
    @include bp-min($small) {
        margin-top: -10px;
    }
}
.is-expanded .ingredients-list__notice,
.no-js .ingredients-list__notice {
    @include clearfix;
    margin-bottom: $single-line;
    padding-bottom: $single-line;
    max-height: 200px;
    overflow: visible;
    opacity: 1;
}
.ingredients-list__notice-list {
    @include bare-list;
    position: relative;
    float: left;
    margin: 0.2em 0 0;
    line-height: 1.15;
}
.ingredients-list__notice-term {
    margin: 0 0 0 0.75em;
    font-weight: normal;
    font-family: $custom-font-bold;
}
.ingredients-list__notice-definition {
    display: inline;
    margin: 0 0 0 0.75em;
    padding: 0;
}

.ingredients-list__notice-definition:not(:first-of-type) {
    margin: 0;
    text-transform: lowercase;
}
.ingredients-list__notice .notice-icon {
    margin: 0;
}

/** 
 * Place a div with an ID where a skiplink can link to
 */
.ingredients-list__end-marker {
    display: none;
}
