// stylelint-disable no-unknown-animations

.theme-allergic-extended {
    @include theme-illustration;
}
.theme-allergic-extended__blob {
    transform-origin: center;
    animation: float 14s alternate infinite ease-in-out;
}

/**
 * Animations: one time
 */
@keyframes cat-slide-in {
    0% {
        transform: translateX(20%);
    }
    50% {
        transform: translateX(0);
    }
}
@keyframes soap-slide-in {
    0% {
        transform: translate(-100%, 0);
    }
    50% {
        transform: translate(0, 0);
    }
}

/**
 * Animations: constantly running
 */
@keyframes dangle {
    0%,
    100% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(5deg);
    }
}

@keyframes fizz {
    0%,
    100% {
        transform: translateY(1%) scaleX(1.05);
    }
    60% {
        transform: translateY(2%) scaleX(0.98);
    }
}
@keyframes rise {
    0% {
        opacity: 1;
        transform: translateY(200%) scale(1.1);
    }
    100% {
        opacity: 0;
        transform: translateY(-200%) scale(0.8);
    }
}
@keyframes tail-wiggle {
    0%,
    100% {
        transform: translateY(-2%) rotate(0);
    }
    50% {
        transform: translateY(0) rotate(29deg);
    }
}
@keyframes itchy-head {
    0%,
    14%,
    100% {
        transform: translate(0, 0) rotate(0);
    }
    1%,
    12% {
        transform: translate(3%, 2%) rotate(-7deg);
    }
}
@keyframes itchy-shoulder-left {
    0% {
        transform: rotate(0) translate(0, 0);
    }
    2%,
    6%,
    10% {
        transform: rotate(-13deg) translate(10%, 12%);
    }
    4%,
    8%,
    12% {
        transform: rotate(-13deg) translate(10%, 14%);
    }
    14%,
    100% {
        transform: rotate(0) translate(0, 0);
    }
}
@keyframes itchy-shoulder-right {
    0% {
        transform: rotate(0) scale(1, 1.02) translate(0, 1%);
    }
    2%,
    6%,
    10% {
        transform: rotate(-1deg) scale(1, 1.02) translate(0, -3%);
    }
    4%,
    8%,
    12% {
        transform: rotate(-1deg) scale(1, 1.02) translate(0, 1%);
    }
    14%,
    100% {
        transform: rotate(0) translate(0, 0);
    }
}
@keyframes itchy-arm-left {
    0% {
        transform: rotate(0) scaleY(1) translate(0, 0%);
    }
    2%,
    6%,
    10% {
        transform: rotate(-12deg) scaleY(0.9) translate(7%, 7.5%);
    }
    4%,
    8%,
    12% {
        transform: rotate(-12deg) scaleY(0.9) translate(7%, 14%);
    }
    14%,
    100% {
        transform: rotate(0) scaleY(1) translate(0, 0);
    }
}
@keyframes itchy-arm-right {
    0% {
        transform: rotate(0) translate(4%, 3%);
    }
    2%,
    6%,
    10% {
        transform: rotate(-10deg) translate(4%, -5%);
    }
    4%,
    8%,
    12% {
        transform: rotate(-10deg) translate(4%, 3%);
    }
    14%,
    100% {
        transform: rotate(0) translate(0, 0);
    }
}

/**
 * Elements
 */
.theme-allergic-extended__head {
    transform-origin: center;
    animation: itchy-head 7s infinite;
}
.theme-allergic-extended__arm-right {
    transform-origin: bottom center;
    animation: itchy-arm-right 7s infinite;
}
.theme-allergic-extended__arm-left {
    transform-origin: top center;
    animation: itchy-arm-left 6s 3s infinite;
}
.theme-allergic-extended__arm-left-top {
    transform-origin: bottom center;
    animation: itchy-shoulder-left 6s 3s infinite;
}
.theme-allergic-extended__arm-right-top {
    transform-origin: top left;
    animation: itchy-shoulder-right 7s infinite;
}
.theme-allergic-extended__bubble-left,
.theme-allergic-extended__bubble-right {
    transform-origin: center bottom;
    animation: rise 3s infinite;
}
.theme-allergic-extended__bubble-right {
    animation-delay: 0.2s;
}
.theme-allergic-extended__cat,
.theme-allergic-extended__chair {
    animation: cat-slide-in 0.8s forwards;
}
.theme-allergic-extended__cat-head,
.theme-allergic-extended__glove {
    transform-origin: bottom center;
    animation: dangle 6s infinite;
}
.theme-allergic-extended__cat-tail {
    transform-origin: top right;
    animation: tail-wiggle 14s infinite;
}
.theme-allergic-extended__sink {
    transform-origin: bottom center;
    animation: fizz 4s infinite;
}
.theme-allergic-extended__soap {
    animation: soap-slide-in 1s forwards;
}
