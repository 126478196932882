// stylelint-disable font-family-no-missing-generic-family-keyword
@use "sass:color";

.page-block {
    @include box-shadow;
    position: relative;
    float: left;
    width: 100%;
    margin: 5px 0;
    background: $white;
    color: $text-color;
    text-align: left;
    text-decoration: none;
    white-space: normal;
    border-radius: 3rem 0;
    @include bp-max($small) {
        display: flex;
        align-items: center;
    }
    @include bp-min($small) {
        width: calc(50% - #{$gutter});
        margin: $gutter-sm;
    }
    @include bp-min($medium) {
        width: calc(50% - #{$gutter});
    }
    @include bp-min($medium-large) {
        width: calc(25% - #{$gutter});
        margin: 0 $gutter-sm;
    }
    @include bp-min($large) {
        width: calc(25% - #{$gutter-lg});
        margin: 0 $gutter;
    }
    &::before {
        @include box-shadow;
        opacity: 0;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: inherit;
        transition: opacity 0.2s;
    }
    .styleguide & {
        width: 300px;
    }
}
.page-block:hover::before {
    opacity: 0.5;
}
.page-block__image {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: $page-block-image-size-medium;
    margin: 0;
    border-radius: 3rem 0 0;
    box-shadow: inset -1px 0 0 color.adjust($grey, $lightness: 2%);
    @include bp-max($small) {
        width: $page-block-thumb-size;
        height: $page-block-thumb-size;
        float: left;
    }
    @include bp-min($small) {
        box-shadow: inset 0 -1px 0 color.adjust($grey, $lightness: 2%);
    }
    @include bp-min($large) {
        height: $page-block-image-size-large;
    }
}
.page-block__image > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 80%;
    height: 80%;
    transform: translate(-50%, -50%);
    fill: $blue-muted;
}
.page-block__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: "object-fit: cover"; // for polyfill
    @include bp-min($small) {
        min-width: 100%;
        height: $page-block-image-size-large;
    }
}
.page-block__header {
    position: relative;
    padding: $gutter-sm $gutter-lg $gutter-sm $gutter-sm;
    hyphens: auto;
    @include bp-max($small) {
        float: left;
        width: calc(100% - #{$page-block-thumb-size});
        padding: $gutter-xs $gutter $gutter-xs $gutter-xs;
    }
}
.page-block__header::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 2.5em;
    height: 100%;
    background: url("../../images/chevron--pink.svg") no-repeat 50% 50% / 0.8em
        auto;
    transform: translate3d(0, 0, 0);
    transition: transform 0.2s;
}
.page-block:hover .page-block__header::after,
.page-block:focus .page-block__header::after {
    transform: translate3d(0.3em, 0, 0);
}
.page-block__supertitle {
    margin-top: 0;
    margin-bottom: 0.15em;
    font-family: $custom-font-regular;
    font-size: 14px;
    line-height: 1.15;
    @include bp-min($small) {
        font-size: 16px;
    }
}
.page-block__title {
    margin: 0;
    font-family: $custom-font-bold;
    font-size: 15px;
    line-height: 1.15;
    text-transform: uppercase;
    word-wrap: break-word;
    @include bp-min($large) {
        font-size: 18px;
    }
}
.page-block:hover .page-block__title {
    color: $tertiary-color;
}
.page-block-container {
    @include clearfix;
    @include bp-min($small) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
    }
    @include bp-min($medium-large) {
        margin: $gutter (-$gutter) 0;
    }
}
.page-block-container + .button {
    margin-top: 25px;
    @include bp-min($small) {
        margin-top: $triple-line;
    }
}
