@use "sass:color";
@use "sass:map";
// stylelint-disable media-feature-name-no-unknown, declaration-no-important, selector-no-vendor-prefix, selector-pseudo-class-no-unknown, font-family-no-missing-generic-family-keyword

/* ==========================================================================
   mixins
   ========================================================================== */

/**
 * Clearfix
 * Actually an @extend, but we don't want to depend on inclusion order
 *
 * Usage:
 * section { @include clearfix(); }
 */
@mixin clearfix {
    &::after {
        display: table;
        clear: both;
        content: "";
    }
}

/**
 * Get z-index values from z-index map
 */
@mixin z-index($type) {
    z-index: map.get($z-index, $type);
}

/*
 * Bare list
 *
 * Get rid of list-style and padding
 */
@mixin bare-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

/*
 * Bare button
 */
@mixin bare-button {
    appearance: none;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    background: none;
    text-decoration: none;
    &::after {
        content: none;
        background: none;
    }
}

@mixin disable-interaction {
    position: relative;
    // temporary: disable interaction
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

/**
 * Placeholder styling
 */
@mixin placeholder {
    &:placeholder {
        @content;
    }
    &::placeholder {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

/**
 * Block title / big heading
 */
@mixin block-title {
    font-size: 24px;
    margin-bottom: $half-margin;
    @include bp-min($medium) {
        font-size: 32px;
    }
    @include bp-min($large) {
        font-size: 44px;
    }
}

/**
 * Reserve space for site-header
 */
@mixin site-header-padding {
    @include bp-min($medium) {
        padding-top: $site-header-height-medium-screens;
    }
    @include bp-min($large) {
        padding-top: $site-header-height-large-screens;
    }
}

/**
 * For image replacement
 */
@mixin hide-text {
    font: 0 / 0 a;
    text-shadow: none;
    color: transparent;
    background-color: transparent;
    border: 0 none;
}

/**
 * Media query breakpoints mixins
 *
 * Usage:
 * div { width: 60px;
 *  @include bp-min($small) { width: 80px; }
 * }
 */
@mixin bp-min($point: 0) {
    @media (min-width: px-to-em($point)) {
        @content;
    }
}

@mixin bp-max($point: 0) {
    @media (max-width: (px-to-em($point) - 0.0625em)) {
        @content;
    }
}

@mixin bp-min-max($min-point: 0, $max-point: 0) {
    @media (min-width: px-to-em($min-point)) and (max-width: (px-to-em($max-point) - 0.0625em)) {
        @content;
    }
}

/**
 * For targetting high dpi screens
 * Usage:
 * div {
 *  width: 30px; height: 30px; background: url('img.jpg');
 *  @include high-dpi() { background: url('image-2x.jpg'); @include background-size(30px 30px); }
 * }
 */
@mixin high-dpi {
    @media (min-device-pixel-ratio: 2) and (min-resolution: 192dpi) and (min-resolution: 2dppx) {
        @content;
    }
}

/*
 * Box shadow
 *
 * Box shadow settings for this theme
 */
@mixin box-shadow {
    box-shadow: 0 2px 15px 0 rgba($black, 0.27);
}

@mixin hocus {
    &:hover,
    &:focus {
        @content;
    }
}

@mixin scroll-bars-default {
    @include bp-min($medium) {
        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0 1px rgba($grey-light, 0.85);
            background-color: $whitish;
        }
        ::-webkit-scrollbar {
            width: 14px;
            background-color: $whitish;
        }
        ::-webkit-scrollbar-thumb {
            background-color: color.adjust($grey-medium, $lightness: 3.5%);
            border: 3px solid transparent;
            border-radius: 10px;
            background-clip: padding-box;
        }
    }
}

@mixin scroll-bars-alternate {
    @include bp-min($medium) {
        ::-webkit-scrollbar-track {
            box-shadow: none;
            background-color: $secondary-color;
        }
        ::-webkit-scrollbar {
            width: 14px;
            background-color: $secondary-color;
        }
        ::-webkit-scrollbar-thumb {
            background-color: rgba($text-color, 0.2);
            border: 5px solid transparent;
            background-clip: padding-box;
        }
    }
}

/**
 * Settings for theme illustration
 */
@mixin theme-illustration {
    position: relative;
    pointer-events: all;
    overflow: visible !important;
    path,
    g {
        transform-box: fill-box;
    }
}

/**
 * Generic card hover effect
 */
@mixin card-hover-effect {
    position: relative;
    transition: background 0.2s;
    &:hover,
    &:focus {
        background-color: $grey-lighter;
    }
    &::before {
        @include bp-min($medium) {
            content: "";
            position: absolute;
            top: 50%;
            right: $gutter;
            transform: translateY(-50%);
            height: 35px;
            width: 20px;
            background-image: url("../../images/chevron--blue.svg");
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: 0.8em auto;
            opacity: 0;
            transition: opacity 0.2s;
        }
    }
    &:hover::before,
    &:focus::before {
        @include bp-min($medium) {
            opacity: 1;
        }
    }
}

/**
 * For creating an entirely clickable element
 */
@mixin expand-clickable-area {
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@mixin expand-clickable-area-tap-size {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        min-height: $tap-size;
        min-width: $tap-size;
        transform: translate(-50%, -50%);
    }
}

/**
 * Read more text link
 * As seen in cards
 */
@mixin read-more-link {
    width: auto;
    margin: auto auto 0 0;
    &:hover,
    &:focus,
    :hover > &,
    :focus > & {
        text-decoration: underline;
    }
    > svg {
        display: inline-block;
        vertical-align: middle;
        height: 1em;
        width: 0.7em;
        margin: -0.15em 0 0 0.2em;
        transition: transform 0.2s;
        :hover > &,
        :focus > & {
            transform: translateX(0.15em);
        }
    }
}

/**
 * Aspect ratio for images, videos and iframes.
 *
 * Usage:
 * figure {
 *     @include aspect-ratio(16, 9);
 * }
 */
@mixin aspect-ratio($width, $height) {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: 0;
    padding-bottom: (100% / $width) * $height;

    // Requires the image to be a direct child.
    > iframe,
    > img,
    > video,
    > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    // Some embeds can't handle the object fit.
    > iframe {
        object-fit: unset;
    }

    /* stylelint-disable order/order */
    @supports (aspect-ratio: 16 / 9) {
        overflow: visible;
        position: relative;
        width: 100%;
        padding-bottom: 0;

        // Requires the image to be a direct child.
        > iframe,
        > video,
        > img,
        > div {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            aspect-ratio: $width / $height;
        }
        // Some embeds can't handle the object fit.
        > iframe {
            object-fit: unset;
        }
    }
}

/**
 * Helper mixin for reduced motion media query
 */
@mixin reduced-motion {
    @media (prefers-reduced-motion) {
        @content;
    }
}

@mixin substance-filter-option {
    @include bare-button;
    width: 100%;
    padding-left: 19px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 19px;
    font-size: 14px;
    font-family: $custom-font-regular;
}

@mixin substance-filter-icon-dimensions {
    display: flex;
    width: 10px;
    height: 10px;
}

@mixin substance-filter-remove {
    @include bare-button;
    right: 40px;
    padding: 0;
}
