.collapsible-section {
    @include row-bottom(medium);
}
.collapsible-section__button {
    @include bare-button;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: -1px; // Fix overlapping border for different content structures
    padding: 1.25em 0;
    border-top: 1px solid $grey-light;
    border-bottom: 1px solid $grey-light;
}
.collapsible-section__button[aria-expanded="true"] {
    border-bottom-color: transparent;
}
.collapsible-section__button::before {
    content: "";
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    margin: 0 0.55em 0 0.45em;
    background: url("../../images/chevron--down.svg") no-repeat 50% 50% / 1.4em
        auto;
    transform: translateZ(0) rotate(0.75turn);
    transition: transform 0.2s;
}
.collapsible-section__button[aria-expanded="true"]::before {
    transform: translateZ(0) rotate(1turn);
}
.collapsible-section__content {
    display: none;
    padding: 1em 0 2em;
}
.collapsible-section__button[aria-expanded="true"]
    + .collapsible-section__content {
    display: block;
    border-bottom: 1px solid $grey-light;
}
.collapsible-section .collapsible-section__button > h2,
.collapsible-section .collapsible-section__button > h3 {
    margin: 0;
    line-height: 1;
}
