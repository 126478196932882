/**
 * Index grouped cards
 */
.index-group {
    scroll-margin-top: 1.5em;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: $gutter-xs 0;
    @include bp-min($small) {
        width: calc(100% + 4px);
        margin-left: -2px;
        margin-right: -2px;
    }
    @include bp-min($medium) {
        padding: $gutter 0;
    }
}
.index-group:last-of-type {
    margin-bottom: $gutter-xs;
    @include bp-min($medium) {
        margin-bottom: $gutter;
    }
}

// in card
.index-group__header {
    width: 100%;
    padding: 0 $gutter-sm;
    @include bp-min($medium) {
        padding: 0 25px;
    }
}
.index-group__header h2 {
    font-family: $custom-font-bold;
    font-size: 26px;
    margin: 0 0 $half-line;
    text-transform: uppercase;
    @include bp-min($medium) {
        margin: 0 0 $single-line;
    }
}
