@use "sass:map";

.search-button {
    @include bare-button;
    display: block;
    min-width: 0;
    width: $search-button-medium;
    height: $search-button-medium;
    margin: 0;
    padding: 0;
    background-color: $primary-color;
    color: $white;
    font-family: $custom-font-regular;
    font-size: 16px;
    line-height: $search-button-medium;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    transition: background 0.2s;
    @include bp-min($medium-large) {
        font-size: map.get($font-sizes-medium, body);
    }
}
.search-form--in-header .search-button {
    width: $search-button-small;
    height: $search-button-small;
    line-height: $search-button-small;
    font-size: 14px;
    @include bp-min($medium-large) {
        font-size: map.get($font-sizes-small, body);
    }
}
.search-button:hover,
.search-button:focus {
    background-color: $grey-light;
    color: $text-color;
}
.search-button:hover svg,
.search-button:focus svg {
    fill: $text-color;
}
.search-button:hover span,
.search-button:focus span {
    opacity: 1;
}
.search-button > svg {
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    margin: 0 auto;
    fill: $white;
}
.search-button > span {
    display: none;
    @include bp-min($medium-large) {
        display: inline-block;
        vertical-align: middle;
        margin-left: $gutter-xs;
        opacity: 0.8;
    }
}

// Variations
.search-button--large {
    @include bp-min($medium-large) {
        width: $search-button-large;
        height: $search-button-large;
        line-height: $search-button-large;
    }
}
.search-button--has-label {
    @include bp-min($medium-large) {
        width: auto;
        padding: 0 $gutter;
    }
}
.search-button--has-label > svg {
    @include bp-min($medium-large) {
        display: inline-block;
        margin: 0;
    }
}
