// stylelint-disable no-unknown-animations

.theme-kids-extended {
    @include theme-illustration;
}
.theme-kids-extended__blob {
    animation: float 14s alternate infinite ease-in-out;
}

/**
 * Animations: one time
 */
@keyframes tree-slide-in {
    0% {
        opacity: 0;
        transform: translateX(-10%);
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

/**
 * Animations: constantly running
 */
@keyframes balloon-wind {
    0%,
    100% {
        transform: translateX(-2%) rotate(0) scale(1);
    }
    50% {
        transform: translateX(-2%) rotate(12deg) scale(1.05);
    }
}
@keyframes balloon-pull {
    0%,
    100% {
        transform: rotate(0) scale(1);
    }
    50% {
        transform: rotate(-5deg) scale(0.96);
    }
}

@keyframes tree {
    0%,
    100% {
        transform: rotate(0) scale(1);
    }
    50% {
        transform: rotate(-2deg) scaleX(1.03);
    }
}
@keyframes twist-body {
    0% {
        transform: rotate(0) translateY(0);
    }
    100% {
        transform: rotate(-5deg) translateY(2%);
    }
}
@keyframes kid-look-up {
    0% {
        transform: rotate(0) translateY(2%);
    }
    100% {
        transform: rotate(-25deg) translateY(2%);
    }
}
@keyframes father-look-up {
    0% {
        transform: rotate(0) translateY(2%);
    }
    100% {
        transform: rotate(-5deg) translateY(2%);
    }
}

/**
 * Elements
 */
.theme-kids-extended__father-kid {
    transform-origin: bottom center;
    animation: twist-body 3s alternate infinite linear;
}
.theme-kids-extended__father-head {
    transform-origin: bottom center;
    animation: father-look-up 3s alternate infinite linear;
}
.theme-kids-extended__kid-head {
    transform-origin: bottom center;
    animation: kid-look-up 3s alternate infinite linear;
}
// Balloon, string included
.theme-kids-extended__balloon {
    transform-origin: bottom center;
    animation: balloon-pull 5s infinite;
}
// Actual balloon
.theme-kids-extended__balloon-blob {
    transform-origin: bottom center;
    animation: balloon-wind 5s infinite;
}
.theme-kids-extended__tree-left,
.theme-kids-extended__tree-right {
    opacity: 0;
    animation: tree-slide-in 0.5s forwards;
}
.theme-kids-extended__tree-left {
    animation-delay: 0.2s;
}
.theme-kids-extended__tree-leaves-left {
    transform-origin: bottom center;
    animation: tree 5s infinite;
}
.theme-kids-extended__tree-leaves-right {
    transform-origin: bottom center;
    animation: tree 5s 0.5s infinite;
}
