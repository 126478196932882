/* stylelint-disable property-no-vendor-prefix */

$category-remove-label-margin-right: 19px;

.category-filter {
    position: relative;
    margin-bottom: 1em;
    @include bp-min($medium) {
        margin-bottom: 0;
    }
}
.category-filter__inner {
    top: 0;
    width: 100%;
    @include bp-min($medium) {
        width: 270px;
    }
}
.category-filter__controls {
    position: absolute;
    width: 100%;
    pointer-events: none;
}

// Chosen option
.category-filter__text {
    display: none;
}
.category-filter__text--active {
    display: flex;
    width: calc(100% - #{$category-remove-label-margin-right});
    margin-left: $category-remove-label-margin-right;
    font-size: 16px;
}
.category-filter__current-filter {
    overflow: hidden;
    display: block;
    width: 150px;
    background: $white;
    font-family: $custom-font-regular;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Hide chosen filter label when input is searchable (has focus)
.category-filter__search[type="search"]:focus-visible
    ~ .category-filter__controls
    .category-filter__current-filter {
    display: none;
}

.category-filter__label {
    @include body-font;
    display: block;
    margin-bottom: 8px;
    font-family: $custom-font-regular;
    font-size: 16px;
    cursor: pointer;
}
.category-filter__input-wrapper {
    display: flex;
    align-items: center;
}

.category-filter__input-wrapper > .category-filter__search {
    -webkit-appearance: none;
    width: calc(100% - 50px);
    height: 46px;
    margin: 0;
    margin-left: 1px;
    padding-left: 19px;
    font-size: 16px;
    border: none;
    outline: 3px solid $white;
    @include placeholder {
        color: $text-color;
        font-size: 14px;
        font-family: $custom-font-regular;
        font-style: italic;
    }
}
.category-filter__search[type="search"]:focus-visible {
    border: none;
}
// Dropdown toggle
.category-filter__button {
    @include bare-button;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $tap-size-large;
    height: $tap-size-large;
    margin-left: auto;
    padding: 0;
    background: $grey-lighter;
}
// Chevron
.category-filter__icon {
    @include substance-filter-icon-dimensions;
    transition: transform 0.2s;
}
.category-filter__icon > svg {
    width: 100%;
    height: 100%;
    transform: rotate(90deg);
}

// Cancel button
.category-filter__remove {
    @include substance-filter-remove;
    width: $tap-size-large;
    margin-left: auto;
    pointer-events: auto;
}
.category-filter__remove--active {
    display: block;
}

.category-filter__close-icon-wrapper {
    display: flex;
    justify-content: center;
}
.category-filter__option {
    width: 100%;
}
.category-filter__option:hover {
    background: $grey-lighter;
}
.category-filter__option-list {
    display: none;
}
.category-filter__option-list--open {
    @include bare-list;
    @include z-index(category-filter);
    position: absolute;
    overflow-y: scroll;
    display: block;
    width: 100%;
    max-height: 340px;
    background: $white;
    box-shadow: 0 12px 15px 0 rgba($black, 0.27);
}
.category-filter__option-button {
    @include substance-filter-option;
}
.category-filter__option-button[disabled] {
    color: $grey-medium;
    background-color: $grey-lighter;
    cursor: not-allowed;
}

// Icon
.category-filter__button[aria-expanded="true"] .category-filter__icon {
    transform: rotate(180deg);
}

.category-filter__option--hide {
    display: none;
}
