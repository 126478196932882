.pagination {
    @include content(large);
    @include row-bottom(medium);
}
.pagination__list {
    @include bare-list;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: max-content;

    @include bp-min($small) {
        gap: 40px;
    }
}

/**
 * Next and Back links
 */
.pagination__back-link {
    margin-right: $gutter;
}
.pagination__next-link {
    margin-left: $gutter;
}
.pagination__back-link,
.pagination__next-link {
    display: block;
    position: relative;
    height: 15px;
    width: 15px;
    z-index: 1;

    @include bp-min($small) {
        height: 20px;
        width: 20px;
    }
}
.pagination__back-link::after,
.pagination__next-link::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 36px;
    height: 36px;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 50%;
    z-index: -1;

    @include bp-min($small) {
        width: 50px;
        height: 50px;
    }
}
.pagination__back-link svg {
    transform: rotate(180deg) translateX(2px);
    width: 100%;
    height: 100%;
}
.pagination__next-link svg {
    display: block;
    transform: translateX(2px);
    width: 100%;
    height: 100%;
}

/**
 * Page numbers
 */
.pagination__current-item-number {
    display: block;
    position: relative;
}
.pagination__current-item-number::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    background-color: currentcolor;
    width: 20px;
    height: 2px;
}
.pagination__item-link {
    position: relative;
    text-decoration: none;

    @include bp-min($small) {
        @include expand-clickable-area-tap-size;
    }
}
.pagination__item-link::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    min-height: 30px;
    min-width: 30px;
    transform: translate(-50%, -50%);
}
