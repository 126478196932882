/*
This CSS resource incorporates links to font software which is the valuable copyrighted
property of Monotype Imaging and/or its suppliers. You may not attempt to copy, install,
redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
Imaging with any questions regarding Web Fonts:  http://www.fonts.com
*/

// Avenir
@font-face {
    font-family: "Avenir LT W01_55 Roman1475520";
    src: url("../../fonts/57bf7902-79ee-4b31-a327-1bbf59a3d155.eot?#iefix");
    src:
        url("../../fonts/57bf7902-79ee-4b31-a327-1bbf59a3d155.eot?#iefix")
            format("eot"),
        url("../../fonts/b290e775-e0f9-4980-914b-a4c32a5e3e36.woff2")
            format("woff2"),
        url("../../fonts/4b978f72-bb48-46c3-909a-2a8cd2f8819c.woff")
            format("woff"),
        url("../../fonts/9bdf0737-f98c-477a-9365-ffc41b9d1285.ttf")
            format("truetype"),
        url("../../fonts/15281d0d-e3c2-46e1-94db-cb681e00bfaa.svg#15281d0d-e3c2-46e1-94db-cb681e00bfaa")
            format("svg");
}

// Filson Pro Regular
@font-face {
    font-family: "Filson W01 Regular";
    src: url("../../fonts/cf824b31-d20c-45c9-b7b9-ed88600652fa.eot?#iefix");
    src:
        url("../../fonts/cf824b31-d20c-45c9-b7b9-ed88600652fa.eot?#iefix")
            format("eot"),
        url("../../fonts/5e31e57d-3e5b-4d48-baec-41c09504ffb7.woff2")
            format("woff2"),
        url("../../fonts/089c193b-25f0-434c-b2fc-0321ca362b82.woff")
            format("woff"),
        url("../../fonts/54d0c291-296d-45be-bd33-d51c4fd3ae0f.ttf")
            format("truetype"),
        url("../../fonts/6517e60e-a00c-482e-a327-c028bd6aae63.svg#6517e60e-a00c-482e-a327-c028bd6aae63")
            format("svg");
    font-weight: normal;
    font-style: normal;
}

// Filson Pro Bold
@font-face {
    font-family: "Filson W01 Bold";
    src: url("../../fonts/1fe52047-8729-4a94-aaf6-34f8f7b2ef9d.eot?#iefix");
    src:
        url("../../fonts/1fe52047-8729-4a94-aaf6-34f8f7b2ef9d.eot?#iefix")
            format("eot"),
        url("../../fonts/11ef1a9f-5bee-4a38-8676-d85842c76a9f.woff2")
            format("woff2"),
        url("../../fonts/0220ca22-b34b-4299-a023-bbc0cf9e8b55.woff")
            format("woff"),
        url("../../fonts/71d44766-c21e-4f31-887a-1a08713b6642.ttf")
            format("truetype"),
        url("../../fonts/d6ee5444-83f9-442d-b296-92966da83e46.svg#d6ee5444-83f9-442d-b296-92966da83e46")
            format("svg");
    font-weight: normal;
    font-style: normal;
}
