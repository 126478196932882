/**
 * Pregnant illustration
 */
.theme-pregnant {
    @include theme-illustration;
}
/**
 *  Hover effects
 */
.theme-card {
    @include hocus {
        @include bp-min($medium-large) {
            .theme-pregnant__blob--front {
                transform: scale(1.05, 1.1) rotate(2deg);
            }
            .theme-pregnant__blob--back {
                transform: scale(1.1) rotate(-2deg);
            }
        }
        .theme-pregnant__mother {
            transform: translateX(-14%);
        }
        .theme-pregnant__mother-skirt {
            transform: rotate(-3deg) translateX(2%);
        }
        .theme-pregnant__mother-arm {
            transform: rotate(-10deg) translate(2%, -3.5%);
        }
        .theme-pregnant__father {
            transform: translateX(14%);
        }
        .theme-pregnant__father-head {
            transform: rotate(6deg) translateY(1%);
        }
        .theme-pregnant__father-left-arm {
            transform: translateX(2.5%) rotate(-6deg);
        }
        .theme-pregnant__father-right-arm {
            transform: rotate(-6deg);
        }
    }
}

/**
 * Parts
 */
.theme-pregnant__blob--front,
.theme-pregnant__blob--back {
    transform-origin: center;
    transition: transform 0.3s;
}
.theme-pregnant__blob--back {
    transform-origin: center;
    transform: scale(0.5);
    transition: transform 0.3s;
}
.theme-pregnant__father,
.theme-pregnant__mother {
    transition: transform 0.3s;
}
.theme-pregnant__father-head {
    transform-origin: bottom center;
    transition: transform 0.2s;
}
.theme-pregnant__father-right-arm,
.theme-pregnant__father-left-arm {
    transform-origin: 50% 50%;
    transition: transform 0.2s;
}
.theme-pregnant__father-right-arm {
    transform-origin: 50% 40%;
}
.theme-pregnant__mother-skirt {
    transform-origin: 50% 50%;
    transition: transform 0.2s;
}
.theme-pregnant__mother-arm {
    transform-origin: bottom right;
    transition: transform 0.5s;
}
