/**
 * The Page Header
 */
.page-header {
    @include site-header-padding;
    overflow: hidden;
    position: relative;
    width: 100%;
}
.page-header__content {
    @include row(medium);
    @include content(small);
    text-align: center;
}

/*
 * Titles
 */
.page-header__subtitle {
    margin: 0;
}
.page-header__intro {
    margin: 0 auto;
    line-height: 1.5;
    @include bp-min($medium) {
        width: 95%;
        font-size: 22px;
    }
}
.page-header__intro p {
    margin: 0;
}

/**
 * Custom Page Header on homepage
 */
.page-header--home::after {
    content: "";
    position: absolute;
    bottom: -1000px;
    right: 50%;
    margin-right: -1300px;
    width: 2290px;
    height: 1150px;
    background: rgba($white, 0.15);
    border-radius: 50%;
    z-index: -1;
    @include bp-min($medium-large) {
        margin-right: -1490px;
    }
}
.page-header--home .page-header__inner {
    @include content(medium);
    @include bp-min($small) {
        @include row-top(small);
        display: flex;
        align-items: center;
    }
    @include bp-min($large) {
        padding-left: 0;
        padding-right: 0;
    }
}
.page-header--home .page-header__title {
    line-height: 1;
    @include bp-min($large) {
        font-size: 50px;
    }
}
.page-header--home .page-header__content {
    width: 100%;
    text-align: left;
    padding: 0;
    @include bp-min($small) {
        float: left;
        width: 50%;
    }
    @include bp-min($large) {
        width: 60%;
    }
}
.page-header--home .page-header__intro {
    width: 100%;
    font-size: map.get($font-sizes-small, body);
    @include bp-min($small) {
        font-size: map.get($font-sizes-medium, body);
    }
    @include bp-min($large) {
        font-size: map.get($font-sizes-large, body);
    }
}
.page-header--home .page-header__illustration {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: $gutter;
    padding: 0;
    @include bp-min($small) {
        width: 50%;
        margin: 0;
        padding-left: $gutter;
    }
    @include bp-min($small) {
        float: right;
    }
    @include bp-min($large) {
        right: -240px;
        width: 40%;
    }
}
.page-header--home .page-header__illustration > svg {
    display: block;
    width: auto;
    height: 100%;
    max-height: 300px;
    margin: 0 auto;
    padding: 0;
    @include bp-min($medium-large) {
        float: right;
    }
    @include bp-min($large) {
        max-height: none;
        width: 570px;
        height: 450px;
    }
}

/**
 * Custom Page Header on newspage
 */
.page-header--news {
    overflow: visible;
}
.page-header--news .page-header__content {
    padding-bottom: 0;
}
.page-header--news .page-header__title {
    margin-bottom: $gutter-xs;
    font-size: 25px;

    @include bp-min($medium) {
        margin-bottom: $gutter-sm;
        font-size: 30px;
    }
    @include bp-min($medium-large) {
        font-size: 48px;
    }
}
.page-header--news .page-header__link {
    @include regular-font-size;
    @include body-font;
    display: inline-block;
    text-decoration: none;
    font-weight: normal;
    margin-bottom: $gutter-sm;
}
.page-header--news .page-header__link:hover {
    text-decoration: underline;
}
.page-header--news .page-header__intro {
    @include regular-font-size;
    max-width: 565px;
}
.page-header--news .page-header__metadata {
    @include heading-font;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 $gutter;
    margin-top: $gutter;
    margin-bottom: 55px;
    font-style: normal;

    @include bp-min($medium) {
        margin-bottom: 80px;
    }
}
.page-header--news .page-header__author {
    font-style: normal;
}
.page-header--news .page-header__figure {
    @include aspect-ratio(4, 2.75);
    margin-bottom: 0;
}
.page-header--news .page-header__image,
.page-header--news .page-header__video {
    margin-bottom: -#{$news-header-media-offset-small};
    position: relative;
    z-index: 1;

    @include bp-min($medium) {
        margin-bottom: -#{$news-header-media-offset-medium};
    }
    @include bp-min($large) {
        margin-bottom: -#{$news-header-media-offset-large};
    }
}
