// stylelint-disable selector-max-universal, no-unknown-animations

.word-definition {
    display: inline-block;
}
.word-definition.is-expanded {
    display: inline;
}
.word-definition__term {
    @include bare-button;
    margin: 0;
    padding: 0;
    position: relative;
    background: none;
    color: $text-color;
    font-size: 0.95em;
    cursor: pointer;
}
.word-definition__term::after {
    content: "";
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 0 -2px;
    background: currentcolor url("../../images/icon-informatie--white.svg")
        no-repeat 6px 3px;
    background-size: 4px auto;
    border-radius: 50%;
}
.word-definition__term > span {
    display: inline-block;
    border-bottom: 1px dashed;
}
.word-definition__term[aria-expanded="true"] > span,
.word-definition__term:hover > span,
.word-definition__term:focus > span {
    border-bottom-style: solid;
}
.word-definition__content {
    display: none;
    padding: $gutter-sm 20px;
    background: $grey-lighter;
    color: $text-color;
    opacity: 0;
}

.no-js .word-definition__content,
.is-expanded > .word-definition__content {
    display: block;
    overflow: auto;
    animation: fade-in 0.35s forwards;
}
.word-definition__content *:last-child {
    margin-bottom: 0;
}

.is-expanded > .word-definition__content,
.is-expanded > .word-definition__content:last-child {
    margin: $gutter-xs (-20px);
}
