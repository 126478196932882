.notice-icon {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    margin: 0;
    background-color: $grey;
    color: $white;
    text-align: center;
    border-radius: 50%;
    @include bp-min($medium) {
        width: 32px;
        height: 32px;
    }
}
.notice-icon > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.notice-icon--no-risk,
.notice-icon--0 {
    background-color: $icon-green;
}
.notice-icon--no-risk > svg,
.notice-icon--0 > svg {
    width: 12px;
    height: 10px;
    @include bp-min($medium) {
        width: 16px;
        height: 13px;
    }
}
.notice-icon--medium-risk,
.notice-icon--1 {
    background-color: $icon-blue;
}
.notice-icon--medium-risk > svg,
.notice-icon--1 > svg {
    width: 3px;
    height: 14px;
    @include bp-min($medium) {
        width: 4px;
        height: 18px;
    }
}
.notice-icon--high-risk,
.notice-icon--2 {
    background-color: $icon-orange;
}
.notice-icon--high-risk > svg,
.notice-icon--2 > svg {
    width: 15px;
    height: 14px;
    transform: translate(-50%, -55%);
    @include bp-min($medium) {
        width: 20px;
        height: 18px;
    }
}
