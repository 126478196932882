// stylelint-disable declaration-no-important

/**
 *  Color blocks in styleguide
 */
.color {
    @include box-shadow;
    position: relative;
    display: inline-block;
    border-radius: 5px;
    overflow: hidden;
    width: calc(50% - #{$gutter} - 4px);
    min-height: 140px;
    margin: $gutter-sm;
    @include bp-min($medium) {
        width: calc(33.3333% - #{$gutter} - 4px);
    }
    @include bp-min($medium-large) {
        width: calc(25% - #{$gutter} - 5px);
    }
}
.color__header {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $white;
    margin: $gutter-sm 0 0;
    padding: $gutter-xs;
    border-top: 1px solid $grey;
    text-align: center;
    font-size: 11px;
    @include bp-min($small) {
        font-size: 13px;
    }
}

// non semantic color classes
@each $theme, $color in $themes-map {
    .color--#{$theme} {
        background: $color !important;
    }
    .theme--#{$theme} {
        background: $color !important;
    }
}
.color--light {
    color: $white;
    a:not(.button) {
        color: $white;
    }
}
.color--dark {
    color: $text-color;
}
