.slider {
    position: relative;
    width: 100%;
    @include bp-min($medium) {
        overflow: visible;
    }
}
.js .slider__inner {
    position: absolute;
    width: 100%;
    height: 100%;
    will-change: transform;
    transition: transform 0.25s ease-in-out;
}
.slider__item {
    width: 100%;
    @include bp-min($large) {
        .js & {
            opacity: 0;
            transition: opacity 0.3s;
        }
    }
}
.slider__item.is-active {
    @include bp-min($medium) {
        opacity: 1;
    }
}
.js .slider__item {
    position: absolute;
    top: 0;
    left: 0;
}
.no-js .slider__next-slide,
.slider__item:last-child .slider__next-slide {
    display: none;
}
.slider__nav-item {
    display: none;
    @include bp-min($medium) {
        display: block;
        position: absolute;
        top: 50%;
        height: 60px;
        width: 50px;
        min-width: 0;
        margin-top: -30px;
        padding: 0;
        background: none;
        box-shadow: none;
        cursor: pointer;
        transition: opacity 0.2s;
    }
}
.slider__nav-item::before {
    content: "";
    z-index: 0;
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    background: rgba($black, 0.045);
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.2s;
    @include bp-min($medium-large) {
        width: 100px;
        height: 100px;
    }
}
.slider__nav-item::after {
    content: none;
}
.no-js .slider__nav-item,
.slider__nav-item.is-hidden {
    display: none;
}
.slider__nav-item > svg {
    display: block;
    width: 15px;
    height: 100%;
    margin: 0 auto;
    stroke: $tertiary-color;
    stroke-width: 2;
    @include bp-min($medium-large) {
        width: 30px;
    }
    .theme--secondary & {
        stroke: $primary-color;
    }
}
.slider__nav-item:hover {
    &::before {
        opacity: 1;
    }
    > svg {
        stroke: $tertiary-color;
    }
}
.slider__nav-item--prev {
    left: $gutter-xs;
    @include bp-min($medium-large) {
        left: $gutter-sm;
    }
}
.slider__nav-item--prev::before {
    left: 0;
    transform: translate(0, -50%);
    @include bp-min($medium-large) {
        transform: translate(-25px, -50%);
    }
}
.slider__nav-item--next {
    right: $gutter-xs;
    @include bp-min($medium-large) {
        right: $gutter-sm;
    }
}
.slider__nav-item--next::before {
    right: 0;
    transform: translate(0, -50%);
    @include bp-min($medium-large) {
        transform: translate(25px, -50%);
    }
}
.slider__indicators {
    @include bare-list;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px;
    text-align: center;
}
.slider__indicator {
    display: inline-block;
    vertical-align: middle;
    width: 11px;
    height: 11px;
    margin: 0 4px;
    background: transparent;
    border: 2px solid $primary-color;
    border-radius: 50%;
    transition: background 0.2s;
}
.slider__indicator::after {
    content: none;
}
.slider__indicator span {
    @include hide-text;
}
.slider__indicators li.is-active {
    background-color: $primary-color;
}
