.search-form {
    @include clearfix;
    position: relative;
    height: $search-button-medium;
    margin: 0 auto;
    @include bp-min($medium) {
        @include content(medium);
    }
}
.search-form--in-header {
    height: $search-button-small;
    width: calc(100% - #{$gutter-sm} - #{$gutter-sm});
    flex: 0 1 calc(100% - #{$gutter-sm} - #{$gutter-sm});
    margin-top: calc(60px + #{$gutter-sm});
    @include bp-min($small) {
        width: calc(100% - #{$gutter} - #{$gutter});
    }
    @include bp-min($medium) {
        width: 320px;
        flex-basis: 320px;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 11px;
        padding: 0;
    }
    @include bp-min($nav-switch) {
        width: 370px;
        flex-basis: 370px;
    }
    @include bp-min($large) {
        margin-bottom: 14px;
    }
}
// Box Shadow
.search-form::before {
    @include box-shadow;
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    @include bp-min($medium) {
        width: calc(100% - #{$gutter} - #{$gutter});
    }
}
.search-form--in-header::before {
    width: 100%;
}
.search-form__label {
    @include hide-text;
    display: block;
    margin: 0;
}
.search-form__input {
    position: relative;
    float: left;
    width: calc(100% - 60px);
    height: 100%;
    padding: 0 $gutter-sm;
    margin-bottom: 0;
    appearance: none;
    background: $white;
    color: $black;
    border: 0;
    border-radius: 0;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include bp-min($medium) {
        padding: 0 $gutter;
    }
    @include bp-min(1060) {
        font-size: 16px;
    }
    @include bp-min($large) {
        font-size: 19px;
    }
}
.search-form--in-header .search-form__input {
    font-size: 14px;
    color: $grey-dark;
    width: calc(100% - 48px);
    @include bp-min($medium) {
        padding: 0 $gutter-sm;
    }
}

/*
 * Search form on substance index
 */
.js .search-form--index .search-form__input {
    width: 100%;
}
.js .search-form--index .search-button {
    display: none;
}
