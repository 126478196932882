.social-list {
    @include bare-list;
    margin-bottom: $gutter-sm;
    color: inherit;
}

.social-list li {
    display: inline-block;
    vertical-align: top;
}
.social-list a {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    color: inherit;
    width: 46px;
    height: 46px;
    border-radius: 50%;
}

.social-list svg {
    width: 1.5em;
    height: 1.5em;
    fill: $tertiary-color;
}
