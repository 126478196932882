// stylelint-disable no-unknown-animations

.theme-baby-extended {
    @include theme-illustration;
}
.theme-baby-extended__blob {
    animation: float 14s alternate infinite ease-in-out;
}

/**
 * Animations: one time
 */
@keyframes father-in {
    0% {
        transform: translateX(-10%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes train-in {
    0% {
        transform: translateX(100%) rotate(5deg);
    }
    100% {
        transform: translateX(0) rotate(0);
    }
}

/**
 * Animations: constantly running
 */
@keyframes day {
    0%,
    20%,
    80%,
    100% {
        opacity: 1;
        transform: translateY(0);
    }
    30%,
    70% {
        opacity: 0;
        transform: translateY(350%);
    }
}
@keyframes night {
    0%,
    20%,
    80%,
    100% {
        opacity: 0;
        transform: translateY(350%);
    }
    30%,
    70% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes turn-switch {
    0%,
    2%,
    100% {
        transform: translateY(-30%);
    }
    1% {
        transform: translateY(0%);
    }
}
@keyframes lights {
    0% {
        opacity: 0;
    }
    3%,
    100% {
        opacity: 0.2;
    }
}
@keyframes baby-arm-right {
    0% {
        transform: rotate(0) translate(4%, 0);
    }
    20% {
        transform: rotate(-15deg) translate(4%, 0);
    }
    40%,
    100% {
        transform: rotate(0) translate(0);
    }
}
@keyframes baby-arm-left {
    0% {
        transform: rotate(0) translate(-4%, 0);
    }
    20% {
        transform: rotate(15deg) translate(-4%, 0);
    }
    40%,
    100% {
        transform: rotate(0) translate(0);
    }
}
@keyframes baby-leg-right {
    0% {
        transform: rotate(0) translate(-4%, 0);
    }
    20% {
        transform: rotate(-15deg) translate(-4%, 0);
    }
    40%,
    100% {
        transform: rotate(0) translate(0);
    }
}
@keyframes baby-leg-left {
    0% {
        transform: rotate(0) translate(-4%, 0);
    }
    20% {
        transform: rotate(15deg) translate(-4%, 0);
    }
    40%,
    100% {
        transform: rotate(0) translate(0);
    }
}
@keyframes father-head {
    0%,
    40%,
    100% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(3deg) translate(-3%, 0);
    }
}

/**
 * Elements
 */

.theme-baby-extended__baby-arm-left {
    transform-origin: bottom right;
    animation: baby-arm-left 4s infinite;
}
.theme-baby-extended__baby-arm-right {
    transform-origin: bottom left;
    animation: baby-arm-right 4s infinite;
}
.theme-baby-extended__baby-leg-left {
    transform-origin: bottom right;
    animation: baby-leg-left 4s infinite;
}
.theme-baby-extended__baby-leg-right {
    transform-origin: top left;
    animation: baby-leg-right 4s infinite;
}
.theme-baby-extended__father {
    animation: father-in 0.8s;
}
.theme-baby-extended__father-head {
    transform-origin: bottom left;
    animation: father-head 5s infinite;
}
.theme-baby-extended__lamp-light {
    opacity: 0.2;
    animation: lights 30s infinite;
}
.theme-baby-extended__lamp-switch {
    animation: turn-switch 20s infinite;
}
.theme-baby-extended__moon {
    animation: night 6s infinite alternate linear;
}
.theme-baby-extended__sun {
    animation: day 6s infinite alternate linear;
}
.theme-baby-extended__train {
    animation: train-in 0.8s;
}
.theme-baby-extended__baby-head {
    transform-origin: 21% center; // Correct markup in SVG
}
