/**
 * Progress indicator with <progress> element
 */
.read-indicator {
    width: 100%;
    height: 6px;
    background: transparent;
    color: $tertiary-color-dark;
    border: 0;
    appearance: none;
}

// Determine position depending on parent
.toc-menu__read-indicator {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    .js-is-sticky &,
    .js-is-bottom-aligned & {
        display: block;
    }
    .toc-menu--has-title & {
        @include bp-min($toc-menu-switch) {
            top: -1px;
        }
    }
}

// Generic styles
.read-indicator::-webkit-progress-bar {
    background-color: transparent;
}
.read-indicator::-webkit-progress-value {
    background-color: $tertiary-color-dark;
}
.read-indicator::-moz-progress-bar {
    background-color: $tertiary-color-dark;
}
