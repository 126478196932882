/*
 * Generic animations
 */
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes pop-in {
    0% {
        transform: translateY(4em);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes pop-out {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(4em);
    }
}

@keyframes minimize {
    0% {
        transform: scaleY(1);
        transform-origin: left top;
    }
    100% {
        transform: scaleY(0);
        transform-origin: left top;
    }
}

@keyframes scale-in {
    0% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes scale-out {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.9);
    }
}

@keyframes float {
    0% {
        transform: translateY(10px) rotate3d(0, 0, 1, 3deg);
    }
    100% {
        transform: translateY(-10px) rotate3d(0, 0, 1, 0);
    }
}

@keyframes fly-in {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100vw, 0);
    }
}

@keyframes fly-out {
    0% {
        transform: translate(-100vw, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes slide-in-from-top {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}
