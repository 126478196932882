/** 
 * Related items 
 * 
 * As seen on category single and theme single pages
 */
.related-entities {
    display: flex;
    flex-wrap: wrap;
}
.related-entities__content {
    @include bp-min($small) {
        width: 80%;
        padding-right: 1em;
    }
    @include bp-min($extra-large) {
        width: 95%;
    }
}

// As seen on category single pages
.related-entities__illustration {
    display: none;
    @include bp-min($small) {
        display: block;
        width: 20%;
    }
    @include bp-min($extra-large) {
        width: 30%;
        margin-right: -25%;
    }
}
.related-entities__illustration > svg {
    width: 100%;
    height: auto;
}
.related-entities__list {
    @include bare-list;
}
.related-entities__item {
    display: inline-block;
    margin: 0;
}
.related-entities:not(:last-child) {
    margin-bottom: $single-line;
    @include bp-min($medium-large) {
        margin-bottom: $double-line;
    }
}
.related-entities__button {
    margin: $single-line 0 0;
    word-break: break-word;
    @include bp-min($small) {
        margin-right: 10px;
    }
}
.related-entities__intro {
    margin-bottom: $single-line;
}
