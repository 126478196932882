// stylelint-disable no-unknown-animations, no-descending-specificity

/**
 * Table Of Contents menu, sticky on scroll
 */

$TOC_MENU_TOP: 67px;

:root {
    #{--toc-menu-min-height}: 0; // Interpolate to suppress sass lint fatal error
}

@include bp-max($toc-menu-switch) {
    .toc-wrapper {
        position: relative;
        background-color: $white;
    }
    // Placeholder for shifting content
    .toc-wrapper::before {
        content: "";
        display: block;
        min-height: var(--toc-menu-min-height);
    }
    // Faux bg color to make it look like TOC menu is pulled over main content
    .toc-wrapper::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background: $bg-color;
    }
}
.toc-menu {
    @include box-shadow;
    position: relative;
    width: calc(100% - #{$gutter});
    min-height: 69px;
    margin-left: $gutter-sm;
    margin-right: $gutter-sm;
    background-color: $white;
    border-radius: 3rem;
    transform: translate3d(0, 0, 0);
    will-change: transform, scroll-position;
    @include bp-min($small) {
        margin: 0 $gutter;
    }
    @include bp-min($medium) {
        margin: 0 $gutter-lg;
    }
    @include bp-min($toc-menu-switch) {
        width: $toc-menu-width;
        margin: 0;
        margin-top: calc(-#{$toc-top-spacer} - #{$single-line});
        padding-top: $toc-top-spacer;
        padding-bottom: 49px;
        background: none;
        border-radius: 0 0 3rem;
        box-shadow: none;
    }
}
.toc-menu:not([data-enhancer="tocMenu"]) {
    position: relative;
}
.toc-menu__inner,
.toc-menu__list-container {
    @include bp-min($toc-menu-switch) {
        position: relative;
    }
}
.toc-menu__title {
    display: none;
    @include bp-min($toc-menu-switch) {
        display: block;
        margin: 0;
        padding: $gutter-sm $gutter;
        background: $grey-lighter;
        font-family: $custom-font-bold;
        font-size: 13px;
        line-height: 1.35;
        text-align: center;
        text-decoration: none;
        word-break: break-word;
        border-bottom: 1px solid $grey;
        border-radius: 3rem 0 0;
    }
}

// TOC menu in TOC wrapper
.toc-wrapper__toc-menu {
    z-index: 777;
    @include bp-min($small) {
        width: calc(100% - (#{$gutter} * 2));
    }
    @include bp-min($medium) {
        width: calc(100% - (#{$gutter-lg} * 2));
    }
    @include bp-min($toc-menu-switch) {
        position: absolute;
        top: $gutter-xl;
        left: calc(50% - (100% / 2)); // relative to container
        width: $toc-menu-width;
    }
    @include bp-min($large) {
        left: calc(
            50% - (#{$site-max-width} / 2)
        ); // position relative to container
    }
}

/**
 * When sticky
 */
.toc-menu.js-is-sticky,
.toc-menu.js-is-bottom-aligned {
    position: fixed;
    top: 0;
    left: 0;
    margin-left: 0;
    margin-right: 0;
    background: $white;
    backface-visibility: hidden;
    @include bp-max($toc-menu-switch) {
        width: 100%;
        border-radius: 0;
        transform: translateY(-100%);
        animation: slide-in-from-top 0.2s forwards;
    }
    @include bp-min($toc-menu-switch) {
        top: calc(#{$toc-top-spacer} + #{$single-line});
        left: calc(50% - (100% / 2));
        background: transparent;
    }
    @include bp-min($large) {
        left: calc(50% - (#{$site-max-width} / 2));
    }
}
.toc-menu.js-is-sticky ol,
.toc-menu.js-is-bottom-aligned ol {
    @include bp-max($toc-menu-switch) {
        border-radius: 0;
    }
}

/**
 * When it hits bottom
 */
.toc-menu.js-is-bottom-aligned {
    @include bp-max($toc-menu-switch) {
        display: none;
    }
    @include bp-min($toc-menu-switch) {
        position: absolute;
        top: auto;
        bottom: 0;
    }
}
.toc-menu__list {
    @include clearfix;
    margin: 0;
    padding: 0;
    border-radius: 3rem;
    will-change: border-radius;
    transition: border-radius 0.28s 0.14s;
    @include bp-min($toc-menu-switch) {
        padding-top: $gutter-sm;
        padding-bottom: $gutter-sm;
        background: $grey-lighter;
        border-radius: 3rem 0;
        .js-is-sticky &,
        .js-is-bottom-aligned & {
            border-radius: 0;
            transition: border-radius 0.28s 0.14s;
        }
    }
    .is-expanded.js-is-sticky & {
        margin-top: $gutter-sm;
        margin-bottom: $gutter-sm;
    }
}

.toc-menu--has-title .toc-menu__list {
    @include bp-min($toc-menu-switch) {
        border-radius: 0 0 3rem;
    }
}
.toc-menu--has-title.js-is-sticky .toc-menu__list,
.toc-menu--has-title.js-is-bottom-aligned .toc-menu__list {
    @include bp-min($toc-menu-switch) {
        border-radius: 0;
        transition: border-radius 0.28s 0.14s;
    }
}

.toc-menu__item {
    display: block;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid $grey;
    font-size: 15px;
    @include bp-max($toc-menu-switch) {
        .js-is-sticky &,
        .js-is-bottom-aligned & {
            position: absolute;
            width: 100%;
            border: 0;
        }
        .is-expanded.js-is-sticky & {
            position: relative;
            &:first-child a {
                border-top: 1px solid currentcolor;
            }
            &:last-child a {
                border-bottom: 1px solid $text-color;
            }
        }
    }
    @include bp-min($medium) {
        float: left;
        width: 50%;
        &:nth-child(odd) {
            border-right: 1px solid $grey;
        }
        .js-is-sticky & {
            width: 100%;
            &:nth-child(odd) {
                border-right: 0;
            }
        }
    }
    @include bp-min($toc-menu-switch) {
        width: 100%;
        border: 0;
        &:nth-child(odd) {
            border: 0;
        }
    }
}
.toc-menu__item:last-of-type {
    border-bottom: 0;
}
.toc-menu__item a {
    display: flex;
    align-items: center;
    width: 100%;
    padding: $gutter-sm 20px;
    color: $text-color;
    font-family: $custom-font-regular;
    font-size: 14px;
    line-height: 1.1;
    text-decoration: none;
    will-change: color;
    transition: color 0.15s;

    @include bp-max($toc-menu-switch) {
        height: 50px;
        .js-is-sticky & {
            opacity: 0;
            width: calc(100% - #{$gutter});
            height: 44px;
            margin: $gutter-sm;
            padding: 0 50px 0 $gutter-sm;
            background: $white;
            background-image: none;
            line-height: 1;
            border: 1px solid $text-color;
        }
        .is-expanded & {
            opacity: 1;
            margin-top: 0;
            margin-bottom: 0;
            border-top: 0;
            border-bottom: 0;
        }
    }
    @include bp-min($small) {
        font-size: 15px;
    }
    @include bp-min($medium) {
        position: relative;
        background-image: none; // no arrow
        .js-is-sticky & {
            justify-content: center;
        }
        @include hocus {
            color: $text-color;
        }
    }
    @include bp-min($toc-menu-switch) {
        padding: $gutter-xs 25px;
        margin: 5px 0;
        background: transparent;
        .js-is-sticky & {
            justify-content: flex-start;
        }
    }
    @include hocus {
        font-family: $custom-font-bold;
        text-decoration: underline;
        color: $text-color;
    }
}
@include bp-min($toc-menu-switch) {
    .toc-menu__item--arrow a {
        align-items: stretch;
    }
    .toc-menu__item--icon a {
        align-items: center;
    }
}
.toc-menu__item--active a {
    font-family: $custom-font-bold;
    color: $text-color;
    .js-is-sticky & {
        opacity: 1;
        @include bp-max($toc-menu-switch) {
            color: $text-color;
        }
    }
    .is-expanded & {
        text-decoration: underline;
    }
    @include bp-min($toc-menu-switch) {
        position: relative;
    }
}

/**
 * Icons in TOC menu
 */
.toc-menu__item span {
    float: left;
}
.toc-menu__item svg {
    fill: currentcolor;
}
.toc-menu__icon {
    display: flex;
    align-items: center;
}
.toc-menu__icon svg {
    width: 26px;
    min-width: 26px;
    height: 22px;
    margin-right: 10px;
    padding: 0;
    @include bp-min($toc-menu-switch) {
        margin-right: 12px;
    }
}
.toc-menu__arrow {
    display: none;
    @include bp-min($toc-menu-switch) {
        display: flex;
        align-self: flex-start;
    }
}
.toc-menu__arrow svg {
    width: 11px;
    min-width: 11px;
    height: 14px;
    margin-right: 4px;
}

/**
 * Toggle dropdown button
 */
.toc-menu__toggle-button {
    display: none;
    position: absolute;
    top: calc(#{$gutter-sm} + 1px);
    right: calc(#{$gutter-sm} + 1px);
    height: 42px;
    width: calc(100% - #{$gutter});
    min-width: 0;
    margin: 0;
    padding: 0;
    background: url("../../images/chevron--down-blue.svg") no-repeat bottom
        right / 2em auto;
    background-position: right $gutter-sm bottom 50%;
    box-shadow: none;
    will-change: transform;
    transition: transform 0.15s;
    .is-expanded & {
        width: 52px;
        background-position: 50% 50%;
    }
    @include bp-max($toc-menu-switch) {
        .js-is-sticky & {
            display: block;
        }
        .is-expanded & {
            transform: scaleY(-1);
        }
    }
}
.toc-menu__toggle-button::after {
    content: none;
}

/**
 * Back to top link
 */
.toc-menu__back-to-top {
    display: none;
    @include bp-min($toc-menu-switch) {
        opacity: 0;
        position: absolute;
        display: block;
        width: 100%;
        max-height: 0;
        overflow: hidden;
        background: $grey-lighter;
        font-family: $custom-font-bold;
        font-size: 13px;
        color: $text-color;
        text-align: center;
        text-decoration: none;
        border-top: 1px solid transparent;
        border-radius: 0 0 3rem;
        transition:
            color 0.15s,
            opacity 0.3s 0.2s,
            padding 0.3s,
            max-height 0.3s 0.2s;
        .js-is-sticky &,
        .js-is-bottom-aligned & {
            opacity: 1;
            max-height: none;
            padding: $gutter-sm;
            &::before {
                transform: scaleX(1);
            }
        }
        @include hocus {
            text-decoration: underline;
        }
    }
}
// Line
.toc-menu__back-to-top::before {
    @include bp-min($toc-menu-switch) {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: $grey;
        transform: scaleX(0);
        transform-origin: top left;
        will-change: transform;
        transition: transform 0.3s 0.2s;
    }
}
.toc-menu__back-to-top > svg {
    opacity: 0;
    width: 13px;
    height: 7px;
    vertical-align: middle;
    margin-left: 5px;
    fill: currentcolor;
    will-change: opacity;
    transform: translate3d(0, 0, 0) rotate(0.5turn);
    transition: opacity 0.3s 0.2s;
    .js-is-sticky &,
    .js-is-bottom-aligned & {
        opacity: 1;
    }
}
