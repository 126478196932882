/**
 * Flash message
 */
.flash-message {
    @include box-shadow;
    position: fixed;
    top: 20%;
    left: 50%;
    z-index: 1000;
    width: 600px;
    margin: 0 0 0 -300px;
    padding: 60px;
    background: $white;
    color: $text-color;
    font-size: 18px;
    line-height: 1.5;
    opacity: 0;
    transform: translateY(-50px);
    transition:
        opacity 0.25s ease-out,
        transform 0.25s ease-out;
    will-change: transform;
}
.flash-message p {
    margin-bottom: 0;
}
.flash-message-active .flash-message {
    opacity: 1;
    transform: translateY(0);
}
.flash-message-inactive .flash-message {
    opacity: 0;
    transform: translateY(-50px);
}
