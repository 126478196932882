.news-page__content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: $news-header-media-offset-small;

    @include bp-min($medium) {
        padding-top: $news-header-media-offset-medium;
        gap: $gutter-lg;
    }
    @include bp-min($large) {
        padding-top: $news-header-media-offset-large;
    }
}
.news-page__content[data-header-has-media="false"] {
    padding-top: 0;
}
.news-page__part :last-child {
    margin-bottom: 0;
}
.news-page__image-caption {
    font-size: 14px;

    @include bp-min($medium) {
        padding: $gutter-xs 0 0;
        font-size: 16px;
        text-align: left;
    }
}
