/*
 * Error 404 page styling
 */
.error__inner {
    @include content(small);
    @include row(medium);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.error :last-child {
    @include bp-min($medium) {
        margin-bottom: 0;
    }
}
.error__article,
.error__image {
    width: 100%;
    text-align: center;
    @include bp-min($medium) {
        width: 50%;
        float: left;
        text-align: left;
    }
}
.error__image svg {
    margin: 0 auto;
    @include bp-min($medium) {
        float: right;
        width: 300px;
        height: 300px;
    }
}
