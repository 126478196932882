@use "sass:map";

.note {
    padding: $gutter-sm;
    border-left: 4px solid;
    background: $grey-lighter;
    font-size: map.get($font-sizes-small, body);
    @include bp-min($medium) {
        border-width: 5px;
    }
}
.note :last-child {
    margin-bottom: 0;
}
