/*
 * Substance card in index overview
 */
.index-card {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 4px;
    padding: $gutter-sm;
    background: $white;
    color: $text-color;

    @include bp-min($small) {
        width: calc(50% - 4px);
        margin-right: 2px;
        margin-left: 2px;
    }
    @include bp-min($medium) {
        padding: ($gutter-sm * 1.5) $gutter;
    }
}

// Apply box shadow without overlapping other index card
.index-card::before {
    @include box-shadow;
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.6;
    transition: opacity 0.2s;
}
.index-card--is-clickable:hover {
    background: $grey-lighter;
}
.index-card--is-clickable:hover::before {
    opacity: 1;
}
.index-card__link {
    @include expand-clickable-area;
    text-decoration: none;
    @include bp-min($medium-large) {
        display: inline-block;
        padding-right: $gutter-sm;
    }
}
.index-card--has-footer {
    flex-direction: row;
}
.index-card__title {
    width: 100%;
}
.index-card--has-footer .index-card__title {
    @include bp-min($medium-large) {
        width: 60%;
        float: left;
    }
}
.index-card__title > h3 {
    margin: 0 0 $quarter-line;
    font-family: $custom-font-bold;
    font-size: 17px;
    word-break: break-word;
}
.index-card p {
    width: 100%;
    margin: 0 0 $single-margin;
    font-size: 18px;
}
.index-card p:last-child {
    margin: 0;
}
.index-card__footer {
    width: 100%;
    font-size: 15px;
    @include bp-min($medium-large) {
        width: 40%;
        float: left;
    }
}
