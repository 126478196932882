/**
 * House Jobs illustration
 */
.theme-house-jobs {
    @include theme-illustration;
}
.theme-card {
    @include hocus {
        @include bp-min($medium) {
            .theme-house-jobs__blob--front {
                transform: scale(1.1, 1.1);
            }
            .theme-house-jobs__blob--back {
                transform: scale(1.05, 1);
            }
            .theme-house-jobs__paint-streak {
                transform: scaleY(2);
            }
            .theme-house-jobs__paint-roller {
                transform: translateY(15%) rotate(1deg);
            }
            .theme-house-jobs__arm-left {
                transform: translateY(10%) translateX(10%) scaleX(0.9)
                    rotate(-18deg);
            }
            .theme-house-jobs__arm-left-under {
                transform: translateY(-15%) scaleX(0.8) rotate(-13deg);
            }
            .theme-house-jobs__arm-right {
                transform: translateY(50%) translateX(0%) rotate(-3deg);
            }
            .theme-house-jobs__fingers {
                transform: translateY(150%) translateX(-65%);
            }
        }
    }
}

/**
 * Parts
 */
.theme-house-jobs__blob--back {
    transform: scale(0.4);
    transform-origin: center;
    transition: transform 0.2s;
}
.theme-house-jobs__blob--front {
    transform-origin: 50% 50%;
    transition: transform 0.2s;
}
.theme-house-jobs__paint-streak {
    transform-origin: center top;
    transition: transform 0.2s;
}
.theme-house-jobs__paint-roller {
    transform-origin: top center;
    transition: transform 0.2s;
}
.theme-house-jobs__arm-left {
    transform-origin: bottom right;
    transition: transform 0.2s;
}
.theme-house-jobs__arm-left-under {
    transform-origin: bottom right;
    transition: transform 0.2s;
}
.theme-house-jobs__arm-right {
    transform-origin: bottom left;
    transition: transform 0.2s;
}
.theme-house-jobs__fingers {
    transform-origin: center right;
    transition: transform 0.2s;
}
