.page-preview {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    background: $white;
    box-shadow: $card-box-shadow;
    border-radius: $card-border-radius;
    width: 100%;
}

// Extra box-shadow on hover
.page-preview::before {
    content: "";
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: $card-box-shadow;
    border-radius: inherit;
    transition: opacity 0.2s;
}
.page-preview:hover::before {
    opacity: 1;
}

.styleguide .page-preview {
    width: 300px;
}

/**
 * Page Preview header
 */
.page-preview__header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 20px 20px 0;
    gap: $gutter;
    margin-bottom: 0.5em;

    @include bp-min($small) {
        flex-direction: column;
        padding: 0;
        gap: 0;
    }
}
[data-is-highlight="true"] .page-preview__header {
    flex-direction: column;
    gap: 0;
    padding: 0;
}
.page-preview__header-text {
    display: flex;
    flex-wrap: wrap;

    @include bp-min($small) {
        padding: 24px 24px 0; // Exception in design
    }
}
[data-is-highlight="true"] .page-preview__header-text {
    padding: 20px 20px 0;

    @include bp-min($small) {
        padding: 24px 24px 0; // Exception in design
    }
}
.page-preview__title {
    @include h4;
    order: 2;
    width: 100%;
    line-height: 1.2;
    margin: 0;
    text-wrap: balance;
}

.page-preview__supertitle-container {
    @include small-font-size;
    @include body-font;
    display: flex;
    margin-bottom: 0.8em;
    order: 1;

    @include bp-min($small) {
        font-size: 16px;
    }
}
.page-preview__supertitle {
    @include small-font-size;
    @include body-font;
    margin: 0;
    line-height: 1.3;

    @include bp-min($small) {
        font-size: 16px;
    }
}
.page-preview__publish-date-prefix {
    font-size: 9px;
    line-height: 2;
    margin: 0 0.9em;

    @include bp-min($small) {
        margin: 0 0.6em;
        font-size: 10px;
        line-height: 2.2;
    }
}

/**
 * Page Preview header
 */
.page-preview__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 20px 20px;

    @include bp-min($small) {
        padding: 0 24px 24px; // Exception in design
    }
}
.page-preview__description {
    margin-bottom: $single-line;

    @include bp-min($small) {
        font-size: 16px;
    }
}
.page-preview__link {
    @include expand-clickable-area;
    @include small-font-size;
    margin-top: auto;

    @include bp-min($small) {
        font-size: 16px;
    }
}

/**
 * Image or illustration
 */
.page-preview__image-container {
    width: 64px;
    flex-shrink: 0;

    @include bp-min($small) {
        width: 100%;
    }
}
[data-is-highlight="true"] .page-preview__image-container {
    width: 100%;
}
.page-preview__image {
    @include aspect-ratio(4, 2.75);
    margin: 0;
    padding: 0;
    background: $secondary-color;
    border-top-left-radius: inherit;
}

[data-theme="zwanger"] .page-preview__image,
[data-theme="veelgebruikers"] .page-preview__image {
    background: $blue-medium;
}
[data-theme="baby"] .page-preview__image {
    background: $blue-muted-light;
}
[data-theme="allergieen"] .page-preview__image {
    background: $salmon;
}
[data-theme="kinderen"] .page-preview__image {
    background: $yellow;
}

.page-preview__illustration-container > svg,
.page-preview__image > img {
    object-fit: cover;
    display: block;
    max-width: 100%;
    border-radius: inherit;
}
.page-preview__illustration-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-preview__illustration-container > svg {
    width: 70%;
    height: 70%;
}
[data-theme]:not([data-theme=""]) .page-preview__illustration-container > svg {
    width: 100%;
    height: 100%;
}
