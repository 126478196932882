.theme-cards__inner {
    @include content(large);
    @include row-bottom(medium);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.theme-cards__item {
    width: 100%;
    padding: $card-margin-large 0;
    @include bp-min($switch-to-2-columns) {
        width: $width-2-columns;
        padding: $card-margin-medium;
    }
    @include bp-min($switch-to-3-columns) {
        width: $width-3-columns;
        padding: $card-margin-large;
    }
}
