.related-products {
    position: relative;
    background-color: $secondary-color;
}
.related-products__inner {
    @include content(large);
}
.related-products__content {
    @include row(medium);
    display: flex;
    flex-wrap: wrap;
    border-radius: 1.5em;
    @include bp-min($medium) {
        text-align: left;
    }
}
.related-products::before {
    @include bp-min($medium) {
        content: "";
        position: absolute;
        top: -150px;
        width: 100%;
        height: 150px;
        z-index: -1;
        background-color: inherit;
    }
    @include bp-min($large) {
        top: -350px;
        height: 350px;
    }
}

.related-products__image,
.related-products__header,
.related-products__items {
    position: relative;
    z-index: 666;
    width: 100%;
}
.related-products__header,
.related-products__items {
    padding-left: $gutter-sm;
    padding-right: $gutter-sm;
    @include bp-min($medium) {
        padding-left: $gutter;
        padding-right: $gutter;
    }
    @include bp-min($medium-large) {
        margin-left: auto;
        width: calc(100% - #{$toc-menu-width});
    }
    @include bp-min($large) {
        padding-left: $gutter-xl;
        padding-right: $gutter-xl;
    }
}
/**
 * Illustration
 */
.related-products__image {
    display: flex;
    align-items: flex-start;
    margin: 0 0 $typography-spacer;
    @include bp-min($medium-large) {
        width: $toc-menu-width;
        margin: 0;
    }
}
.related-products__image > svg {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding-left: 3em;
}
.related-products__title {
    margin: 0 0 $single-line;
    line-height: 1.15;
    word-break: break-word;
}
.related-products__subtitle {
    margin-bottom: $double-line;
}

/**
 * List with buttons
 */
.related-products__list {
    @include bare-list;
}
.related-products__list-item {
    display: inline-block;
    vertical-align: top;
    margin: $half-line 4px 0 0;
}
.related-products__product-button {
    @include bp-max($large) {
        min-width: 0;
    }
}
.related-products__category-button {
    margin-bottom: $double-line;
}
