.styleguide {
    @include content(large);
    @include row-bottom(medium);
    position: relative;
}
.styleguide__inner {
    @include bp-min($large) {
        padding-left: $toc-menu-width + 80px;
    }
}
.styleguide__index {
    display: none;
    @include bp-min($large) {
        z-index: 777;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: $toc-menu-width + 40px;
    }
    @include bp-min($large) {
        left: calc(
            50% - (#{$site-max-width} / 2)
        ); // position relative to container
    }
}
.styleguide__index nav {
    padding: 22px;
    background: $primary-color;
    color: $white;
    font-family: $custom-font-regular;
    border-radius: 1em 0;
}
.styleguide__index.js-is-sticky {
    position: fixed;
    top: 0;
}
.styleguide__index.js-is-bottom-aligned {
    position: absolute;
    top: auto;
    bottom: 0;
}
.styleguide__index h2 {
    font-size: 1em;
    color: $white;
    margin: 0 0 0.5em 0.15em;
}
.styleguide__index ul {
    @include bare-list;
    display: flex;
    flex-wrap: wrap;
}
.styleguide__index li {
    margin: 0;
    color: $text-color;
}
.styleguide__index a {
    display: block;
    margin: 0 2px 5px;
    padding: 4px 8px;
    background: rgba($white, 0.13);
    color: $white;
    font-size: 12px;
    text-decoration: none;
    border-radius: 5px;
    transition:
        background 0.2s,
        color 0.2s;
}
.styleguide__index a:hover {
    background: $white;
    color: $primary-color;
}
.styleguide__item {
    scroll-margin-top: 1.5em;
    position: relative;
    margin: 0 0 $gutter-sm;
    padding: $gutter-sm;
    background: $white;
    box-shadow: 0 0 50px rgba($black, 0.2);
    @include bp-min($medium) {
        margin: 0 0 $gutter;
        padding: $gutter;
    }
}
.styleguide__header {
    margin: (-$gutter-sm) (-$gutter-sm) $single-line;
    padding: $gutter-xs $gutter;
    background: $grey-lighter;
    @include bp-min($medium) {
        margin: (-$gutter) (-$gutter) $single-line;
        padding: $gutter-sm $gutter;
    }
}
.styleguide__header h2 {
    margin: 0;
    font-size: 22px;
}
.styleguide__example {
    @include clearfix;
    position: relative;
    overflow: hidden;
}
.styleguide__params {
    width: 100%;
    margin: $gutter-sm 0;
    table-layout: fixed;
    border-collapse: collapse;
    font-size: 15px;
    border: 1px solid $grey;
}
.styleguide__params td {
    padding: ($gutter-xs / 2) $gutter-sm;
}
.styleguide__params td:nth-child(3) {
    width: 60%;
}
.styleguide__source {
    padding: 1em;
    background: none;
    color: $text-color;
    font-size: 15px;
    border: 1px solid $grey;
}
.styleguide__details-for-nerds {
    display: none;
    padding-top: $gutter-sm;
    @include bp-min($medium) {
        padding-top: $gutter;
    }
}
.styleguide__nerds-toggle-label {
    display: block;
    margin: $gutter 0 0;
    padding: $gutter-xs $gutter-sm;
    background: $primary-color url("../../images/chevron--down.svg") no-repeat;
    background-position: right $gutter-sm top 52%;
    background-size: 1.5em auto;
    color: $white;
    font-family: $custom-font-regular;
    font-size: 15px;
    cursor: pointer;
}
.styleguide__nerds-toggle {
    position: absolute;
    left: -999em;
}
.styleguide__nerds-toggle:checked ~ .styleguide__details-for-nerds {
    display: block;
}
