/**
 * Gardening illustration
 */
.theme-gardening {
    @include theme-illustration;
}
/**
 * Hover - focus effects
 */
.theme-card {
    @include hocus {
        @include bp-min($medium) {
            .theme-gardening__blob--front {
                transform: scale(1.1, 1.1);
            }
            .theme-gardening__blob--back {
                transform: scale(1.1, 1);
            }
            .theme-gardening__watering-can {
                transform: rotate(3deg);
            }
        }
    }
}

/**
 * Parts
 */
.theme-gardening__blob--front {
    transform-origin: 50% 50%;
    transition: transform 0.2s;
}
.theme-gardening__blob--back {
    transform: scale(0.4);
    transform-origin: center;
    transition: transform 0.2s;
}
.theme-gardening__watering-can {
    transform-origin: left top;
    transition: transform 0.2s;
}
