@use "sass:color";
// stylelint-disable value-keyword-case

/* ==========================================================================
   Variables
   ========================================================================== */

// Colors
$black: #222222;
$white: #ffffff;
$whitish: #fafafa;
$grey-lighter: #f6f6f6;
$grey-light: color.adjust(#f6f6f6, $lightness: -5%);
$grey: #dfdfdf;
$grey-medium: #bcbcbc;
$grey-dark: #757575;
$grey-darker: #222222;

$red: #ff2400;
$pink: #ec4665;
$coral: #ff735c;
$coral-dark: #f7624b;
$salmon: #ffbcb2;
$yellow: #ffe7c7;
$orange: #ffb247;
$green: #68b216;

$blue-lighter: #f2fbff;
$blue-light: #dff2f9;
$blue-medium: #b2e6fa;
$blue: #5fcdf5;
$blue-dark: #061a42;
$blue-muted-light: #81b5c6;
$blue-muted: #586887;

$icon-blue: #367bae;
$icon-green: #15ac34;
$icon-orange: #cf823a;

// Color usage
$primary-color: $blue-dark;
$secondary-color: $blue;
$tertiary-color: $coral;
$quaternary-color: $blue-light;

$primary-color-light: color.adjust(
    color.adjust($primary-color, $lightness: 10%),
    $saturation: -20%
);
$secondary-color-light: #70c4e4;
$tertiary-color-light: color.adjust(
    color.adjust($tertiary-color, $lightness: 4%),
    $saturation: -20%
);
$tertiary-color-dark: $coral-dark;
$quaternary-color-light: color.adjust(
    color.adjust($quaternary-color, $lightness: 5%),
    $saturation: -5%
);

$bg-color: $secondary-color;
$text-color: $blue-dark;

$themes: ($primary-color, $secondary-color, $tertiary-color, $quaternary-color);

$themes-map: (
    primary: $primary-color,
    secondary: $secondary-color,
    tertiary: $tertiary-color,
    quaternary: $quaternary-color,
);

// $focus-color: #649af5;
$focus-color-dark: color.adjust($blue-dark, $lightness: 5%);
$focus-color-light: $white;

$focus-ring: 3px solid $focus-color-dark;
$focus-ring-light: 3px solid $focus-color-light;

// Layout
$card-box-shadow:
    0 0 4px 0 rgba($black, 0.1),
    0 6px 18px 0 rgba($black, 0.16);

// Fonts
$font-sizes-tiny: (
    h1: 30px,
    h2: 20px,
    h3: 18px,
    h4: 17px,
    h5: 13px,
    h6: 13px,
    body: 14px,
);

$font-sizes-small: (
    h1: 32px,
    h2: 22px,
    h3: 20px,
    h4: 19px,
    h5: 15px,
    h6: 15px,
    body: 16px,
);

$font-sizes-medium: (
    h1: 40px,
    h2: 22px,
    h3: 20px,
    h4: 19px,
    h5: 15px,
    h6: 15px,
    body: 18px,
);

$font-sizes-large: (
    h1: 60px,
    h2: 30px,
    h3: 23px,
    h4: 21px,
    h5: 18px,
    h6: 18px,
    body: 19px,
);

$default-line-height: 1.42;
$heading-line-height: 1;

$body-font-regular: "Avenir LT W01_55 Roman1475520", Verdana, Arial, sans-serif;
$custom-font-regular: "Filson W01 Regular", Verdana, Arial, sans-serif;
$custom-font-bold: "Filson W01 Bold", Verdana, Arial, sans-serif;

$body-font: $body-font-regular;
$heading-font: $custom-font-bold;

// Widths & sizes
$site-width-small: 680px;
$site-width-medium: 840px;
$site-width-large: 1120px;
$site-max-width: 1140px;

/** Breakpoints
 * The following is inspired by this article:
 * http://css-tricks.com/media-queries-sass-3-2-and-codekit/
 */
$tiny: 360;
$small: 540;
$medium: 680;
$medium-large: 900;
$large: 1200;
$extra-large: 1400;

// Event based breakpoints
$nav-switch: 960;

// Magic numbers
$quarter-line: 0.4rem;
$half-line: 0.8rem;
$single-line: 1.6rem;
$double-line: 3.8rem;
$triple-line: 4.8rem;

$half-margin: 1.3rem;
$single-margin: $half-margin * 2;
$double-margin: $single-margin * 2;
$triple-margin: $single-margin * 3;

$gutter-xs: 10px;
$gutter-sm: 15px;
$gutter: 30px;
$gutter-lg: 60px;
$gutter-xl: 90px;

$typography-spacer: 1.43em;

$tap-size: 44px;
$tap-size-large: 48px;

// Column settings
$width-1-column: 100%;
$width-2-columns: 50%;
$width-3-columns: 33.333%;
$width-4-columns: 25%;

// Column breakpoints
$switch-to-2-columns: $small;
$switch-to-3-columns: 990;

// Module settings
$card-max-height: 390px;
$card-margin-medium: $gutter-xs;
$card-margin-large: ($gutter * 1.5) / 2;
$card-padding-small: $gutter-sm;
$card-padding-medium: $gutter-sm * 1.5;
$card-padding-large: $gutter;
$card-padding-huge: $gutter-lg;
$card-padding-top-small: 60px;
$card-padding-top-medium: 80px;
$card-border-radius: 1em 0;

$stacked-card-width-medium: 10px;
$stacked-card-width-large: 15px;

$menu-item-padding-extra-small: 8px;
$menu-item-padding-small: 17px;
$menu-item-padding-medium: 20px;
$menu-item-padding-large: $gutter;

$site-header-height-small-screens: 120px;
$site-header-height-medium-screens: 140px;
$site-header-height-large-screens: 75px;

$site-header-bar-height-small-screens: $site-header-height-small-screens / 2;
$site-header-bar-height-medium-screens: $site-header-height-medium-screens / 2;

$site-logo-width-small: 60px;
$site-logo-height-small: $site-logo-width-small;
$site-logo-width-medium: 75px;
$site-logo-height-medium: 80px;
$site-logo-width-large: 110px;
$site-logo-height-large: 120px;

$toc-menu-switch: $medium-large;
$toc-menu-width: 270px;
$toc-top-spacer: $site-header-height-large-screens;

$page-block-thumb-size: 75px;
$page-block-image-size-medium: 125px;
$page-block-image-size-large: 165px;

$search-button-small: 48px;
$search-button-medium: 60px;
$search-button-large: 75px;

$circle-size-extra-small: 100px;
$circle-size-small: 115px;
$circle-size-medium: 140px;
$circle-size-large: 160px;

$search-result-icon-small: 50px;
$search-result-icon-large: 80px;

$quality-mark-width: 535px;

$news-header-media-offset-small: 80px;
$news-header-media-offset-medium: 140px;
$news-header-media-offset-large: 220px;

/*
 * z-index
 * for stacking stuff
 *
 * define the stacking order for modules here
 * within a module you should define it via
 * the $module-z-index variable
 */
$z-index: (
    category-card-collapsed: 1,
    category-card-expanded: 666,
    category-card-underlay: -1,
    category-card-stacked-underlay: -2,
    search-dropdown: 777,
    search-result-click-area: 1,
    site-header: 888,
    relevant-filter: 101,
    category-filter: 100,
);
