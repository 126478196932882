.content-illustration {
    max-width: 100%;
    position: relative;
    z-index: 555;
    margin: 0;
    padding: 0;
    text-align: center;
    pointer-events: none; // If illustrations overlaps content, let links etc be clickable
    @include bp-min($medium-large) {
        float: right;
        max-width: 300px;
        transform: translate(0, -20%);
    }
}
// Half circle behind illustration
.content-illustration::before {
    content: "";
    z-index: -1;
    position: absolute;
    height: 0;
    padding-bottom: 130%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    @include bp-max($medium-large) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        background-image: url("../../images/curve.svg");
        background-position: 50% 0;
    }
    @include bp-min($medium-large) {
        top: -3.5vw;
        right: 0;
        width: 43vw;
        background-image: url("../../images/curve-large.svg");
        background-position: 100% 0;
    }
}
.content-illustration svg {
    margin: $single-line 0;
    padding: 0;
    @include bp-min($medium-large) {
        float: right;
    }
}
