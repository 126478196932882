$logo-size-small: 40px;
$logo-size-medium: 46px;

.quality-mark-item {
    width: 100%;
    background: $white;
    box-shadow: 0 0 15px 0 rgba($text-color, 0.15);
}
// Header
.quality-mark-item__bar {
    @include bare-button;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: $logo-size-small;
    margin: 0;
    padding: $gutter-xs $gutter-sm;
    font-family: $custom-font-regular;
    text-align: left;
    text-decoration: none;
    @include bp-min($small) {
        min-height: $logo-size-medium;
        padding: 12px 23px;
    }
}
.quality-mark-item--with-description .quality-mark-item__bar {
    cursor: pointer;
    padding-left: 40px;
    @include bp-min($medium) {
        padding-left: 65px;
    }
}
// Arrow up down toggle
.quality-mark-item__bar::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -10px;
    display: block;
    width: 20px;
    height: 20px;
    margin: -10px 0 0 22px;
    background: $primary-color url("../../images/chevron--small-white-down.svg")
        no-repeat 50% 54%;
    background-size: 40% auto;
    color: $white;
    border: 1px solid;
    border-radius: 50%;
    transform: translate3d(0, 0, 0) rotate(0.75turn);
    transition: transform 0.2s;
    @include bp-min($medium) {
        left: 0;
        margin-top: -15px;
        width: 30px;
        height: 30px;
        border-radius: 100%;
    }
}
.quality-mark-item__bar[aria-expanded="true"]::before {
    transform: translate3d(0, 0, 0) rotate(1turn);
}
.quality-mark-item__title {
    margin: 0 $gutter 0 0;
    font-size: 14px;
    font-family: $custom-font-regular;
    @include bp-min($medium) {
        font-size: 19px;
    }
}
.quality-mark-item--with-description
    .quality-mark-item__bar:hover
    > .quality-mark-item__title {
    text-decoration: underline;
}
.quality-mark-item__image {
    max-width: $logo-size-small;
    max-height: $logo-size-small;
    height: auto;
    margin-left: auto;
    object-fit: contain;
    @include bp-min($small) {
        max-width: $logo-size-medium;
        max-height: $logo-size-medium;
    }
}

// Content
.quality-mark-item__content {
    display: none;
    padding: $gutter-xs 0;
    margin: 0 $gutter-sm;
    border-top: 1px solid $grey;
    @include bp-min($small) {
        margin-left: 23px;
        margin-right: 23px;
        padding: $gutter-sm 0;
    }
}
.quality-mark-item__content.js-is-expanded {
    display: block;
}
.quality-mark-item__content :last-child {
    margin-bottom: 0;
}

// No toggle option when there is no description
.quality-mark-item--no-description .quality-mark-item__bar::before {
    content: none;
}
