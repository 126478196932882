.intro-blocks {
    @include content(large);
    @include clearfix;
    @include bp-min($medium) {
        @include row-bottom(small);
        display: flex;
    }
    @include bp-min($large) {
        @include row-bottom(large);
    }
}
.intro-blocks__item {
    position: relative;
    padding: $gutter 0;
    @include bp-min($medium) {
        display: flex;
        width: 33.3333%;
        padding: 0 3.5%;
        font-size: 17px;
        text-align: center;
    }
}
.intro-blocks__header {
    width: 100%;
}
.intro-blocks__image {
    float: left;
    width: 25%;
    max-width: 140px;
    margin: 0 $gutter-sm 0 0;
    text-align: center;
    @include bp-min($medium) {
        width: 100%;
        float: none;
        margin: 0 auto $gutter-sm;
    }
}
.intro-block__image--icon svg {
    width: 25%;
    max-width: 80px;
    fill: $text-color;
}
.intro-blocks__image svg {
    width: 100%;
    height: auto;
    vertical-align: top;
}
.intro-blocks__button {
    min-width: 0;
    margin: 0;
    @include bp-min($medium) {
        min-width: auto;
        width: 100%;
        max-width: 15em;
        margin: 0 auto;
        margin-top: auto;
    }
}
.intro-blocks__item a {
    display: block;
    color: currentcolor;
    text-decoration: none;
    @include bp-min($medium) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}
.intro-blocks__item p:last-of-type {
    margin-bottom: $single-line;
    @include bp-min($medium-large) {
        margin-bottom: $double-line;
    }
}
.intro-blocks__section-supertitle.intro-blocks__section-supertitle {
    margin-bottom: $quarter-line;
}
.intro-blocks__item::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: rgba($white, 0.5);
    @include bp-min($medium) {
        top: 0;
        bottom: auto;
        width: 1px;
        height: 95%;
    }
}
.intro-blocks__item:first-of-type {
    border-top: 1px solid rgba($white, 0.5);
    @include bp-min($medium) {
        border: 0;
    }
}
.intro-blocks__item:last-of-type::after {
    content: none;
}
