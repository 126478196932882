.highlighted-pages {
    background-color: $white;
}
.styleguide .highlighted-pages {
    overflow-x: scroll;
}
.highlighted-pages__inner {
    @include content(large);
    @include row(medium);
}
// This adds a fix for highlighted pages that are visible when a user clicks on a theme on the homepage
.has-overlay .highlighted-pages__inner {
    opacity: 0;
}
.highlighted-pages__title {
    @include block-title;
    margin-bottom: $single-margin;
}
.highlighted-pages__previews,
.highlighted-pages__small-previews {
    display: grid;
    align-items: flex-start;
    width: 100%;
    grid-gap: $gutter-sm;

    @include bp-min($medium-large) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
    }
}
.highlighted-pages__small-previews {
    align-items: stretch;

    @include bp-min($small) {
        grid-template-columns: 1fr 1fr;
    }
}
