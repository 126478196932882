.auto-suggest-list {
    @include z-index(search-dropdown);
    overflow: hidden;
    position: absolute;
    width: 100%;
    background: $white;
    box-shadow: 0 5px 8px rgba($black, 0.2);
    text-align: left;
    @include bp-min($medium) {
        width: calc(100% - #{$gutter-lg});
    }
}
.site-header .auto-suggest-list {
    top: $search-button-small;
    width: 100%;
}
.auto-suggest-list h3 {
    z-index: 888;
    position: absolute;
    top: $gutter-sm;
    right: $gutter-sm * 1.5;
    font-size: 16px;
}
.auto-suggest-list ul {
    @include bare-list;
}
.auto-suggest-list ul li:first-child::after {
    content: "";
    position: absolute;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 20px;
    background-image: linear-gradient(rgba($black, 0.12), rgba($black, 0)),
        linear-gradient(rgba($black, 0.03), rgba($black, 0) 40%);
}
.auto-suggest-list li {
    position: relative;
    z-index: 0;
    margin: 0;
}
.auto-suggest-list li:first-child {
    padding-top: $gutter;
    @include bp-min($medium) {
        padding-top: $gutter;
    }
}
.auto-suggest-list li:last-child {
    padding-bottom: $gutter-xs;
    @include bp-min($medium) {
        padding-bottom: $gutter-sm;
    }
}
.auto-suggest-list a {
    z-index: 10;
    position: relative;
    display: block;
    width: 100%;
    padding: 5px $gutter-sm 5px ($gutter * 1.5);
    text-decoration: none;
    color: $text-color;
    background: url("../../images/icons/search--grey.svg") no-repeat left
        $gutter-sm top 50%;
    background-size: 1em auto;
    transform: translate3d(0, 0, 0);
    transition:
        transform 0.2s,
        background-color 0.2s,
        color 0.2s;
    @include bp-min($medium) {
        padding: 7px $gutter 7px $gutter-lg;
        background-position: left $gutter top 50%;
    }
}
.auto-suggest-list a:hover {
    background-color: $grey-light;
    color: inherit;
    outline: 0;
}
