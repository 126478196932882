@use "sass:color";

/**
 * Index page for substances with index navigation and cards
 * for search: go to search-form.scss
 */
.index__inner {
    @include content(large);
    @include row-bottom(medium);
    @include bp-min($small) {
        padding-left: $gutter;
        padding-right: $gutter;
    }
}

/**
 * Letters & Categories
 */
.index__nav {
    @include row-bottom(small);
    margin: 0 0 $gutter-xs;
    @include bp-min($medium) {
        @include content(medium);
        margin: 0 auto;
    }
}
.index__nav ul {
    @include bare-list;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    min-height: 60px;
    line-height: 50px;
    margin: 0;
    padding: 0 10px;
    background-color: color.adjust($grey-light, $lightness: 5%);
    border-top: 1px solid $grey-light;
    text-align: center;
    @include bp-min($medium) {
        line-height: 58px;
    }
    @include bp-min($medium-large) {
        flex-wrap: nowrap;
        justify-content: space-around;
    }
}
// Box shadow layer
.index__nav ul::before {
    @include box-shadow;
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.index__nav li {
    display: inline-block;
    width: 14%;
    height: inherit;
    margin: 0;
    color: $grey-dark;
    font-family: $custom-font-regular;
    font-size: 15px;
    cursor: not-allowed;
}

.index__nav a {
    display: inline-block;
    width: 100%;
    height: inherit;
    color: $text-color;
    text-decoration: none;
    font-family: $custom-font-bold;
    border-bottom: 3px solid transparent;
    cursor: pointer;
}

.index__nav a:hover {
    color: $text-color;
    border-color: currentcolor;
}

.index__nav span {
    display: none;
    @include bp-min(460) {
        display: inline-block;
    }
}

.index__nav .index__search-result-amount {
    display: inline-block;
    vertical-align: super;
    font-size: 10px;

    @include bp-min($medium) {
        font-size: 12px;
    }
}

.index__nav .active {
    border-color: currentcolor;
    color: $text-color;
}

/**
 * Index Nav on Search page (Categories)
 */
.index__nav--search-results li {
    width: 50%;
    white-space: nowrap;
    font-size: 14px;
    @include bp-min($medium) {
        font-size: 16px;
    }
    @include bp-min($medium-large) {
        margin: 0 $gutter-sm;
    }
}

/**
 * Search results overview
 */
.index__search-results,
.index__substances-results,
.index__wordlist-results {
    @include bp-min($medium) {
        @include content(medium);
        margin: 0 auto;
    }
}

.index__substances-results {
    margin: $gutter auto;

    @include bp-min($medium) {
        margin-top: $gutter-lg;
    }
}

.index__wordlist-results {
    margin-bottom: $gutter;
}

/**
 * Search results headers - Producten(4)
 */
.index__search-results-header {
    color: $white;
    font-size: 20px;
    text-transform: uppercase;
    padding: 0 $gutter-sm;

    @include bp-min($medium) {
        padding: 0 25px;
    }
}
.index__search-results ol {
    @include bare-list;
}

/**
 * Single search result
 */
.index__search-result {
    @include clearfix;
    @include box-shadow;
    @include card-hover-effect;
    position: relative;
    background: $white;
    color: $text-color;
    margin: 0 0 ($gutter-xs / 2);
    padding: $gutter-sm;

    @include bp-min($medium) {
        padding-right: $gutter-lg;
    }

    @include bp-min($medium-large) {
        padding: $gutter;
        padding-right: $gutter-xl;
    }
}

// If no result
p.index__search-result {
    min-height: 0;
}

.index__search-result:last-of-type {
    margin-bottom: 0;
}

.index__search-result :last-child {
    margin-bottom: 0;
}

// Expand the clickable area
.index__search-result a {
    @include expand-clickable-area;
    text-decoration: none;

    @include hocus {
        color: $text-color;
    }
}
.index__search-result a::after {
    @include z-index(search-result-click-area);
}

.index__search-result .index__read-more {
    width: auto;
    margin-top: -$typography-spacer;
}

// Images of svg in search results
.index__search-result figure {
    width: $search-result-icon-small;
    height: 100%;
    float: left;
    margin: 0 $gutter-sm 0 0;

    @include bp-min($medium) {
        width: $search-result-icon-large;
    }

    @include bp-min($medium-large) {
        margin: 3px $gutter 0 0;
    }

    &.product {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.product > svg {
        display: block;
    }
}
.index__search-result figure > svg {
    max-width: 90%;
    max-height: 38px;
    fill: currentcolor;
}

.index__search-result-image-container {
    @include aspect-ratio(4, 2.75);
}

.index__search-result span {
    float: left;
    width: calc(100% - #{$search-result-icon-small} - #{$gutter-sm});
    font-size: 17px;
    @include bp-min($medium) {
        width: calc(100% - #{$search-result-icon-large} - #{$gutter});
    }
    @include bp-min($large) {
        font-size: 19px;
    }
}
// Unfortunately we can't ensure "hyphenated" words within the search result
.index__search-result span > p {
    word-break: break-word;
}

.index__no-result {
    display: block;
    margin: 0 auto;
    padding: $gutter-sm;
    background: rgba($white, 0.2);
    text-align: center;

    @include bp-min($medium-large) {
        padding-left: $gutter;
        padding-right: $gutter;
    }
}

.index__no-result[aria-hidden="true"] {
    display: none;
}
