// This custom property will be updated through JS, to match the amount of panels
:root {
    --featured-categories-panel-amount: 1;
}

$panel-padding-small: $gutter-sm;
$panel-padding-medium: 21px 27px 27px;

$panel-orientation-switch: $medium-large;
$panel-transition-duration: 0.3s;
$panel-transition-easing: ease-in-out;

$panel-button-indicator-size: 32px;
$panel-button-image-size-small: 32px;
$panel-button-image-size-medium: 55px;

$panel-image-size-small: 40px;
$panel-image-size-medium: 65px;

$focus-color: $primary-color;
$focus-ring-size: 3px;
$focus-ring: $focus-ring-size solid $focus-color;

.featured-categories__inner {
    @include row-top(medium);
    @include row-bottom(large);
    @include content(large);
}

/**
 * Module header
 */
.featured-categories__header {
    @include row-bottom;
    @include bp-min($small) {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
}
.featured-categories__title {
    margin: 0 0 0.5em;
    line-height: 1;
    @include bp-min($small) {
        margin: 0;
    }
}
.featured-categories__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    white-space: nowrap;
}
.featured-categories__link:hover {
    text-decoration: underline;
    text-underline-position: under;
}
.featured-categories__link > svg {
    width: 0.8em;
    height: 1.35em;
    margin-left: 0.35em;
    transition: transform 0.2s ease-in-out;
}
.featured-categories__link:hover > svg {
    transform: translateX(0.15em);
}

/**
 * List
 */
.featured-categories__list {
    @include bare-list;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    @include bp-min($panel-orientation-switch) {
        flex-direction: row;
        height: 600px;
        max-height: 85vh;
    }
}
.no-js .featured-categories__list {
    display: block;
    height: auto;
    max-height: none;
    @include bp-min($small) {
        height: inherit;
    }
}

/**
 * Expand Panelbuttons
 */
.featured-categories__button {
    @include bare-button;
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    background: $white;
    border-radius: inherit;
    cursor: pointer;
    @include bp-max($panel-orientation-switch) {
        padding-right: 3em;
    }
}
[aria-selected="true"] .featured-categories__button,
.no-js .featured-categories__button {
    display: none;
}
.featured-categories__button:hover {
    background: $grey-lighter;
}
.featured-categories__button:focus {
    outline: $focus-ring;
    outline-offset: -$focus-ring-size;
    box-shadow: inset 0 0 0 ($focus-ring-size + 2px) $white;
}
.featured-categories__button-inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;

    @include bp-min($panel-orientation-switch) {
        position: absolute;
        top: 0;
        left: 50%;
        flex-direction: column;
        width: 100%;
        padding: $gutter 0;
        white-space: nowrap;
        transform: translateX(-50%);
    }
}
.featured-categories__button-inner::before {
    @include bp-min($panel-orientation-switch) {
        content: "";
        position: absolute;
        z-index: 10;
        left: 0;
        bottom: 0;
        width: 100%;
        height: $gutter-lg;
        border-bottom: 15px solid $white;
        background: linear-gradient(
            to bottom,
            rgba($white, 0),
            rgba($white, 1)
        );
    }
}

.featured-categories__button-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: $panel-button-indicator-size;
    height: $panel-button-indicator-size;
    background: $primary-color;
    border-radius: 50%;
    transition: transform 0.2s;
    @include bp-max($panel-orientation-switch) {
        position: absolute;
        top: 50%;
        right: $gutter-sm;
        transform: translateY(-50%);
    }
}
.featured-categories__button:hover .featured-categories__button-indicator {
    @include bp-min($panel-orientation-switch) {
        transform: scale(1.15);
    }
}
.featured-categories__button-indicator > svg {
    display: block;
    width: 14px;
    height: 14px;
    margin-top: 1px; // Optical correction
    color: $white;
}
.featured-categories__button-image {
    width: $panel-button-image-size-small;
    height: $panel-button-image-size-small;
    margin: 0 $gutter-xs 0 0;
    flex-shrink: 0;
    @include bp-min($panel-orientation-switch) {
        width: $panel-button-image-size-medium;
        height: $panel-button-image-size-medium;
        margin: $gutter-sm auto;
    }
}
.featured-categories__button-image > svg {
    width: 100%;
    height: 100%;
}
.featured-categories__button-name {
    @include regular-font-size;
    display: block;
    font-family: $custom-font-bold;
    @include bp-min($panel-orientation-switch) {
        position: absolute;
        left: 50%;
        top: $gutter + $panel-button-indicator-size + $gutter-sm +
            $panel-button-image-size-medium + $gutter-sm;
        transform: rotate(0.25turn) translateY(-50%);
        transform-origin: 0 0;
    }
}

/**
 * Panels
 */
.featured-categories__panel {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    transition-duration: $panel-transition-duration;
    transition-timing-function: $panel-transition-easing;
    transition-property: all;
    box-shadow: 0 0 10px rgba($primary-color, 0.3);
    border-radius: $card-border-radius;
    @include bp-min($panel-orientation-switch) {
        box-shadow: 0 0 4px rgba($primary-color, 0.01),
            0 6px 18px rgba($primary-color, 0.16);
    }
    @include reduced-motion {
        transition: none;
    }
}
.featured-categories__panel:hover {
    @include bp-min($panel-orientation-switch) {
        box-shadow: 0 0 4px rgba($primary-color, 0.3),
            0 6px 22px rgba($primary-color, 0.26);
    }
}

.no-js .featured-categories__panel {
    margin-bottom: $quarter-line;
    @include bp-min($panel-orientation-switch) {
        margin-bottom: $single-line;
    }
}

.featured-categories__panel[aria-selected="false"] {
    min-height: calc(100% / var(--featured-categories-panel-amount));
    @include bp-min($panel-orientation-switch) {
        width: calc((100% / var(--featured-categories-panel-amount)));
        height: 100%;
    }
}
.featured-categories__panel:not(:last-child) {
    margin-bottom: 10px;
    @include bp-min($panel-orientation-switch) {
        margin-right: 10px;
        margin-bottom: 0;
    }
    @include bp-min($large) {
        margin-right: 15px;
    }
}

// Fallback styling for when Accordion is not complete (less than 6 items)
[data-panels-amount="1"] .featured-categories__panel[aria-selected="true"] {
    transition: none;
    @include bp-min($panel-orientation-switch) {
        width: 70%;
    }
}
[data-panels-amount="2"] .featured-categories__panel[aria-selected="true"],
[data-panels-amount="3"] .featured-categories__panel[aria-selected="true"] {
    @include bp-min($panel-orientation-switch) {
        width: 150%;
    }
}
[data-panels-amount="4"] .featured-categories__panel[aria-selected="true"] {
    @include bp-min($medium) {
        width: 120%;
    }
}

/** 
 * Panel Content
 */
.featured-categories__panel-content {
    max-height: 0;
    background: $white;
    @include bp-min($panel-orientation-switch) {
        max-height: none;
        transition: all 0.2s ease-in;
    }
}
.no-js .featured-categories__panel-content {
    opacity: 1;
    position: relative;
    width: 100%;
    max-width: 600px;
    max-height: none;
    margin: 0 auto;
    padding: $panel-padding-small;
    @include bp-min($panel-orientation-switch) {
        padding: $panel-padding-medium;
    }
}

[aria-selected="true"] .featured-categories__panel-content {
    display: block;
    width: 100%;
    height: 100%;
    padding: $panel-padding-small;
    @include bp-min($panel-orientation-switch) {
        padding: $panel-padding-medium;
    }
    @include bp-max($panel-orientation-switch) {
        max-height: none;
    }
}

/** 
 * Panel Inner Content
 */
.featured-categories__panel-content-inner {
    opacity: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    @include bp-min($panel-orientation-switch) {
        overflow: auto;
        padding: $focus-ring-size; // Make sure focus ring is not cut off
    }
}
.no-js .featured-categories__panel-content-inner {
    opacity: 1;
}

[aria-selected="true"] .featured-categories__panel-content-inner {
    opacity: 1;
    transform: none;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    @include bp-min($panel-orientation-switch) {
        transition-delay: $panel-transition-duration;
    }
}
.featured-categories__panel-header {
    display: flex;
    align-items: center;
    margin-bottom: $single-line;
}
.featured-categories__panel-image {
    position: relative;
    width: $panel-image-size-small;
    height: $panel-image-size-small;
    margin: 0;
    flex-shrink: 0;
    @include bp-min($panel-orientation-switch) {
        width: $panel-image-size-medium;
        height: $panel-image-size-medium;
    }
}
.featured-categories__panel-image > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.featured-categories__panel-title {
    margin: 0;
    padding-left: $gutter-sm;
}
.featured-categories__panel-intro > p {
    margin-bottom: $double-line;
}

/** 
 * Product buttons
 */
.featured-categories__products {
    @include bare-list;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $single-line;
}
.featured-categories__product-button {
    margin: $single-line 10px 0 0;
    word-break: break-word;
}
.featured-categories__info-button-wrapper {
    margin-top: auto;
    padding-top: 1em;
    border-top: 1px solid $grey-light;
}
.featured-categories__info-button {
    display: block;
    width: 100%;
    @include bp-min($panel-orientation-switch) {
        display: inline-block;
        width: auto;
        margin-right: auto;
    }
}
.featured-categories__info-button-label {
    text-transform: lowercase;
}
