// stylelint-disable scss/at-rule-no-unknown

@mixin row-top($size: small) {
    @if $size == medium {
        padding-top: 30px;
        @include bp-min($small) {
            padding-top: 50px;
        }
        @include bp-min($medium) {
            padding-top: 75px;
        }
    } @else if $size == large {
        padding-top: 40px;
        @include bp-min($small) {
            padding-top: 75px;
        }
        @include bp-min($medium) {
            padding-top: 100px;
        }
    } @else if $size == none {
        padding-top: 0;
    } @else {
        padding-top: 25px;
        @include bp-min($small) {
            padding-top: 40px;
        }
        @include bp-min($medium) {
            padding-top: 40px;
        }
    }
}

@mixin row-bottom($size: small) {
    @if $size == medium {
        padding-bottom: 30px;
        @include bp-min($small) {
            padding-bottom: 50px;
        }
        @include bp-min($medium) {
            padding-bottom: 70px;
        }
    } @else if $size == large {
        padding-bottom: 40px;
        @include bp-min($small) {
            padding-bottom: 70px;
        }
        @include bp-min($medium) {
            padding-bottom: 100px;
        }
    } @else if $size == none {
        padding-bottom: 0;
    } @else {
        padding-bottom: 25px;
        @include bp-min($small) {
            padding-bottom: 40px;
        }
        @include bp-min($medium) {
            padding-bottom: 50px;
        }
    }
}

@mixin row($size: small) {
    @include row-top($size);
    @include row-bottom($size);
}

.row {
    @include row;
}
$row-sizes: ("small", "medium", "large");
@each $size in $row-sizes {
    .row--#{$size} {
        @include row($size);
    }
    .row-top--#{$size} {
        @include row-top($size);
    }
    .row-bottom--#{$size} {
        @include row-bottom($size);
    }
}
