.product-appearances {
    @include bare-list;
}

.product-appearances li {
    display: inline-block;
    margin: 0;
}

.product-appearances li:nth-child(odd) {
    margin-right: 50px;
}

.product-appearances .button {
    min-width: 300px;
    margin: 0 0 10px;
}
