// stylelint-disable no-unknown-animations
@keyframes video-scale-in {
    from {
        transform: scale(0.5);
    }
    to {
        transform: scale(1);
    }
}
.video-overlay {
    display: none;
}
.video-overlay.is-visible {
    display: block;
    z-index: 888;
    padding: 25px;
    transform: translate3d(-50%, 0, 0);
    animation:
        video-scale-in 0.2s forwards,
        fade-in 0.2s forwards;
    @include bp-min($small) {
        padding: $gutter-lg $gutter;
    }
    @include bp-min($medium-large) {
        @include content(medium);
    }
}
.video-overlay__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.video-overlay__video {
    overflow: hidden;
    margin: 0;
    box-shadow:
        0 0 50px rgba($black, 0.3),
        0 0 10px 0 rgba($black, 0.1);
    border-radius: 5px;
}
.is-visible .video-overlay__video::after {
    animation:
        minimize 0.2s 0.9s forwards,
        fade-out 0.2s 0.7s forwards;
}
.video-overlay__button {
    z-index: 666;
    position: absolute;
    top: 0;
    right: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    background: $primary-color url("../../images/close--white.svg") no-repeat
        50% 50% / 15px auto;
    color: $white;
    border-radius: 50%;
    transform: translate(50%, -50%);
    transition: transform 0.13s cubic-bezier(0.17, 0.67, 0.83, 0.67);

    @include hocus {
        transform: translate(50%, -50%) scale(1.1);
    }
}
.video-overlay__button:focus {
    box-shadow: 0 0 0 2px $white;
    outline-offset: 2px;
}
