.login__inner {
    @include row(medium);
    max-width: 600px;
    margin: 0 auto;
    padding: $gutter-sm;
}
.login form {
    background: $white;
    padding: $gutter-sm;

    @include bp-min($medium) {
        padding: $gutter;
    }
}
.login__button {
    margin-bottom: 0;
}
