.card {
    @include z-index(category-card-collapsed);
    position: relative;
    width: 100%;
    padding: $card-padding-top-small 0 $card-margin-large;
    @include bp-min($switch-to-2-columns) {
        width: $width-2-columns;
        padding: $card-padding-top-medium $card-margin-medium
            $card-margin-medium;
    }
    @include bp-min($switch-to-3-columns) {
        width: $width-3-columns;
        padding: $card-padding-top-medium $card-margin-large $card-margin-large;
    }
}
.card--is-expanded {
    @include z-index(category-card-expanded);
    position: relative;
}
// Mask underlying illustrations in the same row
.card--is-expanded::before {
    @include bp-min($switch-to-2-columns) {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 300vw;
        height: $card-padding-top-medium;
        transform: translateX(-50%);
        background: $bg-color;
    }
}
.card__inner {
    position: relative;
    height: 100%;
    padding: 0 $card-padding-small $card-padding-small;
    background-color: $white;
    border-radius: $card-border-radius;
    @include bp-min($switch-to-2-columns) {
        padding: 0 $card-padding-medium $card-padding-medium;
    }
    @include bp-min($switch-to-3-columns) {
        padding: 0 $card-padding-large $card-padding-large;
    }
}
.card__container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    color: $text-color;
    text-decoration: none;
}
.card--has-link-list .card__inner,
.card--has-link-list .card__container {
    height: auto;
}
.card--is-expanded .card__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
}
/** 
 * Card illustration
 */
.card__image {
    width: 100%;
    margin-top: -$card-padding-top-small;
    @include bp-min($switch-to-3-columns) {
        margin-top: -$card-padding-top-medium;
    }
}
.card__image > svg {
    display: block;
    width: 100%;
    height: auto;
    max-height: 100%;
}
/** 
 * Card link
 */
.card__link {
    @include expand-clickable-area;
    text-decoration: none;
}

/** 
 * Card contents
 */
.card__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 1 auto;
    width: 100%;
    @include bp-min($small) {
        min-height: 3.5em;
    }
}
.card__content p:last-of-type {
    margin-bottom: $double-line;
}
.card__title {
    @include h4;
    width: 100%;
    margin: 0 0 $gutter-sm;
    font-family: $custom-font-bold;
    @include bp-min($switch-to-2-columns) {
        margin-bottom: $gutter-xs;
    }
    @include bp-min($switch-to-3-columns) {
        line-height: 1.27;
    }
}
.card--is-expanded .card__title {
    @include bp-min($switch-to-2-columns) {
        width: calc(100% + #{$card-padding-medium});
    }
    @include bp-min($switch-to-3-columns) {
        width: calc(100% + #{$card-padding-large});
    }
}
.card__title svg {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-left: 0.1em;
    transform: translateY(0.15em);
}
.card--has-link-list .card__link > span {
    background: url("../../images/chevron--pink.svg") no-repeat left 100% bottom
        0.13em / 8px;
    padding-right: 1em;
    line-height: 1.27;
    @include bp-min($medium) {
        background-position: left 100% bottom 0.17em;
        background-size: 9px;
    }
}
// Disable expanded clickable area for this edge case
.card--has-link-list .card__link::after {
    content: none;
}
.card__intro {
    margin: 0;
}
.card__read-more {
    @include read-more-link;
}
.card--is-expanded .card__buttons-amount {
    display: none;
}
/** 
 * Card buttons
 */
.card--is-expanded .card__buttons {
    @include bp-min($switch-to-2-columns) {
        width: calc(
            200% + (#{$card-margin-medium} * 2) + (#{$card-padding-medium} * 2)
        );
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        height: 100%;
    }
    @include bp-min($switch-to-3-columns) {
        width: calc(
            300% + (#{$card-margin-large} * 4) + (#{$card-padding-large} * 4)
        );
    }
}

// 3 white stacked overlays created by pseudo elements
.card__buttons::after,
.card__buttons::before,
.card__buttons-list::before {
    @include bp-min($switch-to-2-columns) {
        @include z-index(category-card-underlay);
        @include box-shadow;
        content: "";
        position: absolute;
        top: 0;
        width: calc(200% + (#{$card-padding-medium}));
        height: 100%;
        background: $white;
        border-radius: $card-border-radius;
        transform: scale(0); // hide initially
        transition:
            left 0.1s linear,
            right 0.1s linear;
    }
    @include bp-min($switch-to-3-columns) {
        width: calc(300% + (#{$card-padding-large} * 3));
    }
}

// Show stacked overlays when card is expanded
.card--is-expanded .card__buttons::after,
.card--is-expanded .card__buttons::before,
.card--is-expanded .card__buttons-list::before {
    transform: scale(1);
}

/** 
 * Faux stacked card - Level 1
 */
.card--is-expanded .card__buttons::before,
.card--is-expanded .card__buttons-list::before {
    @include bp-min($switch-to-2-columns) {
        top: $card-padding-medium;
        height: calc(100% - (#{$card-padding-medium} * 2));
        max-height: $card-max-height;
    }
    @include bp-min($switch-to-3-columns) {
        top: $card-padding-large;
        height: calc(100% - (#{$card-padding-large} * 2));
    }
}

/** 
 * Faux stacked card - Level 2
 */
.card--is-expanded .card__buttons-list::before {
    content: none;
    @include bp-min($switch-to-3-columns) {
        @include z-index("category-card-stacked-underlay");
        content: "";
        top: $card-padding-large * 2;
        height: calc(100% - (#{$card-padding-large} * 4));
        max-height: calc(#{$card-max-height} - (#{$card-padding-large} * 2));
    }
}

/** 
 * 2 columns - items on the left
 */
.card--is-expanded:nth-child(2n + 1) .card__buttons::after {
    @include bp-min-max($switch-to-2-columns, $switch-to-3-columns) {
        left: 0;
    }
}
// 2 columns - items on the left - initial stacked card position
.card:nth-child(2n + 1) .card__buttons::before {
    @include bp-min-max($switch-to-2-columns, $switch-to-3-columns) {
        left: 0;
    }
}
// 2 columns - items on the left - stacked card level 1
.card--is-expanded:nth-child(2n + 1) .card__buttons::before {
    @include bp-min-max($switch-to-2-columns, $switch-to-3-columns) {
        left: $stacked-card-width-medium;
    }
}

/**
 * 2 columns - items on the right
 */
.card--is-expanded:nth-child(2n + 2) .card__buttons::after {
    @include bp-min-max($switch-to-2-columns, $switch-to-3-columns) {
        right: 0;
    }
}
.card--is-expanded:nth-child(2n + 2) .card__buttons,
.card--is-expanded:nth-child(2n + 2) .card__container {
    @include bp-min-max($switch-to-2-columns, $switch-to-3-columns) {
        margin-left: calc(
            -100% - (#{$card-padding-medium} * 2) - (#{$card-margin-medium} * 2)
        );
    }
}

// 2 columns - items on the left - initial stacked card position
.card:nth-child(2n + 2) .card__buttons::before {
    @include bp-min-max($switch-to-2-columns, $switch-to-3-columns) {
        right: 0;
    }
}

// 2 columns - items on the right - stacked card level 1
.card--is-expanded:nth-child(2n + 2) .card__buttons::before {
    @include bp-min-max($switch-to-2-columns, $switch-to-3-columns) {
        right: $stacked-card-width-medium;
    }
}

/**
 *  3 columns - items on the left
 */
.card--is-expanded:nth-child(3n + 1) .card__buttons::after {
    @include bp-min($switch-to-3-columns) {
        left: 0;
    }
}

// 3 columns - items on the left - initial stacked card position
.card:nth-child(3n + 1) .card__buttons::before,
.card:nth-child(3n + 1) .card__buttons-list::before {
    @include bp-min($switch-to-3-columns) {
        left: 0;
    }
}

// 3 columns - items on the left - stacked card level 1
.card--is-expanded:nth-child(3n + 1) .card__buttons::before {
    @include bp-min($switch-to-3-columns) {
        left: $stacked-card-width-large;
    }
}
// 3 columns - items on the left - stacked card level 2
.card--is-expanded:nth-child(3n + 1) .card__buttons-list::before {
    @include bp-min($switch-to-3-columns) {
        left: $stacked-card-width-large * 2;
    }
}

//  3 columns - items in the middle
.card--is-expanded:nth-child(3n + 2) .card__buttons::after {
    @include bp-min($switch-to-3-columns) {
        left: 50%;
        transform: translateX(-50%);
    }
}

// 3 columns - items in the middle - initial stacked card position
.card:nth-child(3n + 2) .card__buttons::before {
    @include bp-min($switch-to-3-columns) {
        transition: none;
        transform-origin: 50% 50%;
        transform: translateX(-50%) scaleX(0);
    }
}

// 3 columns - items in the middle - stacked card level 1
.card--is-expanded:nth-child(3n + 2) .card__buttons::before {
    @include bp-min($switch-to-3-columns) {
        left: 50%;
        width: calc(
            300% + (#{$card-padding-large} * 3) + #{$stacked-card-width-large} +
                #{$stacked-card-width-large}
        );
        transform: translateX(-50%) scaleX(1);
        transition: transform 0.2s;
    }
}

// 3 columns - items in the middle - stacked card level 2
.card--is-expanded:nth-child(3n + 2) .card__buttons-list::before {
    @include bp-min($switch-to-3-columns) {
        content: none;
    }
}

.card--is-expanded:nth-child(3n + 2) .card__buttons,
.card--is-expanded:nth-child(3n + 2) .card__container {
    @include bp-min($switch-to-3-columns) {
        margin-left: calc(
            -100% - (#{$card-padding-large} * 2) - (#{$card-margin-large} * 2)
        );
    }
}

// 3 columns - items on the right
.card--is-expanded:nth-child(3n + 3) .card__buttons::after {
    @include bp-min($switch-to-3-columns) {
        right: 0;
    }
}

// initial stacked card position
.card:nth-child(3n + 3) .card__buttons::before,
.card:nth-child(3n + 3) .card__buttons-list::before {
    @include bp-min($switch-to-3-columns) {
        right: 0;
    }
}

// 3 columns - items on the right - stacked card level 1
.card--is-expanded:nth-child(3n + 3) .card__buttons::before {
    @include bp-min($switch-to-3-columns) {
        right: $stacked-card-width-large;
    }
}
// 3 columns - items on the left - stacked card level 2
.card--is-expanded:nth-child(3n + 3) .card__buttons-list::before {
    @include bp-min($switch-to-3-columns) {
        right: $stacked-card-width-large * 2;
    }
}
.card--is-expanded:nth-child(3n + 3) .card__buttons,
.card--is-expanded:nth-child(3n + 3) .card__container {
    @include bp-min($switch-to-3-columns) {
        margin-left: calc(
            -200% - (#{$card-padding-large} * 4) - (#{$card-margin-large} * 4)
        );
    }
}

/** 
 * Button list styling
 */
.card__buttons-list {
    @include bare-list;
    width: 100%;
    margin-bottom: $gutter-sm;
    @include bp-min($switch-to-2-columns) {
        margin-bottom: $gutter-xs;
    }
    @include bp-min($switch-to-3-columns) {
        margin-bottom: $gutter-sm;
    }
}
.card__buttons-list[aria-hidden="true"] {
    display: none;
}
.card--is-expanded .card__buttons-list {
    @include bp-min($switch-to-2-columns) {
        column-count: 2;
        column-gap: $card-padding-medium;
        column-fill: balance;
        page-break-inside: avoid;
        break-inside: avoid;
    }
    @include bp-min($switch-to-3-columns) {
        column-count: 3;
        column-gap: $card-padding-huge;
    }
}

.card__buttons-item > a {
    margin-top: 4px; // Add space for the focus ring
    margin-bottom: calc(
        (#{$half-line} * 1.5) - 4px
    ); // Remove the margin top by making the bottom margin smaller
}
.card__buttons-item:last-child {
    margin-bottom: 0;
}
.card__buttons-item[aria-hidden="true"] {
    display: none;
}
.card__buttons-link {
    width: 100%;
}
.card__buttons-more-link {
    @include bare-button;
    @include expand-clickable-area-tap-size;
    display: block;
    width: 100%;
    max-width: 300px;
    margin: auto auto 0;
    padding: 0;
    text-align: center;
    @include bp-max($switch-to-2-columns) {
        margin-bottom: $gutter-sm;
    }
}
.card__buttons-more-link:hover,
.card__buttons-more-link:focus {
    text-decoration: underline;
}
.card__buttons-more-link > svg {
    display: inline-block;
    vertical-align: middle;
    width: 0.75em;
    height: 0.4em;
    margin-left: 0.3em;
    fill: currentcolor;
    transition: transform 0.2s;
}
.card__buttons-more-link[aria-expanded="true"] > svg {
    transform: rotate(180deg);
}

/** 
 * Styleguide specific styling
 */
.styleguide .card {
    width: 300px;
}
