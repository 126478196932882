/**
 *  Button popup
 */
.button-popup {
    position: absolute;
    top: -15px;
    left: 5%;
    width: 90%;
    transform: translateY(-100%);
    padding: 10px 10px 10px 40px;
    transition: opacity 0.15s ease-out;
    pointer-events: none;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: $primary-color;
    color: $white;

    @include bp-min($medium) {
        left: -70%;
        width: 240%;
    }
}
.button-popup::before {
    content: "";
    position: absolute;
    left: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: $white url("../../images/check--blue.svg") no-repeat center /
        50%;
}
.button-popup::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
    height: 0;
    width: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $primary-color;
}
.button-popup[aria-hidden="true"] {
    opacity: 0;
}
.button-popup svg {
    position: absolute;
    left: 10px;
    width: 20px;
    height: 20px;
}
.button-popup__url {
    font-size: 12px;
    word-wrap: break-word;
}
