// stylelint-disable declaration-no-important, no-unknown-animations

.personalisation-tool {
    @include clearfix;
    background-color: $grey-lighter;
}
.personalisation-tool__inner {
    @include content(large);
    @include row-top(medium);
    position: relative;
    @include bp-min($large) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
.personalisation-tool__intro {
    @include row-bottom;
    margin: 0 auto;
    @include bp-min($medium) {
        text-align: center;
        max-width: 545px;
    }
}
.personalisation-tool__title {
    margin-bottom: 0.25em;
    line-height: 1.1;
    @include bp-min($medium) {
        font-size: 30px;
    }
    @include bp-min($medium-large) {
        font-size: 48px;
    }
}
.personalisation-tool__intro :last-child {
    margin-bottom: 0;
}

/** 
 * Theme cards
 */
.personalisation-tool__cards {
    @include row-bottom(medium);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include bp-min($switch-to-2-columns) {
        margin-left: -12px;
        margin-right: -12px;
    }
    @include bp-min($switch-to-3-columns) {
        @include content(large);
        margin: 0 auto;
    }
}
.personalisation-tool__card {
    width: $width-1-column;
    padding: $card-margin-large 0;
    @include bp-min($switch-to-2-columns) {
        width: $width-2-columns;
        padding: 14px 12px;
    }
    @include bp-min($switch-to-3-columns) {
        width: $width-3-columns;
    }
}

/** 
 * Theme overlay
 */
.personalisation-tool__theme-wrapper {
    z-index: 999;
    position: fixed;
    transform: translateZ(0);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
}
.personalisation-tool__theme-wrapper > .theme-header {
    opacity: 0;
    animation: fade-in 0.3s forwards;
    padding-top: $gutter-lg;
}
.personalisation-tool__back-button {
    z-index: 999;
    position: absolute;
    top: -$gutter;
    left: $gutter-xs;
    padding: 0.5em 1.5em;
    text-align: left;
    @include bp-min($small) {
        left: $gutter;
    }
}
.personalisation-tool__back-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 2.5em;
    height: 100%;
    background: url("../../images/chevron--pink-left.svg") no-repeat 0 50% /
        0.8em auto;
}
.personalisation-tool__items li:nth-child(2) .theme-illustration {
    float: right;
    @include bp-min($large) {
        float: none;
    }
}
.personalisation-tool__items li:nth-child(4) .theme-illustration {
    float: right;
    @include bp-min($large) {
        float: none;
    }
}
.personalisation-tool__items li:nth-child(3),
.personalisation-tool__items li:nth-child(4) {
    align-items: flex-end;
}
.personalisation-tool__items li:nth-child(2),
.personalisation-tool__items li:nth-child(4) {
    justify-content: flex-end;
}
