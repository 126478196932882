.relevant-filter {
    position: relative;
    margin-bottom: 1em;
    @include bp-min($medium) {
        margin-right: $single-margin;
        margin-bottom: 0;
    }
}

.relevant-filter__inner {
    top: 0;
    min-width: 240px;
    @include bp-min($medium) {
        width: 270px;
    }
}

.relevant-filter__label {
    @include body-font;
    display: block;
    margin-bottom: 8px;
    font-family: $custom-font-regular;
    font-size: 16px;
}

.relevant-filter__button-wrapper {
    position: relative;
}

.relevant-filter__select {
    display: none;
}
.relevant-filter__select--open {
    @include z-index(relevant-filter);
    @include bare-list;
    display: block;
    width: 100%;
    position: absolute;
    background: $white;
    box-shadow: 0 12px 15px 0 rgba($black, 0.27);
}

.relevant-filter__trigger {
    position: relative;
}

.relevant-filter__trigger-button {
    @include bare-button;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 8px;
    padding: 0;
    padding-left: 19px;
}

.relevant-filter__current-filter {
    overflow: hidden;
    width: 175px;
    font-family: $custom-font-regular;
    font-size: 16px;
    line-height: 1.3;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Initial state "alle groepen"
.relevant-filter__default-state {
    font-size: 14px;
    font-style: italic;
    color: $text-color;
}

.relevant-filter__chevron-icon-wrapper,
.relevant-filter__close-icon-wrapper {
    width: $tap-size-large;
    height: $tap-size-large;
    display: flex;
    justify-content: center;
    align-items: center;
}

.relevant-filter__chevron-icon-wrapper {
    background: $grey-lighter;
}

.relevant-filter__icon-inner {
    @include substance-filter-icon-dimensions;
    transition: transform 0.2s;
}

.relevant-filter__trigger-button[aria-expanded="true"]
    .relevant-filter__icon-inner {
    transform: rotate(180deg);
}

.relevant-filter__icon-inner svg {
    width: 100%;
    height: 100%;
}

.relevant-filter__option:hover {
    background: $grey-lighter;
}

.relevant-filter__option-button {
    @include substance-filter-option;
}
.relevant-filter__option-button[disabled] {
    color: $grey-medium;
    background-color: $grey-lighter;
    cursor: not-allowed;
}

.relevant-filter__remove {
    display: none;
}
.relevant-filter__remove--active {
    @include substance-filter-remove;
    position: absolute;
    top: 0;
    display: block;
}
