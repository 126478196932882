.quality-marks {
    @include bare-list;
    width: 100%;
    max-width: $quality-mark-width;
    padding-bottom: $typography-spacer;
    @include bp-min($medium) {
        margin-top: -($typography-spacer / 2);
        padding-bottom: $typography-spacer * 2;
    }
}
.quality-marks li + li {
    margin-top: $half-line;
}
