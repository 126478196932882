// stylelint-disable no-unknown-animations

.theme-gardening-extended {
    @include theme-illustration;
}
.theme-gardening-extended__blob {
    animation: float 14s alternate infinite ease-in-out;
}

/**
 * Animations: one time
 */
@keyframes woman-slide-in {
    0% {
        opacity: 0;
        transform: translateX(-20%);
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes spinning-wheel {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(150deg);
    }
}

@keyframes arm-right {
    0% {
        transform: rotate(20deg);
    }

    100% {
        transform: rotate(0);
    }
}

@keyframes plant-left-slide-in {
    0% {
        opacity: 0;
        transform: translateX(-30%) rotate(-10deg);
    }
    100% {
        opacity: 1;
        transform: translateX(0) rotate(0);
    }
}

@keyframes plant-right-slide-in {
    0% {
        opacity: 0;
        transform: translateX(30%) rotate(10deg);
    }
    100% {
        opacity: 1;
        transform: translateX(0) rotate(0);
    }
}

@keyframes grow-branch {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes grow-leaves {
    0% {
        opacity: 0;
        transform: translateY(50%) scale(0);
    }
    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

@keyframes wobble {
    0% {
        transform: rotate(2deg);
    }
    100% {
        transform: rotate(0);
    }
}

/**
 * Animations: constantly running
 */
@keyframes arm-left {
    0%,
    100% {
        transform: rotate(2deg);
    }
    50% {
        transform: rotate(0);
    }
}

@keyframes bouncy-watering-can {
    0%,
    100% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(2deg);
    }
}

@keyframes sprinkle-water {
    0%,
    100% {
        opacity: 0;
        transform: rotate(-15deg);
    }
    50% {
        opacity: 0.2;
        transform: rotate(-6deg);
    }
}

@keyframes thumb {
    0%,
    100% {
        transform: translate(-5%, 90%) rotate(4deg);
    }
    50% {
        transform: translate(0, 0) rotate(0);
    }
}

@keyframes waving-leaves {
    0%,
    100% {
        transform: rotate(3deg) scale(0.95);
    }
    50% {
        transform: rotate(-3deg) scale(1);
    }
}

/**
 * Elements
 */
.theme-gardening-extended__water {
    opacity: 0;
    transform-origin: left top;
    animation: sprinkle-water 2.4s 0.6s;
    animation-iteration-count: 4;
}
.theme-gardening-extended__watering-can {
    transform-origin: left top;
    animation: bouncy-watering-can 2.4s 0.6s;
    animation-iteration-count: 4;
}
.theme-gardening-extended__ponytail {
    transform-origin: right top;
    animation: wobble 0.7s 0.2s forwards;
}
.theme-gardening-extended__plant-right-leaves {
    transform-origin: center bottom;
    animation: waving-leaves 5s infinite;
}
.theme-gardening-extended__plant-left-leaves {
    transform-origin: center bottom;
    animation: waving-leaves 4s infinite;
}
.theme-gardening-extended__wheel {
    transform-origin: center center;
    animation: spinning-wheel 0.7s forwards;
}
.theme-gardening-extended__arm-right {
    transform-origin: top left;
    animation: arm-right 0.6s forwards;
}
.theme-gardening-extended__woman {
    animation: woman-slide-in 0.6s forwards;
}
.theme-gardening-extended__plant-right {
    opacity: 0;
    animation: plant-right-slide-in 0.4s forwards;
}
.theme-gardening-extended__plant-left {
    opacity: 0;
    animation: plant-left-slide-in 0.4s forwards;
}
.theme-gardening-extended__branch {
    transform: scale(0);
    transform-origin: center bottom;
    animation: grow-branch 0.5s 1.9s forwards;
}
.theme-gardening-extended__leaves-top,
.theme-gardening-extended__leaves-middle,
.theme-gardening-extended__leaves-bottom {
    opacity: 0;
    transform-origin: center bottom;
    animation: grow-leaves 0.3s forwards;
    animation-timing-function: cubic-bezier(0.5, -0.5, 0, 1.75);
}
.theme-gardening-extended__leaves-top {
    animation-delay: 2.3s;
}
.theme-gardening-extended__leaves-middle {
    animation-delay: 2.1s;
}
.theme-gardening-extended__leaves-bottom {
    animation-delay: 1.9s;
}
