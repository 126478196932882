// stylelint-disable no-unknown-animations
@use "sass:map";

.quiz {
    position: relative;
    overflow: hidden;
    z-index: 0;
    background-color: $grey-lighter;
}
.quiz__inner {
    @include row(medium);
    @include clearfix;
}
.quiz .slider {
    max-width: $site-width-large + 80;
    margin: 0 auto;
}
.js .quiz__item {
    padding: 25px $gutter-sm 0;
    @include bp-min($medium) {
        padding: 0 $gutter;
    }
}
.quiz__item .flipper {
    position: relative;
    z-index: 999;
    margin: 0 auto;
    max-width: calc(#{$site-width-small} - #{$gutter-lg * 2});
    @include bp-min($medium) {
        margin-top: $gutter-sm;
    }
    @include bp-min($medium-large) {
        max-width: $site-width-small;
    }
}
.quiz__item .flipper__front,
.quiz__item .flipper__back {
    z-index: 999;
    background: $white;
    .js & {
        @include box-shadow;
        min-height: 340px;
        border-radius: 3rem 0;
    }
}
.quiz__item .flipper__back {
    .no-js & {
        background-color: $blue-light;
        padding: $gutter-sm;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            width: 1em;
            height: 1em;
            background-color: $white;
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }
}
.quiz__image svg {
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
}
.quiz__header {
    width: 100%;
}
.quiz__title {
    @include h3;
    width: 100%;
    margin-top: 0;
    font-size: 16px;
    @include bp-min($medium) {
        font-size: 22px;
    }
}

/**
 * Inner styles
 */
.quiz__question,
.quiz__answer {
    width: 100%;
    padding: $gutter;
    font-size: 14px;
    @include bp-min($small) {
        padding: $gutter $gutter-lg;
        font-size: map.get($font-sizes-medium, body);
    }
    @include bp-min($large) {
        font-size: map.get($font-sizes-large, body);
    }
    .js & {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.quiz__answer-title {
    margin: 0 0 $half-line;
    font-family: $custom-font-bold;
    font-size: 20px;
    @include bp-min($medium) {
        font-size: 22px;
    }
}
.quiz__answer p {
    margin-bottom: $single-margin;
}
.quiz__answer :last-child {
    margin-bottom: 0;
}

/**
 * True or false buttons
 */
.quiz__button {
    min-width: calc(50% - 3px);
    margin: 20px 0;
    padding-right: 1em;
    text-align: center;
    @include bp-min($small) {
        min-width: 180px;
        margin: 40px $gutter-xs 0;
    }
    .no-js & {
        display: none;
    }
    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        width: 36px;
        height: 36px;
        transform: scale(0) translate(30%, -50%);
        transform-origin: top right;
        transition: transform 0.22s cubic-bezier(0.29, 1.25, 0.36, 1.06);
        border-radius: 50%;
    }
    &.right::after,
    &.wrong::after {
        transform: scale(1) translate(30%, -50%);
    }
    &.right::after {
        background: $blue url("../../images/check--blue.svg") no-repeat 50% 50% /
            50% auto;
        .theme--secondary & {
            background-color: $white;
        }
    }
    &.wrong::after {
        background: $blue url("../../images/close--blue-dark.svg") no-repeat 50%
            50% / 45% auto;
        .theme--secondary & {
            background-color: $white;
        }
    }
    &.is-hidden {
        animation: fade-out 0.2s forwards;
    }
}

.quiz__button:hover {
    background-color: transparent;
}

/*
 * Next question (slide) button
 */
.quiz__button--next {
    min-width: 200px;
    margin: 20px 0;
    animation: fade-in 0.54s forwards;
    @include bp-min($small) {
        margin: 40px 0;
    }
}
// Slider
.quiz .slider__indicators {
    bottom: -22px;
    z-index: 0;
    @include bp-min($small) {
        display: none;
    }
}
