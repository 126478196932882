@use "sass:map";

.theme-card {
    position: relative;
    width: 100%;
}
.styleguide .theme-card {
    width: 300px;
}
.theme-card__link {
    display: block;
    padding: 0 $gutter-xs $gutter-xs;
    background-color: $white;
    border-radius: 1em 0;
    box-shadow: $card-box-shadow;
    text-decoration: none;
    @include bp-min($medium-large) {
        padding: 0 $gutter $gutter;
    }
}
// Extra box-shadow for hover state
.theme-card__link::before {
    content: "";
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    box-shadow: 0 6px 18px 0 rgba($black, 0.3);
    transition: opacity 0.2s;
}
.theme-card__link:hover::before {
    opacity: 1;
}

/** 
 * Title
 */
.theme-card__title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: $gutter-xs;
    font-family: $custom-font-regular;
    font-size: map.get($font-sizes-small, body);
    line-height: 1.15;
    @include bp-min($medium) {
        font-size: map.get($font-sizes-medium, body);
    }
}
.theme-card__title > svg {
    width: 0.75em;
    height: 1.45em;
    margin-left: 0.35em;
    transition: transform 0.2s;
}
.theme-card__link:hover .theme-card__title > svg {
    transform: translateX(0.15em);
}

/** 
 * Illustration
 */
.theme-card__image {
    position: relative;
    width: 90%;
    height: 0;
    margin: 0 auto;
    padding-bottom: 97.5%;
}
.theme-card__image > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/**
 * Animations
 */
.theme-card .js-blob {
    display: none;
    @include bp-min($medium) {
        display: block;
        width: 100%;
        height: 100%;
        transform-origin: center;
        transform: scale(0.5);
        transition: none;
    }
}
.theme-card__link.is-active .js-blob {
    @include bp-min($medium) {
        transform: scale(30);
        transition: transform 0.65s ease-out;
    }
    @include bp-min($large) {
        transform: scale(40);
    }
}

// Hide items when theme link is active (opens overlay)
.theme-card__link.is-active {
    z-index: 777;
    .theme-kids__blob--front,
    .theme-kids__foreground,
    .theme-allergic__blob--front,
    .theme-allergic__foreground,
    .theme-baby__blob--front,
    .theme-baby__foreground,
    .theme-pregnant__blob--front,
    .theme-pregnant__foreground,
    .theme-frequent-users__blob--front,
    .theme-frequent-users__foreground,
    .theme-illustration__button {
        opacity: 0;
    }
}
.has-overlay .theme-card__link:not(.is-active) {
    @include bp-min($medium) {
        opacity: 0;
    }
}
.has-overlay .theme-card-list .theme-card__link {
    @include bp-min($medium) {
        opacity: 1;
    }
}
