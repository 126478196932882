.feedback-tool__inner {
    position: relative;
    padding: 1.25em;
    background: $grey-lighter;
    border-top: 6px solid $coral;
    @include bp-min($medium) {
        padding: 1.75em;
    }
}

/**
 * Fieldset
 */
.feedback-tool__section[aria-hidden="true"] {
    display: none;
}
.feedback-tool__section + .feedback-tool__section {
    margin-top: 2em;
}
.feedback-tool__section > legend {
    margin-bottom: 0.75em;
}

/**
 * Radio list
 */
.feedback-tool__radio-list {
    @include bare-list;
    @include bp-min($medium) {
        display: flex;
        flex-wrap: wrap;
    }
}
.feedback-tool__radio-list--inline {
    display: flex;
    flex-wrap: wrap;
}
.feedback-tool__radio-list input[type="radio"] {
    flex-shrink: 0;
}
.feedback-tool__radio-list > li {
    display: flex;
    width: 100%;
    min-width: 5em;
    margin-bottom: 0.75em;
    @include bp-min($medium) {
        width: 50%;
    }
}
.feedback-tool__radio-list--inline > li {
    width: auto;
    margin-bottom: 0;
}
.feedback-tool__radio-list > li > label {
    padding: 0 1em 0 0.4em;
    font-size: 0.9em;
}
.feedback-tool__button {
    margin-top: 1em;
}

/**
 * Success message
 */
.feedback-tool__success {
    font-family: $custom-font-bold;
}
.feedback-tool__success[aria-hidden="true"] {
    display: none;
}
.feedback-tool__success :last-child {
    margin: 0;
}
