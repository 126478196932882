/**
 * Illustration tiles used in styleguide
 */
.illustration {
    @include box-shadow;
    display: inline-block;
    vertical-align: top;
    width: calc(50% - #{$gutter} - 4px);
    margin: $gutter-sm;
    padding: $gutter;
    background-color: $white;
    border-radius: 5px;
    @include bp-min($medium-large) {
        width: calc(33.3333% - #{$gutter} - 4px);
    }
}
.illustration svg {
    width: 100%;
    height: auto;
}
.illustration__header {
    margin: $gutter-sm (-$gutter) (-$gutter);
    padding: $gutter-xs $gutter-sm;
    border-top: 1px solid $grey;
    font-size: 11px;
    text-align: center;
    @include bp-min($small) {
        font-size: 13px;
    }
}
