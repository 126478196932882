// stylelint-disable selector-max-universal
.flipper {
    position: relative;
    z-index: 20;
    perspective: 60em;
}
.flipper__front,
.flipper__back {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    transform: rotateY(0);
    transform-style: preserve-3d;
    transition: transform 0.65s cubic-bezier(0.29, 1, 0.86, 1);
    *,
    .js & {
        backface-visibility: hidden;
    }
}
.flipper__back {
    .js & {
        position: absolute;
        transform: rotateY(0.5turn);
    }
    .no-csstransforms3d & {
        opacity: 0;
    }
}
.flipper.is-turned {
    z-index: 999;
    .flipper__front,
    .flipper__back {
        transition: transform 0.65s cubic-bezier(0.29, 1.15, 0.86, 1.06);
    }
    .flipper__front {
        transform: rotateY(-0.5turn);
    }
    .flipper__back {
        transform: rotateY(0);
        .no-csstransforms3d & {
            opacity: 1;
        }
    }
}
.flipper__button--close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 222;
    .no-js & {
        display: none;
    }
}
