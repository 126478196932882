/**
 * Breadcrumbs
 */
.breadcrumbs {
    max-width: $site-width-large + 80;
    margin: 0 auto;
    padding: $gutter-sm $gutter-sm 0;

    // Very specific padding due to off center site menu
    @include bp-min($small) {
        padding-left: $gutter;
        padding-right: $gutter;
    }
    @include bp-min(680) {
        padding-left: $gutter-lg;
        padding-right: $gutter-lg;
    }
    @include bp-min($nav-switch) {
        padding-top: 0;
        padding-left: $site-logo-width-medium + $gutter +
            $menu-item-padding-small;
    }
    @include bp-min($medium-large) {
        padding-top: $gutter-sm;
        padding-left: $site-logo-width-large + $gutter +
            $menu-item-padding-small;
    }
    @include bp-min($large) {
        padding-left: $site-logo-width-large + $gutter +
            $menu-item-padding-large;
    }
}
.breadcrumbs__list {
    @include bare-list;
    text-align: left;
}
.breadcrumbs__item {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0 30px 0 0;
    background: url("../../images/chevron--small-blue.svg") no-repeat right 8px
        top 50%;
    background-size: 0.8em auto;
    font-size: 14px;

    @include bp-max($small) {
        // Hide all items
        display: none;

        // Hide all items, except second to last item (= 1 level up)
        &:nth-last-child(2) {
            display: inline-block;
            padding: 0 0 0 20px;
            background-image: url("../../images/chevron--small-blue-back.svg");
            background-position: 0 40%;
        }
    }
}
// Don't show decorative arrow on the last (invisible) & second to last (visible) items)
.breadcrumbs__item:last-child,
.breadcrumbs__item:nth-last-child(2) {
    @include bp-min($small) {
        padding-right: 0;
        background: none;
    }
}
.breadcrumbs__link {
    color: $text-color;
    text-decoration: none;
}
.breadcrumbs__link:hover,
.breadcrumbs__link:focus {
    color: currentcolor;
    text-decoration: underline;
    text-underline-position: under;
}

// Let the current item be there only for Assistive Technology
.breadcrumbs__link[aria-current] {
    @include hide-text;
}
