.news-list {
    @include bare-list;
    @include content(large);
    @include row-bottom(medium);
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 auto;
    gap: $gutter-sm;
    padding-top: $gutter-sm;

    @include bp-min($small) {
        grid-template-columns: repeat(2, 1fr);
        padding-top: 0;
        gap: $gutter $gutter-sm;
    }
    @include bp-min($medium-large) {
        grid-template-columns: repeat(3, 1fr);
        gap: 55px 24px;
    }
}
.news-list__item {
    display: flex;
}
